import { FC } from "react"
import { FormattedMessage } from "react-intl"
import InfoBadge from "UI/InfoBadge"
import { AssetsTableHeadWrap, AssetsTableHead } from "./TableHeader.styles"

type TableHeaderPropsType = {
  isSelling?: boolean
  isEnded?: boolean
}

const TableHeader: FC<TableHeaderPropsType> = ({ isSelling, isEnded }) => (
  <AssetsTableHeadWrap isSelling={isSelling} isEnded={isEnded}>
    <AssetsTableHead>
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.tableHeaders.loanNumber"
        defaultMessage="Lånenummer"
      />
    </AssetsTableHead>
    {!isSelling && (
      <AssetsTableHead>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.status"
          defaultMessage="Status"
        />
      </AssetsTableHead>
    )}
    <AssetsTableHead>
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.tableHeaders.riskClass"
        defaultMessage="Riskklass"
      />
    </AssetsTableHead>
    <AssetsTableHead hideMobile>
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.tableHeaders.term"
        defaultMessage="Löptid"
      />
    </AssetsTableHead>
    <AssetsTableHead hideMobile>
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.tableHeaders.loanInterestRate"
        defaultMessage="Utlåningsränta"
      />
    </AssetsTableHead>
    <AssetsTableHead hideMobile>
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.tableHeaders.amountOfLoanShares"
        defaultMessage="Antal låneandelar"
      />
    </AssetsTableHead>
    {!isEnded && (
      <AssetsTableHead hideMobile>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.amountToSell"
          defaultMessage="Antal utlagttill försäljning"
        />
        <InfoBadge
          title={
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.amountToSell.info"
              defaultMessage="Kan ta upp till en dag innan dina utlagda försäljningar syns"
            />
          }
          placement="top"
        />
      </AssetsTableHead>
    )}
    {isSelling && (
      <AssetsTableHead hideMobile>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.amontLoanedOut"
          defaultMessage="Utlånat"
        />
      </AssetsTableHead>
    )}
    {isSelling ? (
      <AssetsTableHead>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.sell"
          defaultMessage="Sälj"
        />
        <InfoBadge
          title={
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.sell.info"
              defaultMessage="Ange vilka lån du vill sälja"
            />
          }
          placement="top"
        />
      </AssetsTableHead>
    ) : (
      <AssetsTableHead hideMobile>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.bookValue"
          defaultMessage="Bokfört värde"
        />
      </AssetsTableHead>
    )}

    {isSelling ? (
      <AssetsTableHead>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.amountOfLoanShares"
          defaultMessage="Antal låneandelar"
        />
        <InfoBadge
          title={
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.sellCount.info"
              defaultMessage="Ange antalet lån du vill sälja"
            />
          }
          placement="left"
        />
      </AssetsTableHead>
    ) : (
      <AssetsTableHead hideMobile>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.tableHeaders.repayed"
          defaultMessage="Återbetalat"
        />
      </AssetsTableHead>
    )}
    <AssetsTableHead showMobile />
  </AssetsTableHeadWrap>
)

export default TableHeader
