import { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import Checkbox from "UI/Checkbox"

import InfoBadge from "UI/InfoBadge"
import { PriorityLabel } from "./SellAssets.styles"

type PrioritySellingCheckboxPropTypes = {
  prioritySelling: boolean
  togglePrioritySelling: () => void
}

const PrioritySellingCheckbox: FC<PrioritySellingCheckboxPropTypes> = ({
  prioritySelling,
  togglePrioritySelling,
}) => {
  const intl = useIntl()

  return (
    <PriorityLabel htmlFor="priority-selling">
      <FormattedMessage
        id="app.mypages.investments.assets.assetsTable.prioritySaleCheckboxLabel"
        defaultMessage="Prioriterad försäljning"
      />
      <InfoBadge
        title={intl.formatMessage({
          id: "app.mypages.investments.assets.assetsTable.sellPriority.info",
          defaultMessage:
            "Med prioriterad försäljning går dina lån snabbare till avslut mot en avgift på 2.99%",
        })}
        placement="top"
      />
      <Checkbox
        size="large"
        priority
        checked={prioritySelling}
        name="priority-selling"
        value="priority-selling"
        onChange={togglePrioritySelling}
      />
    </PriorityLabel>
  )
}

export default PrioritySellingCheckbox
