import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

export const OverviewWrap = styled.div`
  ${flex.justifyBetween}
  flex-wrap: wrap;
  margin: 1em 0;
  gap: 1em;
  div {
    ${flex.columnAlignCenter}
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    justify-content: center;
  }
`

export const OverviewItemTitle = styled.div`
  font-size: ${font.size.sm};
  margin-bottom: 1em;
  color: var(--dark-gray);
`

export const OverviewItemData = styled.div`
  font-size: ${font.size.xl2};
`
