import { memo } from "react"
import { FormattedMessage } from "react-intl"

import { TFeatureType } from "./GeographicChart.types"
import * as S from "./Tooltip.styles"

const Tooltip = ({ feature }: TFeatureType) => {
  if (!feature?.label) return null

  return (
    <S.TooltipContainer>
      <strong>{feature.label}</strong>: {feature.value}{" "}
      <FormattedMessage
        id="app.charts.geographical.borrower"
        defaultMessage="Låntagare"
      />
    </S.TooltipContainer>
  )
}

export default memo(Tooltip)
