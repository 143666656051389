import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useStatusFilterOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.common.status",
          defaultMessage: "Status",
        }),
        value: "-",
        id: 0,
      },
      {
        name: intl.formatMessage({
          id: "app.common.active",
          defaultMessage: "Aktiv",
        }),
        value: "current",
        id: 4,
      },
      {
        name: intl.formatMessage(
          {
            id: "app.mypages.investments.assets.myAssets.select.daysAgo",
            defaultMessage: "5-30 dagar sen",
          },
          {
            count: "5-30",
          }
        ),
        value: "overdue",
        id: 1,
      },
      {
        name: intl.formatMessage(
          {
            id: "app.mypages.investments.assets.myAssets.select.daysAgo",
            defaultMessage: "30-60 dagar sen",
          },
          {
            count: "30-60",
          }
        ),
        value: "collection",
        id: 7,
      },
      {
        name: intl.formatMessage(
          {
            id: "app.mypages.investments.assets.myAssets.select.daysAgo",
            defaultMessage: "60+ dagar sen",
          },
          {
            count: "60+",
          }
        ),
        value: "gt_60_days_late",
        id: 8,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.assets.myAssets.select.paymentOrder",
          defaultMessage: "Betalningsföreläggande",
        }),
        value: "process",
        id: 9,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.assets.myAssets.select.writtenDown",
          defaultMessage: "Nedskrivna",
        }),
        value: "default",
        id: 10,
      },
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.assets.myAssets.select.finalPayment",
          defaultMessage: "Slutbetald",
        }),
        value: "ended",
        id: 12,
      },
    ],
    [intl]
  )
}

export const useRiskClassFilterOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.assets.myAssets.select.riskClass",
          defaultMessage: "Riskklass",
        }),
        value: "-",
        id: 0,
      },
      { name: "A", value: "A", id: 1 },
      { name: "B", value: "B", id: 2 },
      { name: "C", value: "C", id: 3 },
      { name: "D", value: "D", id: 4 },
      { name: "E", value: "E", id: 5 },
      { name: "F", value: "F", id: 6 },
    ],
    [intl]
  )
}

export const useTerminFilterOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        name: intl.formatMessage({
          id: "app.mypages.investments.assets.myAssets.select.term",
          defaultMessage: "Löptid",
        }),
        value: 0,
        id: 0,
      },
      { name: "0-24", value: 24, id: 1 },
      { name: "24-48", value: 48, id: 2 },
      { name: "48-72", value: 72, id: 3 },
      { name: "72-96", value: 96, id: 4 },
      { name: "96-120", value: 120, id: 5 },
      { name: "120+", value: 130, id: 6 },
    ],
    [intl]
  )
}
