import { IntlShape, MessageDescriptor } from "react-intl"
import { MetricsGroupByType } from "ApiServices/investmentServices"
import { colors, defaultColor } from "Components/Charts/PieChart/constants"
import { ChartDataType } from "Components/Charts/PieChart/PieChart.types"
import { IPortfolioMetrics } from "Interfaces/investmentServicesInterfaces"

const labels: Record<string, MessageDescriptor> = {
  current: {
    id: "app.common.active",
    defaultMessage: "Activ",
  },
  overdue: {
    id: "app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.5-30",
    defaultMessage: "5-30 dagar sen",
  },
  collection: {
    id: "app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.30-60",
    defaultMessage: "30-60 dagar sen",
  },
  gt_60_days_late: {
    id: "app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.60+",
    defaultMessage: "60+ dagar sen",
  },
  processDefault: {
    id: "app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.process",
    defaultMessage: "Betalningsföreläggande",
  },
}

const groupProcessAndDefaultMetrics = (data: IPortfolioMetrics[]) => {
  const res = []
  const processDefault = {
    label: "processDefault",
    amount: 0,
    count: 0,
  }

  for (let i = 0; i < data.length; i += 1) {
    const metric = data[i]
    if (metric.label === "process" || metric.label === "default") {
      processDefault.amount += metric.amount
      processDefault.count += metric.count
      // eslint-disable-next-line no-continue
      continue
    }
    res.push(metric)
  }
  res.push(processDefault)
  return res
}

export const mapToPieChartData = (
  data: IPortfolioMetrics[],
  metricType: MetricsGroupByType,
  intl: IntlShape
) => {
  let metricsData: IPortfolioMetrics[] = data

  if (metricType === "loan_status") {
    metricsData = groupProcessAndDefaultMetrics(metricsData)
  }

  return metricsData.reduce((acc: ChartDataType, item, i) => {
    if (item.amount !== 0) {
      acc.push({
        ...item,
        label:
          metricType === "loan_status"
            ? intl.formatMessage(labels[item.label])
            : item.label,
        value: item.amount,
        color: colors[i] || defaultColor,
      })
    }
    return acc
  }, [])
}
