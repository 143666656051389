import { FC } from "react"
import { FormattedMessage } from "react-intl"

import Balance from "Components/BalanceOverview"
import { BalanceItemProps } from "Components/BalanceOverview/BalanceOverview.types"
import { IInvestmentServiceSummary } from "Interfaces/investmentServicesInterfaces"
import { Urls } from "Constants/urls"
import Button from "UI/Button"
import * as S from "./BalanceOverview.styles"

type InvestmentBalanceOverviewProps = {
  data: IInvestmentServiceSummary
  accountID: string
  isError?: boolean
}

const InvestmentsBalanceOverview: FC<InvestmentBalanceOverviewProps> = ({
  data,
  accountID,
  isError,
}) => {
  const balanceItemsData: BalanceItemProps[] = [
    {
      value: data.account_summary.simple.realized_results.net_results,
      style: "currency",
      text: {
        id: "app.mypages.investments.home.balanceOverview.netResultsTitle",
        defaultMessage: "Realiserat resultat",
      },
    },
    {
      value: data.portfolio_with_accruals_cumulative_returns,
      style: "percent",
      text: {
        id: "app.mypages.investments.home.balanceOverview.returnsFromBeginning",
        defaultMessage: "Avkastning sedan start",
      },
    },
    {
      value: data.account_summary.balances.before_unrealized_value_changes,
      style: "currency",
      text: {
        id: "app.mypages.investments.home.balanceOverview.accountBalance",
        defaultMessage: "Kontobalans",
      },
    },
  ]

  if (isError) {
    return (
      <S.BalanceOverviewContainer>
        <FormattedMessage
          id="app.mypages.error.investment.getInvestmentSummary"
          defaultMessage="Det går inte att hämta sammanfattande mätvärden. Försök igen"
        />
      </S.BalanceOverviewContainer>
    )
  }

  return (
    <>
      <S.BalanceOverviewContainerTest>
        <Balance data={balanceItemsData} />
      </S.BalanceOverviewContainerTest>
      <S.ButtonWrap>
        <Button
          component="link"
          to={[
            Urls.Investments,
            accountID,
            Urls.InvestmentsAccountTransactions,
            Urls.InvestmentsAccountTransactionsDeposit,
          ].join("/")}
        >
          <FormattedMessage
            id="app.common.deposit"
            defaultMessage="Insättning"
          />
        </Button>
        <Button
          component="link"
          variant="inverse"
          to={[
            Urls.Investments,
            accountID,
            Urls.InvestmentsAccountTransactions,
            Urls.InvestmentsAccountTransactionsWithdraw,
          ].join("/")}
        >
          <FormattedMessage id="app.common.withdrawal" defaultMessage="Uttag" />
        </Button>
      </S.ButtonWrap>
    </>
  )
}

export default InvestmentsBalanceOverview
