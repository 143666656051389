import { FC, useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"

import AssetsTable from "Components/AssetsTable"
import TermFilter from "Components/AssetsFilters/TermFilter"
import RiskClassFilter from "Components/AssetsFilters/RiskClassFilter"
import { CURRENT } from "Constants/dataStrings"
import PrioritySellingCheckbox from "./PrioritySellingCheckbox"
import * as S from "./SellAssets.styles"

const SellHoldings: FC = () => {
  const [riskClass, setRiskClass] = useState("-")
  const [termDuration, setTermDuration] = useState(0)
  const [prioritySelling, setPrioritySelling] = useState(false)

  const togglePrioritySelling = useCallback(
    () => setPrioritySelling(!prioritySelling),
    [prioritySelling]
  )

  return (
    <S.SellHoldingsWrap>
      <S.SellHoldingsDesc>
        <FormattedMessage
          id="app.mypages.investments.sellAssets.paragraph"
          defaultMessage="Här kan du välja att sälja hela eller delar av din aktiva portfölj till
            en kostnad om 2% av försäljningsvärdet. Lån som för tillfället inte är
            aktiva måste bli det innan dessa kan säljas. Tiden för överlåtelsen är
            beroende av efterfrågan av överlåtna portföljer vid
            försäljningstillfället, vilket innebär att tiden för överlåtelsen kan
            variera mellan 1-3 månader. Observera även att detta gäller vid
            prioriterad försäljning. Om Brocc ej överlåtit din portfölj inom avsedda
            villkor för prioriterad försäljning kommer avgiften återgå till
            ordinarie avgift."
        />
      </S.SellHoldingsDesc>
      <S.SellingFiltersWrap>
        <S.FilterWrap>
          <RiskClassFilter riskClass={riskClass} setRiskClass={setRiskClass} />
          <TermFilter
            termDuration={termDuration}
            setTermDuration={setTermDuration}
          />
        </S.FilterWrap>
        <PrioritySellingCheckbox
          prioritySelling={prioritySelling}
          togglePrioritySelling={togglePrioritySelling}
        />
      </S.SellingFiltersWrap>
      <AssetsTable
        isSelling
        riskClass={riskClass}
        termDuration={termDuration}
        status={CURRENT}
        prioritySelling={prioritySelling}
      />
    </S.SellHoldingsWrap>
  )
}

export default SellHoldings
