import { FormattedMessage } from "react-intl"
import IconFactory from "UI/IconFactory"
import type { FC } from "react"
import { EmptyInvestmentsWrap } from "./EmptyInvestments.styles"

const EmptyInvestments: FC = () => (
  <EmptyInvestmentsWrap>
    <IconFactory name="manRelaxingGreen" />
    <h2>
      <FormattedMessage
        id="app.mypages.investments.accounts.noAccounts"
        defaultMessage="Investera i svenska privatlån!"
      />
    </h2>
    <p>
      <FormattedMessage
        id="app.mypages.main.investmentsDescription"
        defaultMessage="För närvarande är produkten stängd för nya investeringar."
      />
    </p>
  </EmptyInvestmentsWrap>
)

export default EmptyInvestments
