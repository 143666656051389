import React, { useCallback } from "react"
import { FormattedMessage } from "react-intl"

import Form from "Components/KYC/Form"
import * as S from "./KYCStep.styles"

const KYCStep = ({
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>
}) => {
  const submitCallback = useCallback(() => {
    setStep((prev) => prev + 1)
  }, [setStep])
  return (
    <div>
      <S.MainHeader>
        <FormattedMessage
          id="app.mypages.investments.onboarding.KYC.header.private"
          defaultMessage="Kundkännedom"
        />
      </S.MainHeader>
      <Form
        submitCallback={submitCallback}
        hasDepositOrInvestment
        buttonText={
          <FormattedMessage
            id="app.mypages.investments.onboarding.KYC.nextCTA"
            defaultMessage="Gå vidare"
          />
        }
      />
    </div>
  )
}

export default KYCStep
