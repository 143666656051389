import { FC, useState, memo } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import { useFeedbackModal } from "Context/modal-context"
import InvoiceStatus from "UI/InvoiceStatus"
import Loading from "UI/Loading"
import { useFileDownload } from "Hooks/API/useFileDownload"
import { getInvoicePdf } from "ApiServices/invoices"
import { INVOICE_PDF } from "Constants/fileNames"
import { formatNumber } from "Utils/formatNumber"
import { InvoicesTableRowPropTypes } from "./InvoicesTableRow.types"
import * as S from "./InvoicesTableRow.styles"

const InvoicesTableRow: FC<InvoicesTableRowPropTypes> = ({ invoice }) => {
  const [show, setShow] = useState(false)
  const { errorModal } = useFeedbackModal()

  const toggleShowMobileRowWrap = () => {
    setShow((prevState) => !prevState)
  }

  const { downloadFile, fileDownloadQuery } = useFileDownload({
    fileId: invoice.uid,
    fileName: INVOICE_PDF,
    fetchFile: getInvoicePdf,
    onError: () => errorModal(),
  })

  return (
    <S.TableItemsWrap>
      <S.TableDataItem>{invoice.due_date}</S.TableDataItem>
      <S.TableDataItem>{invoice.invoice_number}</S.TableDataItem>
      <S.TableDataItem>
        <S.Status>
          <InvoiceStatus status={invoice.status} />
        </S.Status>
      </S.TableDataItem>
      <S.TableDataItem hide>
        <FormattedMessage
          id={`app.mypages.loans.invoices.paymentMethods.${invoice.invoice_type}`}
          defaultMessage=""
        />
      </S.TableDataItem>
      <S.TableDataItem hide>
        {formatNumber(invoice.total_amount, { style: "currency" })}
      </S.TableDataItem>
      <S.TableDataItem hide>
        {formatNumber(invoice.paid_total_amount, { style: "currency" })}
      </S.TableDataItem>
      <S.TableDataItem hide>
        {fileDownloadQuery.isLoading ? (
          <Loading size="small" />
        ) : (
          <S.DownloadLink onClick={downloadFile}>
            <IconFactory name="download" />
          </S.DownloadLink>
        )}
      </S.TableDataItem>
      <S.TableDataItem chevron>
        <S.ExpandButton onClick={toggleShowMobileRowWrap} show={show}>
          <IconFactory name="chevronRight" />
        </S.ExpandButton>
      </S.TableDataItem>
      <S.MobileRowWrap show={show} mobile>
        <S.MobileRow>
          <div>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.loans.invoices.table.paymentMethod"
                  defaultMessage="Betalsätt"
                />
              </strong>{" "}
              :{" "}
              <FormattedMessage
                id={`app.mypages.loans.invoices.paymentMethods.${invoice.invoice_type}`}
                defaultMessage=""
              />
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.loans.invoices.table.amount"
                  defaultMessage="Belopp"
                />
              </strong>{" "}
              :{` ${formatNumber(invoice.total_amount, { style: "currency" })}`}
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.loans.invoices.table.payedAmount"
                  defaultMessage="Betalt Belopp"
                />
              </strong>{" "}
              :{` ${formatNumber(invoice.total_amount, { style: "currency" })}`}
            </S.MobileRowItem>
          </div>
          <S.MobileRowItem>
            <S.DownloadLink onClick={downloadFile}>
              <IconFactory name="download" />
            </S.DownloadLink>
          </S.MobileRowItem>
        </S.MobileRow>
      </S.MobileRowWrap>
    </S.TableItemsWrap>
  )
}

export default memo(InvoicesTableRow)
