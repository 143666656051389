import styled from "styled-components"
import { motion } from "framer-motion"

import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { StatusAndRiskHeadersPropTypes } from "./AssetsSummary.types"

export const AssetsOverview = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 2em;
  margin: 3em 0;
  min-height: 490px;

  @media screen and (min-width: ${mediaQueries.extraLarge}) {
    grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));
    & > div:nth-of-type(3n) {
      grid-column: 1/3;
    }
  }
`
export const StatusAndRiskWrap = styled.div`
  padding: 0;
`
export const HeadersWrap = styled.div`
  border-bottom: 1px solid var(--light-gray);
  text-align: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: ${mediaQueries.large}) {
    margin-bottom: 0;
  }
`
export const StatusAndRiskHeader = styled.span`
  font-size: ${font.size.lg};
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 0.5em;
  position: relative;
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.base};
    line-height: 24px;
  }
`

export const StatusAndRiskHeaders = styled.div<StatusAndRiskHeadersPropTypes>`
  padding-bottom: 0.5em;
`
export const LoadingErrorContainer = styled.div`
  ${flex.center}
  height: 100%;
`
export const ActiveLine = styled(motion.div)`
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--dark-yellow);
  position: absolute;
  bottom: -2px;
  left: 0px;
`
export const LoadingContainer = styled.div`
  ${flex.center}
  flex: 1;
  width: 100%;
  height: 100%;
  grid-column: 1/3;
`
