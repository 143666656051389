import styled, { css } from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

type GrayBoxBodyProps = {
  gray?: boolean
  oneString?: boolean
}

export const ResultsWrap = styled.div`
  padding-top: 1em;
  display: flex;
  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
  }
`

export const LoadingContainer = styled.div`
  ${flex.center}
  min-height: 400px;
  flex: 1;
`

export const OverviewBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2em;
  margin-top: 2em;
  width: 100%;

  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
    gap: 2em;
  }
`

export const GrayBox = styled.div`
  ${flex.column}
  border-radius: 16px;
  background: ${(p) => p.theme.infoBox};
  min-height: 200px;
  width: 100%;

  hr,
  h3 {
    margin: 0;
  }
  hr {
    border: none;
    height: 1px;
    background-color: var(--light-gray);
  }
`

export const GrayBoxHeader = styled.div`
  padding: 0 0 0.5em;
  h3 {
    font-size: ${font.size.lg};
  }
`

export const GrayBoxBody = styled.div<GrayBoxBodyProps>`
  padding: 1.25em 0;
  flex-grow: 1;
  color: ${(p) => (p.gray ? "var(--dark-gray)" : p.theme.fontColor)};
  div {
    ${flex.alignCenterBetween}
    margin: 0.5em 0;
  }
  ${(p) =>
    p.oneString &&
    css`
      ${flex.alignCenter}
      max-height: auto;
      flex-grow: 0.5;
    `}
`
