import { FC } from "react"
import { FormattedMessage } from "react-intl"
import CustomChoropleth from "UI/CustomChoropleth"
import counties from "./counties.json"
import Tooltip from "./Tooltip"
import { GeopgraphicChartPropTypes } from "./GeographicChart.types"
import { GeoChartWrapper } from "./GeographicChart.styles"

const GeographicChart: FC<GeopgraphicChartPropTypes> = ({ data }) => {
  const preparedChartData = Object.entries(data).map(([key, value]) => ({
    id: key,
    value,
  }))

  if (preparedChartData?.length === 0) {
    return (
      <GeoChartWrapper isError>
        <FormattedMessage
          id="app.mypages.error.investment.charts"
          defaultMessage="Det finns för närvarande ingenting att visa."
        />
      </GeoChartWrapper>
    )
  }

  return (
    <GeoChartWrapper data-testid="geographic-chart">
      <CustomChoropleth
        features={counties.features}
        data={preparedChartData}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors={[
          "rgba(143, 187, 175, 0.2)",
          "rgba(143, 187, 175, 0.3)",
          "rgba(143, 187, 175, 0.4)",
          "rgba(143, 187, 175, 0.5)",
          "rgba(143, 187, 175, 0.6)",
          "rgba(143, 187, 175, 0.7)",
          "rgba(143, 187, 175, 0.8)",
          "rgba(143, 187, 175, 0.9)",
          "rgba(143, 187, 175, 1)",
        ]}
        domain={[0, 70]}
        unknownColor="#EEF1F0"
        label="properties.name"
        valueFormat=",d"
        projectionScale={710}
        projectionTranslation={[0.03, 3.02]}
        projectionRotation={[0, 0, 0]}
        isInteractive
        width={327}
        height={400}
        legends={[
          {
            direction: "column",
            translateX: 0,
            translateY: 150,
            itemsSpacing: 0,
            itemWidth: 94,
            itemHeight: 18,
            itemOpacity: 0.85,
            symbolSize: 18,
            effects: {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          },
        ]}
        tooltip={Tooltip}
      />
    </GeoChartWrapper>
  )
}

export default GeographicChart
