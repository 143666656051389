import InfoBadge from "UI/InfoBadge"
import { FC, ReactElement } from "react"
import {
  ResultsBoxPropsType,
  ResultBoxHeaderPropsType,
  ResultBoxFooterPropsType,
  ResultBoxContentPropsType,
} from "./ResultBox.types"
import * as Styles from "./ResultsBox.styles"

const ResultBoxHeader: FC<ResultBoxHeaderPropsType> = ({ children }) => (
  <Styles.GrayBoxHeader>
    <h3>{children}</h3>
  </Styles.GrayBoxHeader>
)

const ResultBoxFooter: FC<ResultBoxFooterPropsType> = ({ children }) => (
  <Styles.Footer>{children}</Styles.Footer>
)

const ResultBoxContent: FC<ResultBoxContentPropsType> = ({
  data,
  children,
}) => (
  <Styles.GrayBoxBody>
    {data && (
      <ul>
        {data.map(({ name, value, ...rest }, index) => {
          if (rest?.isHidden) return null
          return (
            <Styles.ListItem key={index} isBold={rest?.isBold}>
              <Styles.ListItemContent>
                {name}
                {rest.infoBadge && <InfoBadge {...rest.infoBadge} />}
              </Styles.ListItemContent>
              <span>{value}</span>
            </Styles.ListItem>
          )
        })}
      </ul>
    )}

    {children}
  </Styles.GrayBoxBody>
)

const ResultsBox: FC<{ children: ReactElement[] }> & ResultsBoxPropsType = ({
  children,
}) => {
  return <Styles.ResultsBox>{children}</Styles.ResultsBox>
}

ResultsBox.Header = ResultBoxHeader
ResultsBox.Content = ResultBoxContent
ResultsBox.Footer = ResultBoxFooter

export default ResultsBox
