import { memo, useCallback, useEffect } from "react"
import type { Dispatch, SetStateAction } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useInView } from "react-intersection-observer"

import { useGetInvestmentAssets } from "Hooks/API/useInvestmentServices"
import { IInvestmentAsset } from "Interfaces/investmentServicesInterfaces"
import Loading from "UI/Loading"
import { LoanGrade } from "Components/LoanGrade/LoanGrade"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./HoldingsList.styles"
import { loanPerformanceStatuses } from "../ExtendedInfo/config"

type HoldingsListProps = {
  riskClass?: string
  status?: string
  termDuration?: number
  setExtendedInfo: Dispatch<SetStateAction<IInvestmentAsset | null>>
}

const HoldingsLIst = ({
  riskClass,
  status,
  termDuration,
  setExtendedInfo,
}: HoldingsListProps) => {
  const intl = useIntl()
  const { ref, inView } = useInView()

  const { accountID } = useParams() as { accountID: string }
  const assets = useGetInvestmentAssets(
    accountID,
    riskClass,
    termDuration,
    status
  )

  const loadMore = useCallback(() => assets.fetchNextPage(), [assets])

  useEffect(() => {
    if (inView && assets.hasNextPage && !assets.isFetching) {
      loadMore()
    }
  }, [inView, loadMore, assets.hasNextPage, assets.isFetching])

  if (assets.isLoading) {
    return <Loading isCentered />
  }

  if (assets.isError) {
    return (
      <S.HoldingsListContainer isEmpty>
        <FormattedMessage
          id="app.mypages.error.investment.getListInvestmentAssets"
          defaultMessage="Kan inte få en förteckning över dina investeringstillgångar. Försök igen"
        />
      </S.HoldingsListContainer>
    )
  }

  return (
    <>
      {assets.data && assets.data.pages.length > 0 ? (
        <S.HoldingsList>
          {assets.data?.pages.map((asset) => (
            <S.HoldingsItem
              key={asset.uid}
              onClick={() => setExtendedInfo(asset)}
            >
              <S.ItemLeft>
                <LoanGrade grade={asset.grade} />
                <div>
                  {intl.formatMessage(
                    loanPerformanceStatuses[asset.loan_performance_status]
                  )}
                </div>
                <div>
                  {intl.formatNumber(Number(asset.loan_interest_rate), {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: "percent",
                  })}
                </div>
              </S.ItemLeft>
              <S.ItemRight>
                <S.NominalValue>
                  {formatNumber(Number(asset.nominal_value), {
                    style: "currency",
                  })}
                </S.NominalValue>
                <div>
                  {formatNumber(
                    parseFloat(asset.interest_paid_to_date) +
                      parseFloat(asset.principal_paid_to_date),
                    { style: "currency" }
                  )}
                </div>
              </S.ItemRight>
            </S.HoldingsItem>
          ))}
        </S.HoldingsList>
      ) : (
        <S.HoldingsListContainer isEmpty>
          <h3>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.noData"
              defaultMessage="Du har inga innehav att visa just nu."
            />
          </h3>
        </S.HoldingsListContainer>
      )}
      <span ref={ref} />
      {assets.isFetchingNextPage && <Loading isCentered />}
    </>
  )
}

export default memo(HoldingsLIst)
