import { FC, useState } from "react"
import { useParams } from "react-router-dom"

import AssetsTable from "Components/AssetsTable"
import RiskClassFilter from "Components/AssetsFilters/RiskClassFilter"
import TermFilter from "Components/AssetsFilters/TermFilter"
import { useGetInvestmentAggregate } from "Hooks/API/useInvestmentServices"
import { ENDED } from "Constants/dataStrings"
import InvestmentAggregate from "Components/InvestmentAggregate"
import Loading from "UI/Loading"
import * as S from "./EndedAssets.styles"

const EndedAssets: FC = () => {
  const [riskClass, setRiskClass] = useState("-")
  const [termDuration, setTermDuration] = useState(0)
  const { accountID } = useParams() as { accountID: string }
  const aggregateOverview = useGetInvestmentAggregate(accountID)

  if (aggregateOverview.isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.EndedAssetsWrap>
      <InvestmentAggregate aggregateOverview={aggregateOverview} />
      <S.FilterWrap>
        <RiskClassFilter riskClass={riskClass} setRiskClass={setRiskClass} />
        <TermFilter
          termDuration={termDuration}
          setTermDuration={setTermDuration}
        />
      </S.FilterWrap>
      <AssetsTable
        riskClass={riskClass}
        termDuration={termDuration}
        status={ENDED}
        isEnded
      />
    </S.EndedAssetsWrap>
  )
}

export default EndedAssets
