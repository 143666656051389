export const colors = [
  "#8FBBAF",
  "#F9D682",
  "#B486B9",
  "#E6E6E6",
  "#E48483",
  "#96AB59",
]

export const defaultColor = "#8FBBAF"
