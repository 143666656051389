import { PMT, RATE } from "Utils/calculations"

type ValueType = undefined | null | boolean | string | number

export const disable = (value: ValueType) => !!value

export const show = (value: ValueType) => value == null

export const getInterestRateBySource = (source?: string) => {
  switch (source) {
    case "handlarfinans":
      return 15.0 / 100
    default:
      return 10.2 / 100
  }
}

export const getFeesBySource = (source?: string) => {
  switch (source) {
    case "handlarfinans":
      return { setupFee: 500, adminFee: 29 }
    default:
      return { setupFee: 495, adminFee: 29 }
  }
}

export const calculatePaymentDetails = (
  amount: number,
  repaymentPeriods: number,
  interestRate: number,
  setupFee: number,
  adminFee: number
) => {
  const PERIODS_PER_YEAR = 12

  const interestRatePerPeriod = interestRate / PERIODS_PER_YEAR
  const periodCost =
    PMT(interestRatePerPeriod, repaymentPeriods, -amount) + adminFee
  const totalCost = periodCost * repaymentPeriods
  const nominalRatePerPeriod = RATE(
    repaymentPeriods,
    periodCost,
    -amount + setupFee
  )
  const effectiveInterestRate =
    ((1 + nominalRatePerPeriod) ** PERIODS_PER_YEAR - 1) * 100

  return {
    periodCost,
    effectiveInterestRate,
    totalCost,
    interestRate: interestRate * 100,
  }
}
