import styled from "styled-components"
import listIcon from "Assets/Icons/list-icon-black.svg"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"

export const IntroContainer = styled.div`
  margin: 3em 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 1em;
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin: 1em 0;
  }
`
export const ExplainationBox = styled.div`
  ${flex.columnAlignCenter}
  border-radius: 20px;
  padding: 3em 0em;
  gap: 3em;
  color: ${(p) => p.theme.background};
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 0;
  }
  svg {
    width: 50%;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
    svg {
      width: 300px;
      margin-right: 3em;
    }
  }
`
export const ExplainationContent = styled.div`
  display: block;
  margin: 0 auto;
  color: var(--black);
  button {
    margin-top: 4em;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 2em auto 0;
    }
  }

  p {
    color: var(--black);
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    svg {
      min-width: 35px;
      margin: 0 30px 0 0;
      width: auto;

      @media screen and (max-width: ${mediaQueries.medium}) {
        min-width: 30px;
        width: 30px;
        margin: 0 20px 0 0;
      }
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      background-image: url(${listIcon});
      background-repeat: no-repeat;
      background-size: 30px;
      line-height: 40px;
      padding-left: 55px;
    }
  }
`
