import { FC, useMemo } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useBasicLoanServices } from "Hooks/API/useBasicLoanServices"
import { useGetDepositServices } from "Hooks/API/useDepositServices"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import Loading from "UI/Loading"
import { Urls } from "Constants/urls"
import { PageLayoutPropTypes } from "./PageLayout.types"
import EmptyService from "../EmptyService"
import { LoadingContainer } from "./PageLayout.styles"

const PageLayout: FC<PageLayoutPropTypes> = ({ type }) => {
  const { data: savings, isLoading: isLoadingSavings } = useGetDepositServices(
    type === "savings"
  )
  const { data: investments, isLoading: isLoadingInvestment } =
    useInvestmentServices(type === "investments")
  const { data: loans, isLoading: isLoadingLoans } = useBasicLoanServices(
    type === "loans"
  )

  const { pathname } = useLocation()

  const emptyServices = useMemo(
    () => ({
      loans: loans && loans?.data.length <= 0,
      savings: savings && savings?.data?.length <= 0,
      investments: investments && investments?.data.length <= 0,
    }),
    [loans, savings, investments]
  )

  if (isLoadingLoans || isLoadingInvestment || isLoadingSavings) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  if (pathname !== `/${type}` && emptyServices[type]) {
    return <Navigate to={Urls.Home} />
  }

  if (emptyServices[type]) {
    return <EmptyService type={type} />
  }

  return <Outlet />
}

export default PageLayout
