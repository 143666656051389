import * as d3 from "d3"
import { PaddingType } from "./CustomChoropleth.types"

export const calculatePath = (
  width: number,
  height: number,
  projectionScale: number,
  projectionTranslation: [number, number],
  projectionRotation: [number, number, number]
) => {
  const projection = d3
    .geoMercator()
    .scale(projectionScale)
    .translate([
      width * projectionTranslation[0],
      height * projectionTranslation[1],
    ])
    .rotate(projectionRotation)
  const path = d3.geoPath(projection)

  return path
}

export const calculatePadding = (customPadding: number | PaddingType) => {
  const zeroPadding = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  const padding =
    typeof customPadding === "number"
      ? {
          top: customPadding,
          right: customPadding,
          bottom: customPadding,
          left: customPadding,
        }
      : {
          ...zeroPadding,
          ...customPadding,
        }

  return padding
}

export const calculateLegendItemLayout = (
  symbolSize: number,
  symbolSpacing: number,
  width: number,
  height: number
) => {
  const symbolX = width - symbolSize
  const symbolY = (height - symbolSize) / 2

  const labelX = width - symbolSize - symbolSpacing
  const labelY = height / 2
  const labelAnchor = "end" as const
  const labelAlignment = "central" as const

  return {
    symbolX,
    symbolY,
    labelX,
    labelY,
    labelAnchor,
    labelAlignment,
  }
}
