import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import {
  AssetsStatusPropTypes,
  GroupedStatusMetricsLabelType,
  StatusMetrics,
} from "./AssetsStatus.types"
import * as S from "./AssetsStatus.styles"

const metricsTypes: GroupedStatusMetricsLabelType[] = [
  "current",
  "overdue",
  "collection",
  "gt_60_days_late",
  "processDefault",
]

const AssetsStatus: FC<AssetsStatusPropTypes> = ({ data }) => {
  const metricsData = data.reduce(
    (res, metric) => {
      if (metric.label === "process" || metric.label === "default") {
        res.processDefault.count += metric.count
        res.processDefault.amount += metric.amount
      } else {
        const label = metric.label as GroupedStatusMetricsLabelType
        res[label] = {
          amount: metric.amount,
          count: metric.count,
        }
      }

      res.totalAmount += metric.amount
      res.totalCount += metric.count
      return res
    },
    {
      current: {
        count: 0,
        amount: 0,
      },
      overdue: {
        count: 0,
        amount: 0,
      },
      collection: {
        count: 0,
        amount: 0,
      },
      gt_60_days_late: {
        count: 0,
        amount: 0,
      },
      processDefault: {
        count: 0,
        amount: 0,
      },
      totalAmount: 0,
      totalCount: 0,
    } as StatusMetrics
  )

  if (data.length === 0) {
    return (
      <S.AssetsStatusContainer empty>
        <FormattedMessage
          id="app.mypages.investments.assets.riskOverview.noAssets"
          defaultMessage="Inga innehav att visa"
        />
      </S.AssetsStatusContainer>
    )
  }

  return (
    <S.AssetsStatusContainer>
      <S.AssetsStatusColumn>
        <S.AssetsStatusItem isTitle>
          <FormattedMessage id="app.common.status" defaultMessage="Status" />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem>
          <FormattedMessage id="app.common.active" defaultMessage="Aktiv" />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.5-30"
            defaultMessage="5-30 dagar sen"
          />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.30-60"
            defaultMessage="30-60 dagar sen"
          />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.60+"
            defaultMessage="60+ dagar sen"
          />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.assetStatus.lateMonths.process"
            defaultMessage="Betalningsföreläggande"
          />
        </S.AssetsStatusItem>
        <S.AssetsStatusItem isTitle>
          <FormattedMessage id="app.common.total" defaultMessage="Total" />
        </S.AssetsStatusItem>
      </S.AssetsStatusColumn>
      <S.AssetsStatusColumn>
        <S.AssetsStatusItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.localCurrency"
            defaultMessage="Belopp"
          />
        </S.AssetsStatusItem>
        {metricsTypes.map((metric, i) => (
          <S.AssetsStatusItem key={i}>
            {formatNumber(metricsData[metric].amount, { style: "currency" })}
          </S.AssetsStatusItem>
        ))}
        <S.AssetsStatusItem isTitle>
          {formatNumber(metricsData.totalAmount, { style: "currency" })}
        </S.AssetsStatusItem>
      </S.AssetsStatusColumn>
      <S.AssetsStatusColumn>
        <S.AssetsStatusItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.quantity"
            defaultMessage="Antal"
          />
        </S.AssetsStatusItem>
        {metricsTypes.map((metric, i) => (
          <S.AssetsStatusItem key={i}>
            {metricsData[metric].count}
          </S.AssetsStatusItem>
        ))}
        <S.AssetsStatusItem isTitle>
          {metricsData.totalCount}
        </S.AssetsStatusItem>
      </S.AssetsStatusColumn>
    </S.AssetsStatusContainer>
  )
}

export default AssetsStatus
