import { FormattedMessage } from "react-intl"

export const linksData = [
  {
    text: (
      <FormattedMessage id="app.common.deposit" defaultMessage="Insättning" />
    ),
    link: "deposit",
  },
  {
    text: (
      <FormattedMessage
        id="app.mypages.investments.transactions.optionsNav.withdraw"
        defaultMessage="Uttag"
      />
    ),
    link: "withdraw",
  },
  {
    text: (
      <FormattedMessage
        id="app.mypages.investments.transactions.optionsNav.registeredDepositsAndWithdrawls"
        defaultMessage="Registrerade insättningar/uttag"
      />
    ),
    link: "registered-deposits",
  },
]
