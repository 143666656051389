import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import type {
  LoanTermContainerPropTypes,
  LoanTermItemPropTypes,
} from "./LoanTerm.types"

export const LoanTermContainer = styled.div<LoanTermContainerPropTypes>`
  align-items: ${(props) => (props.empty ? "center" : "top")};
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.empty ? "1fr" : "repeat(3, 1fr)")};
  text-align: left;
  gap: 4em;

  @media screen and (max-width: ${mediaQueries.medium}) {
    gap: 3em;
  }
`

export const LoanTermItem = styled.div<LoanTermItemPropTypes>`
  font-weight: ${(props) => (props.isTitle ? "bold" : "normal")};
  margin-top: ${(props) => (props.isTitle ? "1.5em" : "0")};
  margin-bottom: 2em;
  word-wrap: break-word;
  text-align: left;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`
export const LoanTermColumn = styled.div`
  display: grid;
  :not(:first-child) {
    justify-content: flex-end;
    justify-items: flex-end;
  }
`
