import styled from "styled-components"
import { flex } from "config/mixins"

export const LoadingContainer = styled.div`
  ${flex.center}
  flex: 1;
  width: 100%;
  height: 100%;
`

export const AssetsWrap = styled.div``
