import styled from "styled-components"
import { flex } from "config/mixins"
import { PieChartWrapperPropTypes } from "./PieChart.types"

export const PieChartWrapper = styled.div<PieChartWrapperPropTypes>`
  ${flex.center}
  height: ${(p) => (p.isError ? "180px" : "400px")};
  width: 100%;
  text-align: center;
  padding: 0;
  line-height: 24px;
  padding: 2em;
`
