import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const OnboardingStepsContainer = styled.div``

export const CounterContainer = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
`

export const StepContainer = styled.div``

export const BackButtonWrap = styled.div`
  display: flex;
  align-items: center;

  span {
    color: var(--dark-gray);
    margin: 0 10px 0 5px;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .icon-chevronRight {
      transform: rotate(180deg);
      path {
        stroke: var(--black);
      }
    }

    &:disabled {
      cursor: not-allowed;
      .icon-chevronRight {
        path {
          stroke: var(--light-gray);
        }
      }
    }
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    span {
      margin: 0 15px 0 10px;
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
