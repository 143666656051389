import { FC, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import { INVESTMENT_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import { InvestmentOnboardingData } from "../InvestmentOnboarding.types"
import * as S from "./Intro.styles"

type IntroPropsType = {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const Intro: FC<IntroPropsType> = ({ setStep }) => {
  const { accountID: id } = useParams() as { accountID: string }
  const investmentOnboardingData =
    getParsedStorageItemOrDefault<InvestmentOnboardingData>(
      INVESTMENT_ONBOARDING,
      {}
    )

  const handleNextStep = useCallback(() => {
    if (!investmentOnboardingData[id]) {
      const updatedStatus: InvestmentOnboardingData = {
        ...investmentOnboardingData,
        [id]: {
          brocc_has_started_investment_onboarding: true,
        },
      }
      setStorageItem(INVESTMENT_ONBOARDING, updatedStatus)
    }

    setStep((prev) => prev + 1)
  }, [setStep, id, investmentOnboardingData])

  return (
    <S.IntroContainer>
      <h1>
        <FormattedMessage
          id="app.mypages.investments.onboarding.intro.subheader"
          defaultMessage="Aktivera ditt konto med 3 enkla steg"
        />
      </h1>
      <S.ExplainationBox>
        <IconFactory name="personArmchairLaptop" />
        <S.ExplainationContent>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage
              id="app.mypages.investments.onboarding.intro.point1"
              defaultMessage="Investeringsprofil"
            />
          </p>
          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage
              id="app.mypages.investments.onboarding.intro.point2"
              defaultMessage="Formulär om kundkännedom"
            />
          </p>

          <p>
            <IconFactory name="squareBox" />
            <FormattedMessage
              id="app.mypages.investments.onboarding.intro.point3"
              defaultMessage="Gör en insättning"
            />
          </p>
          <Button onClick={handleNextStep}>
            <FormattedMessage
              id="app.mypages.investments.onboarding.intro.buttonCTA"
              defaultMessage="Påbörja aktivering"
            />
          </Button>
        </S.ExplainationContent>
      </S.ExplainationBox>
    </S.IntroContainer>
  )
}

export default Intro
