import { flex } from "config/mixins"
import styled, { css } from "styled-components"
import { mediaQueries, font } from "Styles/styleGlobal"

type GrayBoxBodyProps = {
  gray?: boolean
  oneString?: boolean
}

type ListItemType = {
  isBold?: boolean
}

export const ResultsWrap = styled.div`
  padding-top: 1em;
`

export const LoadingContainer = styled.div`
  ${flex.center}
  min-height: 400px;
  flex: 1;
`

export const ResultsBox = styled.div`
  ${flex.column}
  border-radius: 16px;
  background: ${(p) => p.theme.infoBox};
  min-height: 200px;
  width: 100%;
  height: 100%;
  border: 1px solid var(--light-gray);
  border-radius: 15px;
  h3 {
    margin: 0;
    padding: 1em;
  }

  ul:not(.recharts-default-legend) {
    list-style: none;
    ${flex.column}
    gap: 1.2em;
  }
`

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: ${(p: ListItemType) => (p?.isBold ? "bold" : "400")};
  span {
    font-weight: inherit;
  }
  & > div {
    margin: 0 !important;
  }
`

export const ListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0;
`

export const GrayBoxHeader = styled.div`
  h3 {
    font-size: ${font.size.lg};
  }
  border-bottom: 1px solid var(--light-gray);
`

export const GrayBoxBody = styled.div<GrayBoxBodyProps>`
  flex-grow: 1;
  padding: 35px 25px;
  color: ${(p) => (p.gray ? "var(--dark-gray)" : p.theme.fontColor)};
  ${(p) =>
    p.oneString &&
    css`
      ${flex.alignCenter}
      max-height: auto;
      flex-grow: 0.5;
    `}
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 35px 20px;
  }
`

export const Footer = styled.div`
  ${flex.alignCenter}
  max-height: auto;
  padding: 1em;
  color: var(--dark-gray);
  border-top: 1px solid var(--light-gray);
`
