import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { LoanApplicationStatusType } from "Interfaces/loanApplicationInterfaces"
import { font, mediaQueries } from "Styles/styleGlobal"
import { IconWrap } from "Pages/Authenticated/Savings/Home/UpdateDepositeName/UpdateDepositName.styles"
import { flex } from "config/mixins"
import {
  determineBankIcon,
  getBackgroundColorByStatus,
  getBackgroundColorByType,
} from "./helpers"

type ActiveAccountNamePropTypes = {
  scaled?: boolean
}

type AccountNameAndNumberWrapPropTypes = {
  scaled?: boolean
}

type AccountNumberPropTypes = {
  scaled?: boolean
}

type SVGWrapPropTypes = {
  type?: "loans" | "investments" | "savings" | "bank"
  status?: LoanApplicationStatusType
  name?: string
}

export const SVGWrap = styled.div<SVGWrapPropTypes>`
  ${flex.center}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  svg {
    width: 25px;
    height: 25px;
    rect {
      display: none;
    }
  }
  background-color: ${(p) => getBackgroundColorByType(p.type)};
  background: ${(p) => getBackgroundColorByStatus(p.status)};

  ${(p) =>
    p.type === "bank" &&
    css`
      width: 50px;
      height: 50px;
      background-image: url(${determineBankIcon(p.name)});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    `}
`

export const ActiveAccountName = styled.div<ActiveAccountNamePropTypes>`
  ${flex.alignCenter}
  gap: 0 1em;
  ${(p) =>
    p.scaled &&
    css`
      font-size: ${font.size.xl2};
      font-weight: bold;
      margin-bottom: 1.5em;
      min-height: 60px;
      div span {
        font-size: ${font.size.base};
      }
      ${SVGWrap} {
        transform: scale(1.3);
      }
      @media screen and (max-width: ${mediaQueries.medium}) {
        font-size: ${font.size.xl};
      }
    `}
`
export const AccountNameWrap = styled.div`
  display: flex;
  position: relative;
`
export const AccountNameAndNumberWrap = styled.div<AccountNameAndNumberWrapPropTypes>`
  ${flex.justifyCenter}
  flex-direction: column;
  &:hover {
    ${IconWrap} {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${(p) => (p.scaled ? font.size.xl : font.size.sm)};
  }
`
export const AccountNumber = styled.span<AccountNumberPropTypes>`
  color: var(--dark-gray);
  font-size: ${font.size.sm};
  line-height: 22px;
  @media screen and (max-width: ${mediaQueries.small}) {
    line-height: ${(p) => (p.scaled ? "22px" : "16px")};
  }
  ${(p) =>
    !p.scaled &&
    css`
      @media screen and (max-width: ${mediaQueries.small}) {
        font-size: ${font.size.xs};
        margin-top: 0.5em;
      }
    `}
`
export const IconWithBackLinkWrap = styled(Link)`
  position: relative;
  svg {
    &:nth-child(1) {
      width: 50px;
      height: 50px;
      transform: rotate(180deg);
      position: absolute;
      left: -60px;
      cursor: pointer;
      path {
        stroke: ${(p) => p.theme.fontColor};
      }
    }
  }
  @media screen and (max-width: 1500px) {
    margin-left: 45px;
  }
`
