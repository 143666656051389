import { FC, memo, useCallback } from "react"
import { useIntl } from "react-intl"
import Select from "UI/Select"
import { useRiskClassFilterOptions } from "./data"

type RiskClassFilterPropsType = {
  riskClass: string
  setRiskClass: React.Dispatch<React.SetStateAction<string>>
}

const RiskClassFilter: FC<RiskClassFilterPropsType> = ({
  riskClass,
  setRiskClass,
}) => {
  const intl = useIntl()
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRiskClass(e.currentTarget.value)
    },
    [setRiskClass]
  )

  const options = useRiskClassFilterOptions()

  return (
    <Select
      type="rounded"
      aria-label={intl.formatMessage({
        id: "app.mypages.investments.assets.myAssets.select.riskClass",
        defaultMessage: "Riskklass",
      })}
      options={options}
      onChange={handleChange}
      selected={riskClass}
    />
  )
}

export default memo(RiskClassFilter)
