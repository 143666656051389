import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { useCreateTrustlyDeposit } from "Hooks/API/useInvestmentServices"
import DepositForm from "Components/DepositForm"
import { useFeedbackModal } from "Context/modal-context"
import { Urls } from "Constants/urls"
import { DepositFormValues } from "Components/DepositForm/DepositForm.types"
import { TrustlyProps } from "../DepositOptions.types"
import * as S from "../DepositOptions.styles"

const Trustly: FC<TrustlyProps> = ({ id }) => {
  const { errorModal } = useFeedbackModal()
  const createTrustlyDepositMutation = useCreateTrustlyDeposit()

  const handleMakeTrustlyDeposit = (values: DepositFormValues) => {
    const payload = {
      amount: +values.amount,
      fail_url: `${window.location.origin}${Urls.InvestmentsTrustlyFail.replace(
        ":accoundID",
        id
      )}`,
      success_url: `${
        window.location.origin
      }${Urls.InvestmentsTrustlySuccess.replace(":accoundID", id)}`,
    }
    createTrustlyDepositMutation
      .mutateAsync({ uid: id, payload })
      .then((data) => {
        window.location.href = data.url
      })
      .catch(() =>
        errorModal(
          <FormattedMessage
            id="app.mypages.error.investment.useCreateTrustlyDeposit"
            defaultMessage="Underlåtenhet att göra en insättning för Trustly"
          />
        )
      )
  }

  return (
    <S.OptionsContent>
      <S.DepositOptionHeading>
        <FormattedMessage
          id="app.depositAccordion.trustly.body.heading"
          defaultMessage="Sätt in pengar via Trustly"
        />
      </S.DepositOptionHeading>
      <S.DepositOptionDescription>
        <FormattedMessage
          id="app.depositAccordion.trustly.body.description"
          defaultMessage="Direkt Banköverföring. Svenska bankman. Mobilt BankID."
        />
      </S.DepositOptionDescription>
      <DepositForm
        onSubmit={handleMakeTrustlyDeposit}
        isLoading={createTrustlyDepositMutation.isLoading}
        account="investments"
        minAmount={10000}
      />
    </S.OptionsContent>
  )
}

export default Trustly
