import { FC, useMemo, memo } from "react"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"

import { IInvestmentServiceSummary } from "Interfaces/investmentServicesInterfaces"
import ResultsBox from "Components/ResultsBox"
import { ResultListItem } from "Components/ResultsBox/ResultBox.types"
import { INVESTMENT_SERVICES_SUMMARY } from "Constants/queryKeys"
import { formatNumber } from "Utils/formatNumber"

type ResultsBalancePropsType = {
  accountID: string
}

const ResultsBalance: FC<ResultsBalancePropsType> = ({ accountID }) => {
  const queryClient = useQueryClient()
  const summary = queryClient.getQueryData([
    INVESTMENT_SERVICES_SUMMARY,
    accountID,
  ]) as IInvestmentServiceSummary

  const total = useMemo(
    () =>
      summary &&
      summary.account_summary.balances.loan_assets +
        summary.account_summary.balances.available_funds +
        summary.account_summary.balances.pending_withdrawal_funds,
    [summary]
  )

  const accruedReturn = useMemo(
    () =>
      summary &&
      summary.account_summary.balances.accrued_interests +
        summary.account_summary.balances.accrued_fees,
    [summary]
  )

  const totalIncl = accruedReturn + total

  const data: ResultListItem[] = [
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.loanedOut"
          defaultMessage="Utlånat"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.results.overviewBox.balance.loanedOut.info"
            defaultMessage="Bokförda värdet av lånetillgångar"
          />
        ),
        placement: "right",
      },
      value: formatNumber(summary?.account_summary.balances.loan_assets, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.notLoanedOut"
          defaultMessage="Ej utlånat"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.results.overviewBox.balance.notLoanedOut.info"
            defaultMessage="Tillgängligt kapital som kan användas till utlåningen eller uttag"
          />
        ),
        placement: "right",
      },
      value: formatNumber(summary.account_summary.balances.available_funds, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.reservedWithdrawal"
          defaultMessage="Ej utlånat"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.results.overviewBox.balance.reservedWithdrawal.info"
            defaultMessage="Kapital som reserverats för uttag"
          />
        ),
        placement: "top",
      },
      value: formatNumber(
        summary?.account_summary.balances.pending_withdrawal_funds,
        {
          style: "currency",
          currencyDisplay: "narrowSymbol",
        }
      ),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.total"
          defaultMessage="Total"
        />
      ),
      isBold: true,
      value: formatNumber(total, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.accruedReturn"
          defaultMessage="Upplupen avkastning"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.results.overviewBox.balance.accruedReturn.info"
            defaultMessage="Upplupen ränta efter avgifter"
          />
        ),
        placement: "top",
      },
      value: formatNumber(accruedReturn, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.totalIncludingaccruedReturn"
          defaultMessage="Total inkl. upplupen avkastning"
        />
      ),
      isBold: true,
      value: formatNumber(totalIncl, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
  ]

  return (
    <ResultsBox>
      <ResultsBox.Header>
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.balance.title"
          defaultMessage="Balans"
        />
      </ResultsBox.Header>
      <ResultsBox.Content data={data} />
    </ResultsBox>
  )
}

export default memo(ResultsBalance)
