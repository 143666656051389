import { FC, useState, useCallback } from "react"
import { AnimatePresence } from "framer-motion"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import Drawer from "UI/Drawer"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { useInvestmentServiceByIDQuery } from "Hooks/API/useInvestmentServices"
import * as S from "./DepositOptions.styles"
import { PaymentMethod, paymentMethodNames } from "./constants"

const DepositOptions: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const { accountID } = useParams() as { accountID: string }

  const { data, isLoading, isError } = useInvestmentServiceByIDQuery(accountID)

  const handleOpenDrawer = (index: number) => {
    setActiveIndex(index)
  }

  const handleCloseDrawer = useCallback(() => {
    setActiveIndex(0)
  }, [])

  const SelectedPaymentMethod = PaymentMethod[activeIndex]

  return (
    <>
      <AnimatePresence>
        {isModalOpen && (
          <Modal
            body={
              <>
                <p>
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.deposit.modal.paragraphOne"
                    defaultMessage="Sätt in eller swisha önskat belopp enligt uppgifterna här
                  intill. Överföringen tar i regel 1-2 bankdagar,
                  Swish-överföringar registrerar vi dagen efter. Så fort vi
                  registrerat transaktionen kan dina pengar börjas investeras ut
                  via Brocc."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="app.mypages.investments.onboarding.deposit.modal.paragraphTwo"
                    defaultMessage="Det lägsta insättningsbeloppet är 10000 kr. För insättningar
                    över 1 000 000 kr, kontakta vår kundtjänst på 08-50 12 15 80
                    eller help@brocc.se"
                  />
                </p>
              </>
            }
            onClick={() => setIsModalOpen(false)}
            title={
              <FormattedMessage
                id="app.mypages.investments.onboarding.deposit.modal.header"
                defaultMessage="Så fungerar insättningar"
              />
            }
          />
        )}
      </AnimatePresence>
      <S.DepositContent data-testid="deposit-options">
        {paymentMethodNames.map((method) => (
          <S.DepositMethod
            key={method.id}
            onClick={() => handleOpenDrawer(method.id)}
          >
            <S.DepositMethodNameWrap>
              <S.DepositMethodName>{method.name}</S.DepositMethodName>
              <S.DepositMethodDecription>
                {method.description}
              </S.DepositMethodDecription>
            </S.DepositMethodNameWrap>
            <IconFactory name="chevronRight" />
          </S.DepositMethod>
        ))}
      </S.DepositContent>
      <S.ModalLink onClick={() => setIsModalOpen(true)}>
        <FormattedMessage
          id="app.mypages.investments.onboarding.deposit.modalButton"
          defaultMessage="Så fungerar insättningar"
        />
      </S.ModalLink>
      <Drawer isOpen={!!activeIndex} handleClose={handleCloseDrawer} closeBtn>
        {isLoading && (
          <S.LoadingContainer>
            <Loading isCentered />
          </S.LoadingContainer>
        )}
        {isError && (
          <S.ErrorContainer>
            <h3>
              <FormattedMessage
                id="app.common.error.header"
                defaultMessage="Oj då! Något gick fel :("
              />
            </h3>
            <p>
              <FormattedMessage
                id="app.common.error.body"
                defaultMessage="Kontakta oss om problemen kvarstår."
              />
            </p>
          </S.ErrorContainer>
        )}
        {data && SelectedPaymentMethod && (
          <SelectedPaymentMethod
            id={accountID}
            productNumber={data.product_number}
          />
        )}
      </Drawer>
    </>
  )
}

export default DepositOptions
