import { FC, useReducer, useMemo } from "react"
import { FormattedMessage } from "react-intl"

import Loading from "UI/Loading"
import {
  useGetPortfolioMetrics,
  useGetDemographicMetrics,
} from "Hooks/API/useInvestmentServices"
import { MetricsGroupByType } from "ApiServices/investmentServices"
import PieChart from "Components/Charts/PieChart"
import Select from "UI/Select"
import ResultsBox from "Components/ResultsBox"
import DivergingBarChart from "Components/Charts/DivergingBarChart"
import GeographicChart from "Components/Charts/GeographicChart"
import { useOptions } from "./options"
import * as S from "./PieBarGeoSquere.styles"
import {
  PieBarGeoSquareProps,
  SelectedType,
  DemographicType,
} from "./PieBarGeoSquare.types"

const initialState = {
  metricType: "grade" as MetricsGroupByType,
  demographicType: "age" as DemographicType,
  isPieChartVisible: true,
}

const reducer = (state: typeof initialState, action: SelectedType) => {
  switch (action) {
    case "age":
    case "geography":
      return {
        ...state,
        demographicType: action,
        isPieChartVisible: false,
      }
    default:
      return {
        ...state,
        metricType: action as MetricsGroupByType,
        isPieChartVisible: true,
      }
  }
}

const PieBarGeoSquare: FC<PieBarGeoSquareProps> = ({ accountID }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const portfolioMetrics = useGetPortfolioMetrics(accountID, state.metricType)
  const demographicMetrics = useGetDemographicMetrics(accountID)
  const options = useOptions()

  const selected: SelectedType = useMemo(
    () => (state.isPieChartVisible ? state.metricType : state.demographicType),
    [state]
  )

  return (
    <ResultsBox>
      <ResultsBox.Header>
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestments.header"
          defaultMessage="Låneportfölj"
        />
      </ResultsBox.Header>
      <ResultsBox.Content>
        <S.SelectWrap>
          <Select
            type="rounded"
            options={options}
            selected={selected}
            onChange={(e) => dispatch(e.currentTarget.value as SelectedType)}
          />
        </S.SelectWrap>

        {state.isPieChartVisible ? (
          <>
            {portfolioMetrics.isLoading && (
              <S.LoadingErrorContainer>
                <Loading />
              </S.LoadingErrorContainer>
            )}
            {portfolioMetrics.isError && (
              <S.LoadingErrorContainer>
                <FormattedMessage
                  id="app.mypages.error.investment.charts"
                  defaultMessage="Det finns för närvarande ingenting att visa."
                />
              </S.LoadingErrorContainer>
            )}
            {portfolioMetrics.isSuccess && (
              <PieChart
                data={portfolioMetrics.data.data}
                metricType={state.metricType}
              />
            )}
          </>
        ) : (
          <>
            {demographicMetrics.isLoading && (
              <S.LoadingErrorContainer>
                <Loading />
              </S.LoadingErrorContainer>
            )}
            {demographicMetrics.isError && (
              <S.LoadingErrorContainer>
                <FormattedMessage
                  id="app.mypages.error.investment.charts"
                  defaultMessage="Det finns för närvarande ingenting att visa."
                />
              </S.LoadingErrorContainer>
            )}
            {demographicMetrics.isSuccess &&
              (state.demographicType === "age" ? (
                <DivergingBarChart data={demographicMetrics.data.data.ages} />
              ) : (
                <GeographicChart data={demographicMetrics.data.data.states} />
              ))}
          </>
        )}
      </ResultsBox.Content>
    </ResultsBox>
  )
}

export default PieBarGeoSquare
