import styled from "styled-components"

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: center;
`

export const InvestmentsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  flex: 1;
  h1 {
    order: 4;
  }
`
