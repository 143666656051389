import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { ellipsisText } from "config/mixins"
import {
  AssetsStatusContainerPropTypes,
  AssetsStatusItemPropTypes,
} from "./AssetsStatus.types"

export const AssetsStatusContainer = styled.div<AssetsStatusContainerPropTypes>`
  height: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.empty ? "1fr" : "repeat(3, 1fr)")};
  align-items: ${(props) => (props.empty ? "center" : "top")};
  text-align: left;
  gap: 4em;

  @media screen and (max-width: ${mediaQueries.medium}) {
    gap: 3em;
    grid-template-columns: ${(props) => (props.empty ? "1fr" : "1fr 1fr 50px")};
  }
`

export const AssetsStatusItem = styled.div<AssetsStatusItemPropTypes>`
  font-weight: ${(p) => (p.isTitle ? "bold" : "normal")};
  margin-top: ${(p) => (p.isTitle ? "1.5em" : "0")};
  margin-bottom: 2em;
  word-wrap: break-word;
  width: 100%;
  ${ellipsisText}
  @media screen and (max-width: ${mediaQueries.small}) {
    margin-bottom: 1em;
  }
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`
export const AssetsStatusColumn = styled.div`
  display: grid;
  :not(:first-child) {
    justify-content: flex-end;
    justify-items: flex-end;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    grid-template-rows: repeat(7, 65px);
    align-items: center;
  }
`
