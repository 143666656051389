import { FC, useState } from "react"
import { useParams } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { account } from "se-bank-account"
import { BankAccountPayload } from "ApiServices/loanApplications"
import Button from "UI/Button"
import { useUpdateBankAccount } from "Hooks/API/useLoansApplications"
import { TextField } from "Components/form/TextField"
import { yupResolver } from "@hookform/resolvers/yup"
import { useFeedbackModal } from "Context/modal-context"
import { BROCC_LANDING_LOGIN } from "Constants/externalLinks"
import { onboarding } from "config/animations"
import { Source } from "Interfaces/loanApplicationInterfaces"
import * as Styles from "../../Onboarding.styles"
import { AccountWrap, IsCustomerWrap } from "./BankAccountDetails.styles"
import LoanPerksBox from "../../Shared/LoanPerksBox"
import { StepPropsType } from "../../Onboarding.types"

const BankAccountDetailsSchema = yup.object().shape({
  bank_clearing_number: yup.string().required(),
  bank_account_number: yup.string().required(),
})

const BankAccountDetails: FC<StepPropsType> = ({ data }) => {
  const { id } = useParams() as { id: string }
  const [isValid, setIsValid] = useState(true)
  const updateBankAccountMutation = useUpdateBankAccount(id)
  const intl = useIntl()

  const { errorModal } = useFeedbackModal()

  const { handleSubmit, control, setError, setValue, getValues } = useForm({
    defaultValues: {
      bank_account_number: "",
      bank_clearing_number: "",
    },
    mode: "onChange",
    resolver: yupResolver(BankAccountDetailsSchema),
  })

  const validateAccount = () => {
    const values = getValues()
    const valid = account(
      `${values.bank_clearing_number}-${values.bank_account_number}`
    )
    setIsValid(valid)
    return valid
  }

  const onSubmit: SubmitHandler<BankAccountPayload> = (v) => {
    if (validateAccount()) {
      updateBankAccountMutation
        .mutateAsync(v)
        .then(() => {
          setValue("bank_account_number", "")
          setValue("bank_clearing_number", "")
        })
        .catch(() => {
          errorModal()
        })
    } else {
      const message = intl.formatMessage({
        id: "app.common.obligatory",
      })
      setError("bank_clearing_number", { message })
      setError("bank_account_number", { message })
    }
  }

  return (
    <Styles.Container>
      <h1>
        <FormattedMessage
          id="app.onboarding.bankAccountDetails.heading"
          defaultMessage="Ange lönekonto"
        />
      </h1>

      {data.source === Source.Handlarfinans ? (
        <p>
          <FormattedMessage
            id="app.onboarding.bankAccountDetails.description.handlarfinans"
            defaultMessage="Ange den bank som är ansluten till ditt lönekonto för verifiering och betalning med autogiro."
          />
        </p>
      ) : (
        <p>
          <FormattedMessage
            id="app.onboarding.bankAccountDetails.description"
            defaultMessage="Ange den bank som är ansluten till ditt lönekonto för verifiering och utbetalning."
          />
        </p>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <AccountWrap>
          <TextField
            control={control}
            name="bank_clearing_number"
            message={
              !isValid && (
                <FormattedMessage
                  id="app.onboarding.bankAccountDetails.input.mandatory"
                  defaultMessage="Obligatorisk"
                />
              )
            }
            placeholder={
              <FormattedMessage
                id="app.onboarding.bankAccountDetails.input.clearingNumber"
                defaultMessage="Clearingnummer"
              />
            }
            type="number"
            error={!isValid}
          />
          <TextField
            name="bank_account_number"
            control={control}
            message={
              !isValid && (
                <FormattedMessage
                  id="app.onboarding.bankAccountDetails.input.mandatory"
                  defaultMessage="Obligatorisk"
                />
              )
            }
            placeholder={
              <FormattedMessage
                id="app.onboarding.bankAccountDetails.input.bankAccount"
                defaultMessage="Bankkontonummer"
              />
            }
            type="number"
            error={!isValid}
          />
        </AccountWrap>
        <Button
          type="submit"
          disabled={updateBankAccountMutation.isLoading}
          isLoading={updateBankAccountMutation.isLoading}
        >
          <FormattedMessage
            id="app.onboarding.bankAccountDetails.nextCTAText"
            defaultMessage="Spara konto"
          />
        </Button>
      </form>

      <Styles.MobileAttentionBox>
        <LoanPerksBox estimatedCashBack={data.estimated_cashback} />
      </Styles.MobileAttentionBox>
    </Styles.Container>
  )
}

export default BankAccountDetails
