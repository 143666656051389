import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { Link, useParams } from "react-router-dom"

import IconFactory from "UI/IconFactory"

import Button from "UI/Button"
import { Urls } from "Constants/urls"
import { TrustlyWrap } from "./TrustlyFail.styles"

const TrustlyFail: FC = () => {
  const { accountID: id } = useParams() as { accountID: string }
  return (
    <TrustlyWrap>
      <IconFactory name="manBinoculars" data-testid="manBinoculars" />
      <h1 data-testid="title">
        <FormattedMessage
          id="app.mypages.trustly.fail.header"
          defaultMessage="Insättning misslyckades!"
        />
      </h1>
      <p data-testid="paragraph">
        <FormattedMessage
          id="app.mypages.trustly.fail.paragraph"
          defaultMessage="Din överföring har avbrutits. Vänligen försök igen eller kontakta kundsupport."
        />
      </p>
      <Link to={`${Urls.Investments}/${id}/${Urls.InvestmentsAccountOverview}`}>
        <Button data-testid="button">
          <FormattedMessage
            id="app.mypages.trustly.success.linkHome"
            defaultMessage="Gå tillbaka till hemsidan"
          />
        </Button>
      </Link>
    </TrustlyWrap>
  )
}

export default TrustlyFail
