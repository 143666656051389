import { FormattedMessage } from "react-intl"
import { Link, useParams } from "react-router-dom"

import IconFactory from "UI/IconFactory"
import Button from "UI/Button"
import { Urls } from "Constants/urls"
import * as S from "./TrustlySuccess.styles"

const TrustlySuccess = () => {
  const { accountID: id } = useParams() as { accountID: string }
  return (
    <S.TrustlyWrap>
      <IconFactory name="manRelaxing" data-testid="manOk" />
      <h1>
        <FormattedMessage
          id="app.mypages.trustly.success.header"
          defaultMessage="Insättningen genomförd!"
        />
      </h1>
      <p>
        <FormattedMessage
          id="app.mypages.trustly.success.paragraph"
          defaultMessage="Din insättning har blivit registrerad och kommer bokas in på ditt konto under nästa bankdag."
        />
      </p>
      <Link to={`${Urls.Investments}/${id}/${Urls.InvestmentsAccountOverview}`}>
        <Button>
          <FormattedMessage
            id="app.mypages.trustly.success.linkHome"
            defaultMessage="Gå tillbaka till hemsidan"
          />
        </Button>
      </Link>
    </S.TrustlyWrap>
  )
}

export default TrustlySuccess
