import { MessageDescriptor } from "react-intl"
import {
  InvestmentAssetsBorrowerOccupation,
  LoanPerfomanceStatusType,
} from "Interfaces/investmentServicesInterfaces"
import {
  ApplicantHousingType,
  ApplicantMaritalStatus,
} from "Interfaces/loanApplicationInterfaces"

export const housingTypes: Record<ApplicantHousingType, MessageDescriptor> = {
  condominium: {
    id: "app.common.housingOptions.condominum",
    defaultMessage: "Bostadsrätt",
  },
  house: {
    id: "app.common.housingOptions.house",
    defaultMessage: "Villa eller Radhus",
  },
  rental: {
    id: "app.common.housingOptions.rental",
    defaultMessage: "Hyresrätt",
  },
  tenant: {
    id: "app.common.housingOptions.tenant",
    defaultMessage: "Inneboende",
  },
}

export const martialStatuses: Record<
  ApplicantMaritalStatus,
  MessageDescriptor
> = {
  divorced: {
    id: "app.common.maritalOptions.divorced",
    defaultMessage: "Skild",
  },
  living_together: {
    id: "app.common.maritalOptions.livingTogether",
    defaultMessage: "Sambo",
  },
  married: { id: "app.common.maritalOptions.married", defaultMessage: "Gift" },
  single: {
    id: "app.common.maritalOptions.single",
    defaultMessage: "Ensamstående",
  },
}

export const occupationStatuses: Record<
  InvestmentAssetsBorrowerOccupation,
  MessageDescriptor
> = {
  pensioner: {
    id: "app.common.occupationOptions.pensions",
    defaultMessage: "Pensionär",
  },
  early_pensioner: {
    id: "app.common.occupationOptions.pensions",
    defaultMessage: "Pensionär",
  },
  full_time: {
    id: "app.common.occupationOptions.employed",
    defaultMessage: "Anställd",
  },
  hourly: {
    id: "app.common.occupationOptions.hourlyEmployed",
    defaultMessage: "Timmanställd",
  },
  self_employed: {
    id: "app.common.occupationOptions.selfEmployed",
    defaultMessage: "Egenföretagare",
  },
}

export const loanPerformanceStatuses: Record<
  LoanPerfomanceStatusType,
  MessageDescriptor
> = {
  current: {
    id: "app.mypages.loans.loan.performanceStatus.current",
    defaultMessage: "Aktiv",
  },
  process: {
    id: "app.mypages.loans.loan.performanceStatus.process",
    defaultMessage: "Betalningsföreläggande",
  },
  default: {
    id: "app.mypages.loans.loan.performanceStatus.default",
    defaultMessage: "Nedskrivna",
  },
  ended: {
    id: "app.mypages.loans.loan.performanceStatus.ended",
    defaultMessage: "Slutbetald",
  },
  overdue: {
    id: "app.mypages.loans.loan.performanceStatus.overdue",
    defaultMessage: "5-30 dagar sen",
  },
  collection: {
    id: "app.mypages.loans.loan.performanceStatus.collection",
    defaultMessage: "30-60 dagar sen",
  },
  gt_60_days_late: {
    id: "app.mypages.loans.loan.performanceStatus.gt_60_days_late",
    defaultMessage: "60+ dagar sen",
  },
}
