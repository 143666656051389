import { FC, useState } from "react"
import { FormattedMessage } from "react-intl"

import IconFactory from "UI/IconFactory"
import { getFirstName } from "Utils/getFirstName"
import Modal from "UI/Modal/Modal"

import * as S from "./UserGreetings.styles"

type UserGreetingsPropTypes = {
  username: string
  showLoanBanner?: boolean
}

const UserGreetings: FC<UserGreetingsPropTypes> = ({
  username,
  showLoanBanner,
}) => {
  const [isLoanModalOpen, setIsLoanModalOpen] = useState(false)

  const toggleLoanModalOpen = () => {
    setIsLoanModalOpen(!isLoanModalOpen)
  }

  const name = getFirstName(username)

  return (
    <>
      <h1>
        <FormattedMessage id="app.mypages.home.greeting" /> {name}!
      </h1>

      {showLoanBanner && (
        <>
          <S.LoanBanner type="button" onClick={toggleLoanModalOpen}>
            <IconFactory name="infoBadge" />
            &nbsp;
            <span>
              <FormattedMessage
                id="app.mypages.interestDecreaseBanner.heading"
                defaultMessage="Information om villkorsändring"
              />
            </span>
          </S.LoanBanner>

          {isLoanModalOpen && (
            <Modal
              title={
                <FormattedMessage
                  id="app.mypages.interestDecreaseBanner.heading"
                  defaultMessage="Information om villkorsändring"
                />
              }
              onClick={toggleLoanModalOpen}
              fullWidth
              maxWidth="700px"
              body={
                <p>
                  <FormattedMessage id="app.mypages.interestDecreaseBanner.text" />
                  2024&#8209;09&#8209;03.
                </p>
              }
            />
          )}
        </>
      )}
    </>
  )
}

export default UserGreetings
