import { useState, useEffect, FC, useCallback } from "react"
import { StyledInput } from "UI/Input/Input.styles"

import { AssetsInputPropsType } from "./AssetsInput.types"

const AssetsInput: FC<AssetsInputPropsType> = ({
  numberOfLoanAssets,
  uid,
  sellableAssets,
  setSellableAssets,
}) => {
  const [assetsCount, setAssetsCount] = useState<number | string>(1)

  useEffect(() => {
    if (sellableAssets.length === 0) {
      setAssetsCount(1)
    }
  }, [sellableAssets.length])

  const changeSellableAssets = useCallback(
    (value: number) => {
      setAssetsCount(value)
      const isSellableAsset = sellableAssets.find(
        (item) => item.loan_uid === uid
      )
      if (isSellableAsset) {
        const newSellableAssets = sellableAssets.map((item) => {
          if (item.loan_uid === uid) {
            return { ...item, number_of_assets_to_list: value }
          }
          return item
        })
        setSellableAssets(newSellableAssets)
      } else {
        const newSellableAsset = {
          loan_uid: uid,
          number_of_assets_to_list: value,
          checked: false,
        }
        setSellableAssets([...sellableAssets, newSellableAsset])
      }
    },
    [setAssetsCount, setSellableAssets, uid, sellableAssets]
  )

  const handleBlur = useCallback(() => {
    if (assetsCount === "") setAssetsCount(1)
  }, [assetsCount])

  const handleChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (+value > numberOfLoanAssets) return
      if (+value === 0) {
        setAssetsCount("")
        return
      }
      changeSellableAssets(+value)
    },
    [numberOfLoanAssets, changeSellableAssets]
  )

  return (
    <StyledInput
      sellAssets
      type="number"
      aria-label="count"
      min={1}
      max={numberOfLoanAssets}
      value={assetsCount}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default AssetsInput
