import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { GeoChartWrapperPropTypes } from "./GeographicChart.types"

export const GeoChartWrapper = styled.div<GeoChartWrapperPropTypes>`
  ${flex.center}
  height: ${(p) => (p.isError ? "200px" : "400px")};
  width: auto;
  text-align: center;
  padding: 0 2em;
  line-height: 24px;
  color: var(--black);
  @media screen and (max-width: ${mediaQueries.small}) {
    padding: 0;
  }
  @media screen and (max-width: 355px) {
    svg {
      > g {
        transform: scale(0.8);
      }
    }
  }
  @media screen and (max-width: 1200px) and (min-width: ${mediaQueries.large}) {
    svg {
      > g {
        transform: translate(-25px, 0) scale(0.8);
      }
    }
  }
`
