import { FC, useState, useEffect, useMemo, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useInView } from "react-intersection-observer"
import { useParams } from "react-router-dom"

import {
  useGetInvestmentAssets,
  useListAssets,
} from "Hooks/API/useInvestmentServices"
import Button from "UI/Button"
import Loading from "UI/Loading"
import { useFeedbackModal } from "Context/modal-context"
import TableHeader from "./TableHeader"
import Table from "./Table"
import {
  AssetsTableProps,
  SellableAssetType,
  ListAssetsPayloadType,
} from "./AssetsTable.types"
import * as S from "./AssetsTable.styles"

const AssetsTable: FC<AssetsTableProps> = ({
  isSelling,
  riskClass,
  termDuration,
  status,
  prioritySelling,
  isEnded,
}) => {
  const { ref, inView } = useInView()

  const [sellableAssets, setSellableAssets] = useState<SellableAssetType[]>([])
  const { accountID } = useParams() as { accountID: string }
  const { successModal, errorModal } = useFeedbackModal()
  const assets = useGetInvestmentAssets(
    accountID,
    riskClass,
    termDuration,
    status
  )

  const sellAssets = useListAssets()

  const isDisabledBtn: boolean = useMemo(
    () => !sellableAssets.find((item) => item.checked) || sellAssets.isLoading,
    [sellAssets.isLoading, sellableAssets]
  )

  const handleSellMarkedAssets = () => {
    const listings = sellableAssets.reduce(
      (acc: ListAssetsPayloadType[], item: SellableAssetType) => {
        if (item.checked) {
          const asset = {
            loan_uid: item.loan_uid,
            number_of_assets_to_list: item.number_of_assets_to_list,
            method: prioritySelling ? "priority" : "best_effort",
          } as ListAssetsPayloadType
          acc.push(asset)
        }
        return acc
      },
      []
    )
    const payload = {
      listings,
    }
    sellAssets.mutate({ uid: accountID, payload })
  }

  const handleSellAllAssets = () => {
    const payload = {
      method: prioritySelling ? "priority" : "best_effort",
    } as Pick<ListAssetsPayloadType, "method">
    sellAssets.mutate({ uid: accountID, payload })
  }

  const loadMore = useCallback(() => assets.fetchNextPage(), [assets])

  useEffect(() => {
    if (sellAssets.isSuccess) {
      successModal(
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.sellStatus.successMessage"
          defaultMessage="Dina innehav har listats för försäljning och kommer att vara
        synliga under utlagt till försäljning inom några minuter. Notera
        att ditt valda innehav utlagt till försäljning fortfarande
        kommer vara synligt under “Sälj innehav” fram tills dessa sålts."
        />
      )
      setSellableAssets([])
    }

    if (sellAssets.isError) {
      errorModal(
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.sellStatus.failMessage"
          defaultMessage="Något gick fel. Prova igen"
        />
      )
    }
  }, [errorModal, sellAssets.isError, sellAssets.isSuccess, successModal])

  useEffect(() => {
    if (inView && !isSelling && assets.hasNextPage && !assets.isFetching) {
      loadMore()
    }
  }, [inView, isSelling, loadMore, assets.hasNextPage, assets.isFetching])

  if (assets.isLoading) {
    return (
      <S.AssetsTableContainer className="loading">
        <Loading isCentered />
      </S.AssetsTableContainer>
    )
  }

  if (assets.isError) {
    return (
      <S.AssetsTableContainer isEmpty>
        <FormattedMessage
          id="app.mypages.error.investment.getListInvestmentAssets"
          defaultMessage="Kan inte få en förteckning över dina investeringstillgångar. Försök igen"
        />
      </S.AssetsTableContainer>
    )
  }

  return (
    <>
      {assets.isSuccess && assets.data && assets.data.pages.length > 0 ? (
        <S.AssetsTableContainer data-testid="assets-table">
          <TableHeader isSelling={isSelling} isEnded={isEnded} />
          <Table
            isSelling={isSelling}
            isEnded={isEnded}
            assetsList={assets.data.pages}
            sellableAssets={sellableAssets}
            setSellableAssets={setSellableAssets}
          />
        </S.AssetsTableContainer>
      ) : (
        <S.AssetsTableContainer isEmpty>
          <h3>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.noData"
              defaultMessage="Du har inga innehav att visa just nu."
            />
          </h3>
        </S.AssetsTableContainer>
      )}
      <span ref={ref} />

      <S.LoadMoreWrap>
        {assets.isFetching ? (
          <Loading isCentered />
        ) : (
          assets.hasNextPage &&
          isSelling && (
            <S.LoadMore type="button" onClick={loadMore}>
              <FormattedMessage
                id="app.mypages.home.show.more"
                defaultMessage="Visa fler"
              />
            </S.LoadMore>
          )
        )}
      </S.LoadMoreWrap>

      {isSelling && assets.data && assets.data.pages.length > 0 && (
        <S.ButtonWrap>
          <Button
            variant="inverse"
            disabled={sellAssets.isLoading}
            onClick={handleSellAllAssets}
          >
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.sellButton.sellAll"
              defaultMessage="Sälj allt"
            />
          </Button>
          <Button disabled={isDisabledBtn} onClick={handleSellMarkedAssets}>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.sellButton.sellSelected"
              defaultMessage="Sätt ut markerade innehav till försäljning"
            />
          </Button>
        </S.ButtonWrap>
      )}
    </>
  )
}

export default AssetsTable
