import { useState } from "react"
import type { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Button from "UI/Button"
import { Urls } from "Constants/urls"
import IconFactory from "UI/IconFactory"
import type { IInvestmentAsset } from "Interfaces/investmentServicesInterfaces"
import Drawer from "UI/Drawer"
import FiltersModal from "./FiltersModal/FiltersModal"
import { initialFilterValues } from "./config"
import HoldingsLIst from "./HoldingsList/HoldingsList"
import ExtendedInfo from "./ExtendedInfo/ExtendedInfo"
import * as S from "./Holdings.styles"

const Holdings: FC = () => {
  const intl = useIntl()
  const [filters, setFilters] = useState(initialFilterValues)
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false)
  const [extendedInfo, setExtendedInfo] = useState<IInvestmentAsset | null>(
    null
  )

  const openFiltersModal = () => setIsFiltersModalOpen(true)
  const closeFiltersModal = () => setIsFiltersModalOpen(false)

  return (
    <S.MyAssets data-testid="holdings">
      <S.LinksWrap>
        <Button
          component="link"
          variant="chevron"
          to={Urls.InvestmentsAccountHoldingsSellAssets}
          icon={<IconFactory name="chevronRight" />}
        >
          <FormattedMessage
            id="app.mypages.investments.assets.myAssets.link.sellAssets"
            defaultMessage="Sälj innehav"
          />
        </Button>
        <Button
          component="link"
          variant="chevron"
          to={Urls.InvestmentsAccountHoldingsPrepareForSale}
          icon={<IconFactory name="chevronRight" />}
        >
          <FormattedMessage
            id="app.mypages.investments.assets.myAssets.link.prepareForSale"
            defaultMessage="Utlagt till försäljning"
          />
        </Button>
        <Button
          component="link"
          variant="chevron"
          to={Urls.InvestmentsAccountHoldingsEndedAssets}
          icon={<IconFactory name="chevronRight" />}
        >
          <FormattedMessage
            id="app.mypages.investments.assets.myAssets.link.endedAssets"
            defaultMessage="Avslutade innehav"
          />
        </Button>
      </S.LinksWrap>

      <S.HoldingsListBlock data-testid="assets-table">
        <S.ListBlockHeader>
          <h4>
            {intl.formatMessage({
              id: "app.mypages.investments.assets.assetsList.title",
              defaultMessage: "Mina innehav",
            })}
          </h4>
          <S.FilterButton type="button" onClick={openFiltersModal}>
            <IconFactory name="filter" width={24} height={24} />
          </S.FilterButton>
        </S.ListBlockHeader>

        <HoldingsLIst
          setExtendedInfo={setExtendedInfo}
          riskClass={filters.riskClass}
          status={filters.status}
          termDuration={filters.termDuration}
        />

        <Drawer
          isOpen={!!extendedInfo}
          handleClose={() => setExtendedInfo(null)}
          closeBtn
        >
          {extendedInfo && <ExtendedInfo info={extendedInfo} />}
        </Drawer>

        {isFiltersModalOpen && (
          <FiltersModal
            closeModal={closeFiltersModal}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </S.HoldingsListBlock>
    </S.MyAssets>
  )
}

export default Holdings
