import { FC, useState, useMemo, memo } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import IconFactory from "UI/IconFactory"
import Checkbox from "UI/Checkbox"
import { ISecondaryAsset } from "Interfaces/investmentServicesInterfaces"
import { determineStatus } from "./helpers"
import * as S from "../AssetsTable/AssetsTable.styles"

type MobileRowForTableProps = {
  asset: ISecondaryAsset
  checkedToUnlistAssets: number[]
  updateCheckedToUnlistAssets: (asset: ISecondaryAsset) => void
}

const MobileRowForTable: FC<MobileRowForTableProps> = ({
  asset,
  checkedToUnlistAssets = [],
  updateCheckedToUnlistAssets,
}) => {
  const [showMobileRow, setShowMobileRow] = useState(false)
  const intl = useIntl()
  const status = useMemo(
    () => determineStatus(asset.loan_performance_status),
    [asset.loan_performance_status]
  )

  const isChecked = useMemo(
    () => !!checkedToUnlistAssets.find((item) => item === asset.loan_number),
    [asset.loan_number, checkedToUnlistAssets]
  )

  return (
    <S.MobileAssetsTableWrap key={`${asset.loan_number}-mobile`} isSelling>
      <S.AssetsTableItem>
        <label>
          <Checkbox
            size="large"
            name={asset.loan_number.toString()}
            value={asset.loan_number.toString()}
            color="white"
            checked={isChecked}
            onChange={() => updateCheckedToUnlistAssets(asset)}
          />
        </label>
      </S.AssetsTableItem>
      <S.AssetsTableItem>{asset.loan_number}</S.AssetsTableItem>
      <S.AssetsTableItem>{status}</S.AssetsTableItem>
      <S.AssetsTableItem>
        <S.Grade grade={asset.loan_grade}>{asset.loan_grade}</S.Grade>
      </S.AssetsTableItem>
      <S.AssetsTableItem chevron>
        <S.ExpandButton
          onClick={() => setShowMobileRow(!showMobileRow)}
          show={showMobileRow}
        >
          <IconFactory name="chevronRight" />
        </S.ExpandButton>
      </S.AssetsTableItem>
      <S.MobileRowWrap show={showMobileRow} mobile>
        <S.MobileRow>
          <div>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.term"
                  defaultMessage="Löptid"
                />
                :
              </strong>{" "}
              {asset.loan_remaining_term_in_days}
            </S.MobileRowItem>
            <S.MobileRowItem />
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.loanInterestRate"
                  defaultMessage="Utlåningsränta"
                />
                :
              </strong>{" "}
              {intl.formatNumber(asset.loan_interest_rate, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "percent",
              })}
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.amountOfLoanShares"
                  defaultMessage="Antal låneandelar"
                />
                :
              </strong>{" "}
              {asset.number_of_loan_assets}
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.bookValue"
                  defaultMessage="Bokfört värde"
                />
                :
              </strong>{" "}
              {intl.formatNumber(asset.nominal_value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </S.MobileRowItem>
            <S.MobileRowItem />
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.accruedInterest"
                  defaultMessage="Upplupen ränta"
                />
                :
              </strong>{" "}
              {intl.formatNumber(asset.accrued_interest, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </S.MobileRowItem>
            <S.MobileRowItem />
          </div>
        </S.MobileRow>
      </S.MobileRowWrap>
    </S.MobileAssetsTableWrap>
  )
}

export default memo(MobileRowForTable)
