import styled from "styled-components"
import { flex } from "config/mixins"
import { OverviewWrap } from "Components/InvestmentAggregate/InvestmentAggregate.styles"

export const UnlistAssetsWrap = styled.div`
  padding-top: 1em;
  ${OverviewWrap} {
    margin: 3em 0;
  }
`
export const UnlistAssetsDesc = styled.p`
  margin-bottom: 2em;
`
export const LoadingContainer = styled.div`
  ${flex.center}
  flex: 1;
`
