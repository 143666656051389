import { FormattedMessage } from "react-intl"

export const getBackgroundColorByGrade = (grade?: string): string | null => {
  const grades: Record<string, string> = {
    A: "#8FBBAF",
    B: "#F9D682",
    C: "#B486B9",
    D: "#E6E6E6",
    E: "#E48483",
    F: "#96AB59",
  }
  return grade ? grades[grade] : null
}

export const determineInvestmentStatus = (status: string) => {
  if (status === "active") {
    return <FormattedMessage id="app.common.active" defaultMessage="Aktiv" />
  }
  return <FormattedMessage id="app.common.inactive" defaultMessage="Inaktiv" />
}
