import styled, { css } from "styled-components"

import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import * as T from "./AssetsTable.types"
import { getBackgroundColorByGrade } from "./helpers"

export const AssetsTableHead = styled.div<T.AssetsTableHeadProps>`
  ${flex.center}
  font-size: ${font.size.sm};
  font-weight: bold;
  hyphens: auto;
  text-align: center;

  ${(props) =>
    props.hideMobile &&
    css`
      @media screen and (max-width: ${mediaQueries.large}) {
        display: none;
      }

      display: flex;
    `}
  ${(props) =>
    props.showMobile &&
    css`
      @media screen and (max-width: ${mediaQueries.large}) {
        display: flex;
      }

      display: none;
    `};
`

export const AssetsTableContainer = styled.div<T.AssetsTableContainerProps>`
  border-radius: 20px;
  margin: 0;
  text-align: center;
  &.loading {
    ${flex.justifyCenter}
    flex-direction: column;
  }

  h3 {
    @media screen and (max-width: ${mediaQueries.medium}) {
      font-size: ${font.size.base};
    }
  }

  ${(p) => css`
    background: ${p.theme.infoBox};
    ${p.isEmpty &&
    css`
      ${flex.center}
      padding: 2em 0;
      min-height: auto;
      font-weight: 500;
    `}
  `}
`

export const AssetsTableItem = styled.div<T.AssetsTableItemProps>`
  ${flex.center}
  font-size: ${font.size.sm};

  input {
    min-height: 35px;
    padding: 0;
  }

  ${(p) => css`
    ${p.chevron &&
    css`
      display: none;
      align-items: end;
    `}

    @media screen and (max-width: ${mediaQueries.large}) {
      display: ${p.hide && "none"};
      ${p.chevron &&
      css`
        ${flex.alignCenter}
        svg path {
          stroke: var(--black);
        }
      `}
    }
  `}
`

export const AssetsTableItemWrap = styled.div<T.AssetsTableItemWrapProps>`
  display: grid;
  gap: 0 1.5em;
  padding: 1em;
  width: 100%;
  &:hover {
    background-color: var(--very-light-gray);
  }
  ${(p) => css`
    grid-template-columns: ${p.isEnded ? "repeat(8, 1fr)" : "repeat(9, 1fr)"};
    grid-template-columns: ${p.withCheckbox && "50px repeat(8, 1fr)"};
  `}
  @media screen and (max-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const AssetsTableHeadWrap = styled.div<T.AssetsTableHeadWrapProps>`
  display: grid;
  gap: 0 1.5em;
  padding: 1em;
  border-bottom: 1px solid var(--light-gray);

  ${(p) => css`
    grid-template-columns: ${p.isEnded ? "repeat(8, 1fr)" : "repeat(9, 1fr)"};
    grid-template-columns: ${p.withCheckbox && "50px repeat(8, 1fr)"};

    @media screen and (max-width: ${mediaQueries.large}) {
      grid-template-columns: ${p.isSelling
        ? "repeat(5, 1fr)"
        : "repeat(4, 1fr)"};
    }
  `}
`

export const Grade = styled.div<T.GradeProps>`
  ${flex.center}
  border-radius: 50%;
  padding: 1em;
  text-align: center;
  height: 26px;
  width: 26px;
  font-weight: bold;
  color: var(--white);
  background: ${(p) => getBackgroundColorByGrade(p.grade)};
`
export const ButtonWrap = styled.div`
  display: flex;
  gap: 1em;
  margin: 2em 0 0 0;

  @media screen and (max-width: ${mediaQueries.large}) {
    flex-direction: column;
    gap: 1.5em 0;
    width: 100%;
  }
`

export const MobileAssetsTableWrap = styled.div<T.MobileAssetsTableWrapProps>`
  display: none;

  @media screen and (max-width: ${mediaQueries.large}) {
    display: grid;
    grid-template-columns: ${(p) =>
      p.isSelling ? "repeat(5, 1fr)" : "repeat(4, 1fr)"};
    border-top: 1px solid var(--light-gray);
    padding: 1em 0;
    width: 100%;
  }
`

export const ExpandButton = styled.button<T.ExpandButtonProps>`
  border: none;
  background: none;
  transition: 100ms all ease-in-out;
  transform: ${(p) => (p.show ? "rotate(90deg)" : "rotate(0deg)")};

  &:focus {
    box-shadow: none;
  }
`
export const MobileRow = styled.div`
  display: flex;
  margin: 0.5em 0 0.25em 0;
  padding-left: 0.25em;
  text-align: left;
  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowWrap = styled.div<T.MobileRowWrapProps>`
  ${(p) => css`
    display: ${p.show ? "block" : "none"};
    ${p.mobile &&
    css`
      grid-row-start: 2;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 6;
    `}
  `}

  @media screen and (min-width: ${mediaQueries.large}) {
    display: none;
  }
`

export const MobileRowItem = styled.div`
  margin: 0.5em 0;
`
export const LoadMoreWrap = styled.div`
  ${flex.center}
  width: 100%;
  padding-top: 1em;
`
export const LoadMore = styled.button`
  text-align: center;
  text-decoration: underline;
  margin: 0 auto;
  outline: none;
`
