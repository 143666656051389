import { KYCPropTypes } from "Components/KYC/KYC.types"
import { FC } from "react"
import { MessageDescriptor } from "react-intl"

export type StepsTitlesType = Record<number, MessageDescriptor>

export type InvestmentsStepsType = {
  [key: number]:
    | FC<{ setStep: React.Dispatch<React.SetStateAction<number>> }>
    | FC<KYCPropTypes>
}

export enum InvestmentsOnboardingStepsEnum {
  Intro,
  KYC,
  InvestmentProfile,
  Deposit,
}

export type InvestmentOnboardingData = Record<
  string,
  {
    brocc_has_started_investment_onboarding?: boolean
    brocc_has_investment_choices?: boolean
  }
>
