import axios from "ApiServices/axios"
import type { AxiosError } from "axios"
import { Api } from "Constants/api"
import type {
  ClassGroupType,
  IAccountBalanceMetrics,
  IDemographicMetrics,
  IInvestmentAggregate,
  IInvestmentAssets,
  IInvestmentCurrentBalance,
  IInvestmentProfile,
  IInvestmentService,
  IInvestmentServices,
  IInvestmentServiceSettings,
  IInvestmentServiceSummary,
  IInvestmentTransactions,
  InvestmentAssestsParams,
  ISecondaryAssets,
  IUpcomingRepaymentPlans,
  PortfolioMetricsType,
  ReinvestmentPreferenceType,
  TermGroupType,
} from "Interfaces/investmentServicesInterfaces"

export type TrustlyDepositPayloadType = {
  amount: number
  fail_url: string
  success_url: string
}

export type InvestmentProfilePayloadType = {
  class_group: ClassGroupType
  term_group: TermGroupType
}

export type MetricsGroupByType = "loan_status" | "term_left" | "grade"

export type TermDurationType = {
  loan_term_gte?: number
  loan_term_lte?: number
}

export type AccountBalancesMetricsType =
  | "account_balance"
  | "loan_assets"
  | "return"

export type AccountBalanceTimeSpanType =
  | "1_month"
  | "1_year"
  | "3_months"
  | "3_years"
  | "since_start"
  | "this_year"
  | "week"

export type ListAssetsPayloadType = {
  uid: string
  payload:
    | {
        method: "best_effort" | "forced" | "priority" | "swift"
      }
    | {
        listings: {
          method: "best_effort" | "forced" | "priority" | "swift"
          number_of_assets_to_list: number
          loan_uid: string
        }[]
      }
}

export type UnlistAssetsPayloadType = {
  uid: string
  payload: { loan_numbers: number[] }
}

export const getInvestmentServices = async () => {
  try {
    const { data } = await axios.get<IInvestmentServices>(
      Api.InvestmentServices
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentServiceByID = async (id: string) => {
  try {
    const { data } = await axios.get<IInvestmentService>(
      Api.InvestmentService.replace(":id", id)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentServiceSettings = async (uid: string) => {
  try {
    const { data } = await axios.get<IInvestmentServiceSettings>(
      Api.InvestmentServiceSettings.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentServiceSummary = async (uid: string) => {
  try {
    const { data } = await axios.get<IInvestmentServiceSummary>(
      Api.InvestmentServiceSummary.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getPortfolioMetrics = async (
  uid: string,
  params: MetricsGroupByType
) => {
  try {
    const { data } = await axios.get<PortfolioMetricsType>(
      Api.InvestmentServicePortfolios.replace(":id", uid),
      {
        params: {
          group_by: params,
        },
      }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDemographicMetrics = async (uid: string) => {
  try {
    const { data } = await axios.get<IDemographicMetrics>(
      Api.InvestmentServiceDemographics.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getUpcomingRepaymentPlans = async (uid: string) => {
  try {
    const { data } = await axios.get<IUpcomingRepaymentPlans>(
      Api.InvestmentServiceUpcomingRepaymentPlans.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentAssets = async (
  uid: string,
  params: InvestmentAssestsParams
) => {
  try {
    const { data } = await axios.get<IInvestmentAssets>(
      Api.InvestmentServiceInvestments.replace(":id", uid),
      { params }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getSecondaryAssets = async (uid: string) => {
  try {
    const {
      data: { data },
    } = await axios.get<ISecondaryAssets>(
      Api.InvestmentServiceSecondaryMarketListings.replace(":id", uid)
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getAccountBalancesMetrics = async (
  uid: string,
  type: AccountBalancesMetricsType,
  timeSpan: AccountBalanceTimeSpanType
) => {
  try {
    const { data } = await axios.get<IAccountBalanceMetrics>(
      Api.InvestmentServiceAccountBalancesMetrics.replace(":id", uid).replace(
        ":type",
        type
      ),
      {
        params: {
          time_span: timeSpan,
        },
      }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentAggregate = async (
  uid: string,
  params: { active: number }
) => {
  try {
    const { data } = await axios.get<IInvestmentAggregate>(
      Api.InvestmentServiceInvestmentsAggregate.replace(":id", uid),
      { params }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getTransactions = async (uid: string, page: number) => {
  try {
    const { data } = await axios.get<IInvestmentTransactions>(
      Api.InvestmentServiceTransactions.replace(":id", uid),
      {
        params: {
          page,
        },
      }
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createTrustlyDeposit = async ({
  uid,
  payload,
}: {
  uid: string
  payload: TrustlyDepositPayloadType
}) => {
  try {
    const { data } = await axios.post(
      Api.InvestmentServiceTrustlyDeposits.replace(":id", uid),
      payload
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateReinvestmentPreferences = async (
  uid: string,
  payload: { reinvestment_preference: ReinvestmentPreferenceType }
): Promise<IInvestmentServiceSettings> => {
  try {
    const { data } = await axios.put(
      Api.InvestmentServiceSettings.replace(":id", uid),
      payload
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateInvestmentProfile = async (
  uid: string,
  payload: InvestmentProfilePayloadType
): Promise<IInvestmentProfile> => {
  try {
    const { data } = await axios.put(
      Api.InvestmentServiceInvestmentProfile.replace(":id", uid),
      payload
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const listAssets = ({ uid, payload }: ListAssetsPayloadType) => {
  try {
    return axios.post(
      Api.InvestmentServiceSecondaryMarketListings.replace(":id", uid),
      payload
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const unlistAssets = ({ uid, payload }: UnlistAssetsPayloadType) => {
  try {
    return axios.delete(
      Api.InvestmentServiceSecondaryMarketListings.replace(":id", uid),
      { data: payload }
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const postInvestmentWithdrawalAmount = async ({
  uid,
  amount,
}: {
  uid: string
  amount: number
}) => {
  try {
    const { data } = await axios.post(
      Api.InvestmentServiceWithdrawals.replace(":id", uid),
      {
        amount,
      }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getInvestmentCurrentBalance = async (uid: string) => {
  try {
    const { data } = await axios.get<IInvestmentCurrentBalance>(
      Api.InvestmentServiceCurrentBalance.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}
