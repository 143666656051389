import { Urls } from "Constants/urls"
import AccountLayout from "Layouts/AccountLayout"
import PageLayout from "Layouts/PageLayout"
import ServiceLayout from "Layouts/ServiceLayout"
import InvestmentsAccounts from "Pages/Authenticated/Investments/Accounts/InvestmentsAccounts"
import Assets from "Pages/Authenticated/Investments/Assets/Assets"
import EndedAssets from "Pages/Authenticated/Investments/Assets/EndedAssets/EndedAssests"
import UnlistAssets from "Pages/Authenticated/Investments/Assets/UnlistAssets/UnlistAssets"
import InvestmentsHome from "Pages/Authenticated/Investments/Home/Home"
import InvestmentChoices from "Pages/Authenticated/Investments/InvestmentChoices/InvestmentChoices"
import InvestmentsOnboarding from "Pages/Authenticated/Investments/Onboarding/InvestmentsOnboarding"
import Results from "Pages/Authenticated/Investments/Results/Results"
import Transactions from "Pages/Authenticated/Investments/Transactions/Transactions"
import SellAssets from "Pages/Authenticated/Investments/Assets/SellAssets/SellAssets"
import RegisteredDepositsWithdrawals from "Pages/Authenticated/Investments/Transactions/RegisteredDepositsWithdrawals"
import DepositInvestment from "Pages/Authenticated/Investments/Transactions/Deposit"
import WithdrawalsInvestment from "Pages/Authenticated/Investments/Transactions/Withdrawals"
import { getInvestmentServiceByIDQueryData } from "Hooks/API/useInvestmentServices"
import { useLoader } from "Hooks/API/useLoader"
import { queryClient } from "./router"

const createInvestmentsRoutes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const loader = useLoader(queryClient, getInvestmentServiceByIDQueryData)

  return {
    path: Urls.Investments,
    element: <PageLayout type="investments" />,
    children: [
      {
        path: Urls.Investments,
        children: [
          {
            element: <InvestmentsAccounts />,
            index: true,
          },
          {
            path: Urls.InvestmentsOnboarding,
            element: <InvestmentsOnboarding />,
            loader,
          },
          {
            element: <ServiceLayout type="investments" />,
            loader,
            children: [
              {
                path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountOverview}`,
                element: <InvestmentsHome />,
              },
              {
                path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountHoldings}`,
                element: <Assets />,
              },
              {
                path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountResults}`,
                element: <Results />,
              },
              {
                path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountInvestmentChoises}`,
                element: <InvestmentChoices />,
              },
              {
                path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountTransactions}`,
                element: <Transactions />,
              },
            ],
          },
          {
            path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountHoldings}`,
            element: <AccountLayout type="assets" />,
            loader,
            children: [
              {
                path: `${Urls.InvestmentsAccountHoldingsSellAssets}`,
                element: <SellAssets />,
                index: true,
              },
              {
                path: Urls.InvestmentsAccountHoldingsEndedAssets,
                element: <EndedAssets />,
              },
              {
                path: Urls.InvestmentsAccountHoldingsPrepareForSale,
                element: <UnlistAssets />,
              },
            ],
          },
          {
            path: `${Urls.InvestmentsAccount}/${Urls.InvestmentsAccountTransactions}`,
            element: <AccountLayout type="transactions" />,
            loader,
            children: [
              {
                path: Urls.InvestmentsAccountTransactionsDeposit,
                element: <DepositInvestment />,
              },
              {
                path: Urls.InvestmentsAccountTransactionsWithdraw,
                element: <WithdrawalsInvestment />,
              },
              {
                path: Urls.InvestmentsAccountTransactionsRegisteredDeposits,
                element: <RegisteredDepositsWithdrawals />,
              },
            ],
          },
        ],
      },
    ],
  }
}

const investmentRoutes = createInvestmentsRoutes()

export default investmentRoutes
