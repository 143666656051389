import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useGetDepositServiceByID } from "Hooks/API/useDepositServices"
import FilterButton from "./FilterButton"

import {
  useButtonsArray,
  oneDay,
  yesterday,
  formatDate,
  firstDayOfMonth,
  firstDayOfPrevMonth,
  lastDayOfPreviousMonth,
  startOfPrevYear,
  lastDayOfPrevYear,
} from "./data"
import {
  FilterButtonVariants,
  ReportFiltersType,
  RangeValueTypeOmitCustom,
} from "./ReportFilters.types"

import {
  FilterContainer,
  ButtonGroup,
  DatePickerWrap,
  DatePickerInput,
} from "./ReportFilters.styles"

const ReportFilters: FC<ReportFiltersType> = ({ id, setRangeDate, type }) => {
  const [activeButton, setActiveButton] =
    useState<FilterButtonVariants>("months")

  const { data } = useGetDepositServiceByID(id, type === "savings")

  const [customDate, setCustomDate] = useState({
    start_date: formatDate(firstDayOfMonth),
    end_date: formatDate(yesterday),
  })

  const buttonsArray = useButtonsArray()

  const filteredButtonsArray =
    type === "investments"
      ? buttonsArray.filter((item) => item.range !== "since_start")
      : buttonsArray

  const handleClick = useCallback(
    (range: FilterButtonVariants) => setActiveButton(range),
    [setActiveButton]
  )

  const handleChangeDateStart = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const currentDate = new Date(target.value).getTime()

      if (currentDate > new Date(customDate.end_date).getTime()) {
        setCustomDate({
          start_date: target.value,
          end_date: target.value,
        })
      } else {
        setCustomDate((prev) => ({
          ...prev,
          start_date: target.value,
        }))
      }
    },
    [customDate]
  )

  const handleChangeDateEnd = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const currentDate = new Date(target.value).getTime()

      if (currentDate < new Date(customDate.start_date).getTime()) {
        setCustomDate({
          start_date: target.value,
          end_date: target.value,
        })
      } else {
        setCustomDate((prev) => ({
          ...prev,
          end_date: target.value,
        }))
      }
    },
    [customDate]
  )

  const rangeDate = useMemo(() => {
    const rangeValue: RangeValueTypeOmitCustom = {
      months: new Date(yesterday).setMonth(new Date().getMonth() - 3),
      current_year: new Date(yesterday.getFullYear(), 0, 1).getTime() + oneDay,
      one_year: new Date().setFullYear(new Date().getFullYear() - 1) - oneDay,
      since_start: new Date(
        data?.activation_datetime ? data.activation_datetime : yesterday
      ).getTime(),
    }
    if (activeButton === "custom") {
      return customDate
    }
    if (activeButton === "months") {
      return {
        start_date: formatDate(firstDayOfPrevMonth),
        end_date: formatDate(lastDayOfPreviousMonth),
      }
    }
    if (activeButton === "one_year") {
      return {
        start_date: formatDate(startOfPrevYear),
        end_date: formatDate(lastDayOfPrevYear),
      }
    }

    return {
      start_date: formatDate(new Date(rangeValue[activeButton])),
      end_date: formatDate(yesterday),
    }
  }, [activeButton, data, customDate])

  useEffect(() => {
    setRangeDate(rangeDate)
  }, [rangeDate, setRangeDate])

  return (
    <div>
      <p>
        {type === "savings" ? (
          <FormattedMessage
            id="app.mypages.reports.savings.creation.description"
            defaultMessage="Genom att skapa en raport för ditt sparkonto kan du på ett enkelt sätt få en sammanställning av utvecklingen på kontot."
          />
        ) : (
          <FormattedMessage
            id="app.mypages.reports.investments.creation.description"
            defaultMessage="Genom att skapa en investerarrapport kan du på ett enkelt sätt få en sammanställning av utvecklingen på dina konton."
          />
        )}
      </p>
      <FilterContainer>
        {filteredButtonsArray.map(({ range, text }) => (
          <FilterButton
            key={range}
            onClick={handleClick}
            active={range === activeButton}
            text={text}
            range={range}
          />
        ))}
      </FilterContainer>
      {activeButton === "custom" && (
        <DatePickerWrap>
          <ButtonGroup>
            <DatePickerInput
              type="date"
              value={customDate.start_date}
              max={formatDate(yesterday)}
              onChange={handleChangeDateStart}
            />
          </ButtonGroup>
          <ButtonGroup>
            <FormattedMessage
              id="app.mypages.report.filter.button.title"
              defaultMessage="Till"
            />
          </ButtonGroup>
          <ButtonGroup>
            <DatePickerInput
              type="date"
              value={customDate.end_date}
              max={formatDate(yesterday)}
              onChange={handleChangeDateEnd}
            />
          </ButtonGroup>
        </DatePickerWrap>
      )}
    </div>
  )
}

export default ReportFilters
