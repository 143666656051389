import { FC, useCallback, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import { useGetDepositServices } from "Hooks/API/useDepositServices"
import Loading from "UI/Loading"
import Select from "UI/Select"
import { useQueryClient } from "react-query"
import { IDepositServices } from "Interfaces/depositInterfaces"
import {
  DEPOSIT_SERVICES_DATA,
  INVESTMENT_SERVICES_DATA,
} from "Constants/queryKeys"
import { DepositServiceStatus } from "types/enums"
import { IInvestmentServices } from "Interfaces/investmentServicesInterfaces"
import ReportsTable from "./ReportsTable"
import ReportFilters from "./ReportFilters"
import InvestmentsAccounts from "./InvestmentsAccounts"
import SavingsAccounts from "./SavingsAccounts"

import { useOptions } from "./data"
import { AccountTypes } from "./Reports.types"
import { LoadingWrap, ReportsWrap, SelectLabel } from "./Reports.styles"

const Reports: FC = () => {
  const queryClient = useQueryClient()

  const [type, setType] = useState<AccountTypes>("savings")

  const { isLoading: isDepositsLoading } = useGetDepositServices()
  const { isLoading: isInvestmentsLoading } = useInvestmentServices()

  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) =>
      setType(event.target.value as AccountTypes),
    [setType]
  )

  const options = useOptions()

  const savingsData = queryClient.getQueryData<IDepositServices>(
    DEPOSIT_SERVICES_DATA
  )
  const investmentsData = queryClient.getQueryData<IInvestmentServices>(
    INVESTMENT_SERVICES_DATA
  )

  const filteredSavings = useMemo(
    () =>
      savingsData?.data.filter(
        (deposit) => deposit.status === DepositServiceStatus.active
      ),
    [savingsData]
  )

  const filteredInvestments = useMemo(
    () => investmentsData?.data.filter((item) => item.status === "active"),
    [investmentsData]
  )

  const hasTwoAccountType = useMemo(() => {
    return (
      Boolean(filteredSavings?.length) && Boolean(filteredInvestments?.length)
    )
  }, [filteredSavings, filteredInvestments])

  const currentAccountType = {
    investments: InvestmentsAccounts,
    savings: SavingsAccounts,
  }
  const Element = currentAccountType[type]

  if (isDepositsLoading || isInvestmentsLoading) {
    return (
      <LoadingWrap data-testid="loader">
        <Loading />
      </LoadingWrap>
    )
  }

  return (
    <ReportsWrap>
      {hasTwoAccountType && (
        <SelectLabel>
          <FormattedMessage
            id="app.mypages.reports.select"
            defaultMessage="Välj kontotyp"
          />
          <Select
            name="type"
            options={options}
            selected={type}
            onChange={handleSelect}
          />
        </SelectLabel>
      )}

      <Element>
        {(id, setRangeDate) => (
          <ReportFilters id={id} setRangeDate={setRangeDate} type={type} />
        )}
      </Element>

      <ReportsTable />
    </ReportsWrap>
  )
}

export default Reports
