import { useMutation, useQuery, useQueryClient } from "react-query"

import {
  getLoanApplications,
  createLoanApplication,
  LoanApplicationPayload,
  updateLoanApplication,
  LoanApplicationUpdatePayload,
  getLoanApplicationById,
  createLoanApplicationSignature,
  transitionToSigned,
  updateBankAccount,
  updatePaymentProtectionInsurance,
  updateDocumentation,
  generateAgreement,
  acceptLoanApplication,
  BankAccountPayload,
  DocumentationType,
  saveBankAccountScreening,
  storeKYCLoanApplication,
  getLoanApplicationSignatureStatus,
  createSignatureInsuranceAgreement,
  getInsuranceAgreementSignatureStatus,
  LoanCollection,
  collectLoans,
  getLoanApplicationFormById,
} from "ApiServices/loanApplications"
import { IAMLQuestionsRequest } from "Pages/Unauthenticated/Onboarding/Steps/Accept/AMLQuestions/AMLQuestions.types"
import {
  LOAN_APPLICATIONS_APPLICATION,
  LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE,
  LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE_CREATE,
  LOAN_APPLICATIONS_DATA,
  LOAN_APPLICATIONS_SIGN,
  LOAN_APPLICATIONS_SIGNATURE,
  LOAN_APPLICATIONS_APPLICATION_FORM,
} from "Constants/queryKeys"
import {
  LoanApplicationAgreementSignature,
  LoanApplicationSignatureStatus,
} from "Interfaces/loanApplicationInterfaces"
import { useState, useEffect, useMemo } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import {
  LoanApplicationSignatureStatusEnum,
  LoanApplicationSignatureProgressStatus,
} from "types/enums"

export function useGetLoanApplications() {
  return useQuery({
    queryKey: [LOAN_APPLICATIONS_DATA],
    queryFn: getLoanApplications,
    staleTime: 0,
    retry: 0,
  })
}

export const useCreateLoanApplication = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: LoanApplicationPayload) =>
      createLoanApplication(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_DATA])
    },
  })
}

interface IUseUpdateLoanApplication {
  payload: LoanApplicationUpdatePayload
  uid: string
}

export function useUpdateLoanApplication({
  payload,
  uid,
}: IUseUpdateLoanApplication) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => updateLoanApplication(payload, uid),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, uid])
    },
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/loan-applications/paths/~1loan-application~1%7Buid%7D~1signed/put} */
export function useTransitionToSigned(uid: string) {
  const queryClient = useQueryClient()
  return useMutation<{ signed: boolean }>({
    mutationFn: () => transitionToSigned(uid),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_error, _variables, context: any) => {
      if (context?.application) {
        queryClient.setQueriesData(
          [LOAN_APPLICATIONS_APPLICATION],
          context.application
        )
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION])
    },
  })
}

export function useAcceptLoanApplication(uid: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => acceptLoanApplication(uid),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, uid])
    },
  })
}

export function useGenerateAgreement(uid: string) {
  return useMutation({
    mutationFn: () => generateAgreement(uid),
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/loan-applications/paths/~1loan-applications~1%7Buid%7D~1signatures/post} */
export function useCreateLoanApplicationSignature(uid: string) {
  return useMutation<
    LoanApplicationAgreementSignature,
    unknown,
    { isCoApplicant: boolean }
  >({
    mutationKey: [LOAN_APPLICATIONS_SIGN, uid],
    mutationFn: ({ isCoApplicant }) =>
      createLoanApplicationSignature(uid, isCoApplicant),
  })
}

export function useGetLoanApplication(uid: string) {
  return useQuery({
    queryKey: [LOAN_APPLICATIONS_APPLICATION, uid],
    queryFn: () => getLoanApplicationById(uid),
    staleTime: 0,
  })
}

export const useGetLoanApplicationFormById = (uid?: string) => {
  return useQuery({
    queryKey: [LOAN_APPLICATIONS_APPLICATION_FORM, uid],
    queryFn: () => getLoanApplicationFormById(uid!),
    staleTime: 0,
    retry: 0,
    enabled: !!uid,
    onError: (error: any) => error,
  })
}

export function useUpdateBankAccount(id: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (application: BankAccountPayload) =>
      updateBankAccount(application, id),
    onSuccess() {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, id])
    },
  })
}

interface IUseUpdatePaymentProtectionInsurance {
  isPPI: {
    payment_protection_insurance: boolean
  }
}

export function useUpdatePaymentProtectionInsurance(uid: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ isPPI }: IUseUpdatePaymentProtectionInsurance) =>
      updatePaymentProtectionInsurance(isPPI, uid),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, uid])
    },
  })
}

interface IUseUpdateDocumentation {
  documentation: DocumentationType
}

export function useUpdateDocumentation(uid: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ documentation }: IUseUpdateDocumentation) =>
      updateDocumentation(documentation, uid),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, uid])
    },
  })
}

interface IUseCollectLoans {
  loansCollected: LoanCollection[]
}

export function useCollectLoans(uid: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ loansCollected }: IUseCollectLoans) =>
      collectLoans(loansCollected, uid),
    onSuccess: () => {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, uid])
    },
  })
}

export const useSaveBankAccountScreening = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: saveBankAccountScreening,
    onSuccess() {
      queryClient.invalidateQueries([LOAN_APPLICATIONS_APPLICATION, id])
    },
  })
}

export const useStoreKYCLoanApplication = (uid: string) => {
  return useMutation({
    mutationFn: (payload: IAMLQuestionsRequest) =>
      storeKYCLoanApplication(uid, payload),
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/loan-applications/paths/~1loan-applications~1%7Buid%7D~1signatures~1%7Bsignature-uid%7D/get} */
export const useGeLoanApplicationSignatureStatus = ({
  enabled,
  signatureUid,
  uid,
}: {
  uid: string
  signatureUid: string
  enabled: boolean
}) => {
  return useQuery<LoanApplicationSignatureStatus>({
    queryKey: [LOAN_APPLICATIONS_SIGNATURE, uid],
    queryFn: () => getLoanApplicationSignatureStatus(uid, signatureUid),
    refetchInterval: 1500,
    enabled,
  })
}

export const useGetInsuranceAgreementSignatureStatus = ({
  enabled,
  signatureUid,
  uid,
}: {
  uid: string
  signatureUid: string
  enabled: boolean
}) => {
  return useQuery({
    queryKey: [LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE, uid],
    queryFn: () => getInsuranceAgreementSignatureStatus(uid, signatureUid),
    refetchInterval: 1500,
    enabled,
  })
}

export function useCreateSignatureInsuranceAgreement() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMutation<any, unknown, string>({
    mutationKey: [LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE_CREATE],
    mutationFn: createSignatureInsuranceAgreement,
  })
}

const signLoanContractErrorMessages: Record<
  "error" | "transitionToSignedError",
  MessageDescriptor
> = {
  error: {
    id: "app.onboarding.signing.errors",
    defaultMessage: "Något gick fel",
  },
  transitionToSignedError: {
    id: "app.common.error.header",
    defaultMessage: "Oj då! Något gick fel :(",
  },
}

export const useSignLoanApplication = ({
  accountID,
  isCoApplicant,
  handleTransitionToSigned,
  isTransitionToSignedError = false,
  handleCloseModal,
}: {
  accountID: string
  isCoApplicant: boolean
  handleTransitionToSigned: () => Promise<{ signed: boolean }>
  isTransitionToSignedError: boolean
  handleCloseModal: () => void
}) => {
  const intl = useIntl()

  const [isSignError, setIsSignError] = useState(false)
  const [isSignatureStatusQueryEnabled, setIsSignatureStatusQueryEnabled] =
    useState(true)

  const createLoanAppSignatureMutation =
    useCreateLoanApplicationSignature(accountID)
  const loanApplicationSignatureStatusQuery =
    useGeLoanApplicationSignatureStatus({
      uid: accountID,
      signatureUid: createLoanAppSignatureMutation.data?.uid ?? "",
      enabled:
        !!createLoanAppSignatureMutation.data?.uid &&
        isSignatureStatusQueryEnabled,
    })

  const startSigning = () => {
    setIsSignError(false)
    setIsSignatureStatusQueryEnabled(true)
    createLoanAppSignatureMutation.mutateAsync({ isCoApplicant })
  }

  const cancelSigning = () => {
    setIsSignError(false)
    setIsSignatureStatusQueryEnabled(false)
  }

  useEffect(() => {
    if (
      loanApplicationSignatureStatusQuery.data?.status ===
      LoanApplicationSignatureStatusEnum.complete
    ) {
      setIsSignatureStatusQueryEnabled(false)
      handleTransitionToSigned()
        .then(() => {
          handleCloseModal()
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Transition to signed error")
        })
      return
    }
    if (
      createLoanAppSignatureMutation.data?.status ===
        LoanApplicationSignatureStatusEnum.error ||
      createLoanAppSignatureMutation.data?.progress_status ===
        LoanApplicationSignatureProgressStatus.alreadyInProgress ||
      loanApplicationSignatureStatusQuery.data?.status ===
        LoanApplicationSignatureStatusEnum.error
    ) {
      setIsSignatureStatusQueryEnabled(false)
      setIsSignError(true)
      loanApplicationSignatureStatusQuery.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createLoanAppSignatureMutation.data?.status,
    createLoanAppSignatureMutation.data?.progress_status,
    loanApplicationSignatureStatusQuery.data?.status,
  ])

  const errorMessage = useMemo(() => {
    if (
      createLoanAppSignatureMutation.data?.progress_status ===
        LoanApplicationSignatureProgressStatus.alreadyInProgress ||
      loanApplicationSignatureStatusQuery.data?.status ===
        LoanApplicationSignatureStatusEnum.error ||
      createLoanAppSignatureMutation.isError
    ) {
      return intl.formatMessage(signLoanContractErrorMessages.error)
    }

    if (isTransitionToSignedError) {
      return intl.formatMessage(
        signLoanContractErrorMessages.transitionToSignedError
      )
    }

    return intl.formatMessage({
      id: "app.login.error.retry",
      defaultMessage: "Internt tekniskt fel. Försök igen.",
    })
  }, [
    isTransitionToSignedError,
    createLoanAppSignatureMutation.isError,
    loanApplicationSignatureStatusQuery.data?.status,
    createLoanAppSignatureMutation.data?.progress_status,
    intl,
  ])
  return {
    isSignError,
    errorMessage,
    qrCode:
      loanApplicationSignatureStatusQuery.data?.qr_code ||
      createLoanAppSignatureMutation.data?.qr_code ||
      "",
    autostartToken: createLoanAppSignatureMutation.data?.auto_start_token || "",
    isSigning:
      loanApplicationSignatureStatusQuery.data?.progress_status ===
        "user_sign" ||
      loanApplicationSignatureStatusQuery.data?.progress_status === "complete",
    isLoading: createLoanAppSignatureMutation.isLoading,
    startSigning,
    cancelSigning,
  }
}

const signInsuranceAgreementErrorMessages: Record<
  "error" | "updateInsuranceError",
  MessageDescriptor
> = {
  error: {
    id: "app.onboarding.signing.errors",
    defaultMessage: "Något gick fel",
  },
  updateInsuranceError: {
    id: "app.common.error.header",
    defaultMessage: "Oj då! Något gick fel :(",
  },
}

export const useSignInsuranceAgreement = ({
  accountID,
  handleUpdatePaymentProtectionInsurance,
  onClose,
  isUpdateInsuranceError,
}: {
  accountID: string
  handleUpdatePaymentProtectionInsurance: (values: {
    withInsurance: boolean
  }) => Promise<unknown>
  onClose: () => void
  isUpdateInsuranceError: boolean
}) => {
  const intl = useIntl()

  const [isSignError, setIsSignError] = useState(false)
  const [isSignatureStatusQueryEnabled, setIsSignatureStatusQueryEnabled] =
    useState(true)

  const createSignatureInsuranceAgreementMutation =
    useCreateSignatureInsuranceAgreement()
  const insuranceAgreementSignatureStatusQuery =
    useGetInsuranceAgreementSignatureStatus({
      uid: accountID,
      signatureUid: createSignatureInsuranceAgreementMutation.data?.uid ?? "",
      enabled:
        !!createSignatureInsuranceAgreementMutation.data?.uid &&
        isSignatureStatusQueryEnabled,
    })

  const startSigning = () => {
    setIsSignError(false)
    setIsSignatureStatusQueryEnabled(true)
    createSignatureInsuranceAgreementMutation.mutateAsync(accountID)
  }

  const cancelSigning = () => {
    setIsSignError(false)
    setIsSignatureStatusQueryEnabled(false)
  }

  useEffect(() => {
    if (
      insuranceAgreementSignatureStatusQuery.data?.status ===
      LoanApplicationSignatureStatusEnum.complete
    ) {
      setIsSignatureStatusQueryEnabled(false)
      handleUpdatePaymentProtectionInsurance({ withInsurance: true })
        .then(onClose)
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Sign insurance agreement error")
        })
      return
    }
    if (
      createSignatureInsuranceAgreementMutation.data?.progress ===
        LoanApplicationSignatureStatusEnum.error ||
      createSignatureInsuranceAgreementMutation.data?.progress_status ===
        LoanApplicationSignatureProgressStatus.alreadyInProgress ||
      insuranceAgreementSignatureStatusQuery.data?.status ===
        LoanApplicationSignatureStatusEnum.error
    ) {
      setIsSignError(true)
      setIsSignatureStatusQueryEnabled(false)
      insuranceAgreementSignatureStatusQuery.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createSignatureInsuranceAgreementMutation.data?.status,
    createSignatureInsuranceAgreementMutation.data?.progress_status,
    insuranceAgreementSignatureStatusQuery.data?.status,
  ])

  const errorMessage = useMemo(() => {
    if (
      createSignatureInsuranceAgreementMutation.data?.progress_status ===
        LoanApplicationSignatureProgressStatus.alreadyInProgress ||
      insuranceAgreementSignatureStatusQuery.data?.status ===
        LoanApplicationSignatureStatusEnum.error ||
      createSignatureInsuranceAgreementMutation.isError
    ) {
      return intl.formatMessage(signInsuranceAgreementErrorMessages.error)
    }

    if (isUpdateInsuranceError) {
      return intl.formatMessage(
        signInsuranceAgreementErrorMessages.updateInsuranceError
      )
    }

    return intl.formatMessage({
      id: "app.login.error.retry",
      defaultMessage: "Internt tekniskt fel. Försök igen.",
    })
  }, [
    isUpdateInsuranceError,
    createSignatureInsuranceAgreementMutation.isError,
    insuranceAgreementSignatureStatusQuery.data?.status,
    createSignatureInsuranceAgreementMutation.data?.progress_status,
    intl,
  ])

  return {
    isSignError,
    errorMessage,
    qrCode:
      insuranceAgreementSignatureStatusQuery.data?.qr_code ||
      createSignatureInsuranceAgreementMutation.data?.qr_code ||
      "",
    autostartToken:
      createSignatureInsuranceAgreementMutation.data?.auto_start_token || "",
    isSigning:
      insuranceAgreementSignatureStatusQuery.data?.progress_status ===
        "user_sign" ||
      insuranceAgreementSignatureStatusQuery.data?.progress_status ===
        "complete",
    isLoading: createSignatureInsuranceAgreementMutation.isLoading,
    startSigning,
    cancelSigning,
  }
}
