import styled from "styled-components"
import { RiskClassContainerProps, RiskClassItemProps } from "./RiskClass.types"

export const RiskClassContainer = styled.div<RiskClassContainerProps>`
  align-items: ${(p) => (p.empty ? "center" : "top")};
  height: 100%;
  display: grid;
  grid-template-columns: ${(p) => (p.empty ? "1fr" : "repeat(3, 1fr)")};
  gap: 4em;
`

export const RiskClassItem = styled.div<RiskClassItemProps>`
  min-height: 26px;
  font-weight: ${(p) => (p.isTitle ? "bold" : "normal")};
  margin-top: ${(p) => (p.isTitle ? "1.5em" : "0")};
  margin-bottom: 1.5em;
  word-wrap: break-word;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`
export const RiskClassColumn = styled.div`
  display: grid;
  :not(:first-child) {
    justify-content: flex-end;
    justify-items: flex-end;
  }
`
