import { useMemo, useState, useEffect, useCallback } from "react"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import PromoWrap from "Components/PromoWrap"
import { getInvestmentServiceSummary } from "ApiServices/investmentServices"
import { IInvestmentService } from "Interfaces/investmentServicesInterfaces"

import { FormattedMessage } from "react-intl"
import Loading from "UI/Loading"
import { Urls } from "Constants/urls"
import { BROCC_LANDING_INVESTMENTS } from "Constants/externalLinks"
import { LoaderWrapper, InvestmentsWrapper } from "./InvestmentsAccounts.styles"

export interface IData {
  accountNumber: number
  balance: number
  link: string
  status: string
  id: string
}

const InvestmentsAccounts = () => {
  const { data: investments, isLoading, isError } = useInvestmentServices()
  const [investmentData, setInvestmentData] = useState<IData[]>([])

  const investmentsPromises = useMemo(
    () =>
      investments
        ? investments.data
            .sort((a, b) => b.status.localeCompare(a.status))
            .map((item) => getInvestmentServiceSummary(item.uid))
        : [],
    [investments]
  )

  const getInvestmentsInfo = useCallback(
    async (invests: IInvestmentService[]) => {
      const investmentsInfo = await Promise.all(investmentsPromises).then(
        (balances) =>
          balances.map((item, i) => ({
            id: invests[i].uid,
            accountNumber: invests[i].product_number,
            status: invests[i].status,
            balance:
              item.account_summary.balances.before_unrealized_value_changes,
            link:
              invests[i].status === "created"
                ? `${Urls.Investments}/${Urls.InvestmentsOnboarding.replace(
                    ":accountID",
                    invests[i].uid
                  )}`
                : `${Urls.Investments}/${invests[i].uid}/${Urls.InvestmentsAccountOverview}`,
          }))
      )
      setInvestmentData(investmentsInfo)
    },
    [investmentsPromises]
  )

  const instmentmentsLoading = useMemo(
    () =>
      investments?.data &&
      investments?.data.length > 0 &&
      investmentData.length === 0,
    [investmentData.length, investments?.data]
  )

  useEffect(() => {
    if (investments?.data && investments?.data.length > 0) {
      getInvestmentsInfo(investments.data)
    }
  }, [getInvestmentsInfo, investments?.data])

  return (
    <InvestmentsWrapper>
      {(isLoading || instmentmentsLoading) && (
        <LoaderWrapper>
          <Loading />
        </LoaderWrapper>
      )}
      {investmentData.length > 0 && (
        <>
          <h1>
            <FormattedMessage
              id="app.mypages.investments.accounts.heading"
              defaultMessage="Välj investeringskonto"
            />
          </h1>
          <PromoWrap
            type="investments"
            secondTitleId="app.mypages.main.investmentsTitle"
            descriptionId="app.mypages.main.investmentsDescription"
            accountTitleId="app.common.account.investmentAccount"
            linkText="app.mypages.mainNav.investments.requiresOnboarding"
            link={BROCC_LANDING_INVESTMENTS}
            data={investmentData}
          />
        </>
      )}
      {isError && (
        <h2>
          <FormattedMessage
            id="app.mypages.investments.accounts.error"
            defaultMessage="Det går inte att ladda investeringskonton"
          />
        </h2>
      )}
    </InvestmentsWrapper>
  )
}

export default InvestmentsAccounts
