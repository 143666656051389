import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import type { InternetBankProps } from "../DepositOptions.types"
import * as S from "../DepositOptions.styles"

const InternetBank: FC<InternetBankProps> = ({ productNumber }) => (
  <S.OptionsContent>
    <S.DepositOptionHeading>
      <FormattedMessage
        id="app.depositAccordion.internetBanking.body.heading"
        defaultMessage="Deposit money with a bankgiro transfer"
      />
    </S.DepositOptionHeading>
    <S.DepositOptionDescription>
      <FormattedMessage
        id="app.depositAccordion.internetBanking.body.description"
        defaultMessage="Sätt in pengar i 2 enkla steg. Insättningar tar vanligtvis 1 vardag."
      />
    </S.DepositOptionDescription>
    <ol>
      <li>
        <FormattedMessage
          id="app.depositAccordion.internetBanking.body.list.1"
          defaultMessage="Logga in på din internetbank"
        />
      </li>
      <li>
        <FormattedMessage
          values={{
            accountNumber: <strong>{productNumber}</strong>,
            marketplaceNumber: <strong>625-1813</strong>,
          }}
          id="app.depositAccordion.internetBanking.body.list.2"
          defaultMessage="Utför inbetalning från din bank till bankgiro {marketplaceNumber} med
        OCR-nummer {accountNumber}"
        />
      </li>
    </ol>
  </S.OptionsContent>
)

export default InternetBank
