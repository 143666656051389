import { FC, memo } from "react"
import { useIntl } from "react-intl"
import Select from "UI/Select"
import { useTerminFilterOptions } from "./data"

type TermFilterPropsType = {
  termDuration: number
  setTermDuration: React.Dispatch<React.SetStateAction<number>>
}

const TermFilter: FC<TermFilterPropsType> = ({
  termDuration,
  setTermDuration,
}) => {
  const intl = useIntl()
  const options = useTerminFilterOptions()
  return (
    <Select
      type="rounded"
      aria-label={intl.formatMessage({
        id: "app.mypages.investments.assets.myAssets.select.term",
        defaultMessage: "Löptid",
      })}
      options={options}
      onChange={(e) => setTermDuration(parseInt(e.currentTarget.value, 10))}
      selected={termDuration.toString()}
    />
  )
}

export default memo(TermFilter)
