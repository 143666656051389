import { FC, useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import { useGetTransactions } from "Hooks/API/useInvestmentServices"
import TransactionsTable from "Components/TransactionsTable"
import Loading from "UI/Loading"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./RegisterDepositsWithdrawals.styles"

const RegisteredDepositsWithdrawals: FC = () => {
  const { ref, inView } = useInView()

  const { accountID: id } = useParams() as { accountID: string }
  const transactions = useGetTransactions(id)

  const netDepositAmount = useMemo(
    () =>
      transactions.isSuccess
        ? transactions.data.pages[0].total_deposits +
          transactions.data.pages[0].total_withdrawals
        : 0,
    [transactions.data?.pages, transactions.isSuccess]
  )

  useEffect(() => {
    if (inView) {
      transactions.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  if (transactions.isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <>
      {transactions.isError && (
        <FormattedMessage
          id="app.mypages.error.investment.getTransactions"
          defaultMessage="Kan inte få transaktioner. Försök igen"
        />
      )}
      {transactions.isSuccess && (
        <S.RegisterWrap>
          <S.RegisterOveriew>
            <div>
              <S.RegisterOverviewTitle id="deposit-amount">
                <FormattedMessage
                  id="app.mypages.investments.transactions.registeredDepositsAndWithdrawls.deposits"
                  defaultMessage="Insättningar"
                />
              </S.RegisterOverviewTitle>
              <S.RegisterOverviewItem aria-labelledby="deposit-amount">
                {formatNumber(transactions.data.pages[0].total_deposits, {
                  style: "currency",
                })}
              </S.RegisterOverviewItem>
            </div>
            <div>
              <S.RegisterOverviewTitle id="withdrawal-amount">
                <FormattedMessage
                  id="app.mypages.investments.transactions.registeredDepositsAndWithdrawls.withdrawls"
                  defaultMessage="Uttag"
                />
              </S.RegisterOverviewTitle>
              <S.RegisterOverviewItem aria-labelledby="withdrawal-amount">
                {formatNumber(transactions.data.pages[0].total_withdrawals, {
                  style: "currency",
                })}
              </S.RegisterOverviewItem>
            </div>
            <div>
              <S.RegisterOverviewTitle id="net-deposit">
                <FormattedMessage
                  id="app.mypages.investments.transactions.registeredDepositsAndWithdrawls.balance"
                  defaultMessage="Nettoinsättningar"
                />
              </S.RegisterOverviewTitle>
              <S.RegisterOverviewItem aria-labelledby="net-deposit">
                {formatNumber(netDepositAmount, {
                  style: "currency",
                })}
              </S.RegisterOverviewItem>
            </div>
          </S.RegisterOveriew>
          <TransactionsTable transactions={transactions} />

          <span ref={ref} />
        </S.RegisterWrap>
      )}
    </>
  )
}

export default RegisteredDepositsWithdrawals
