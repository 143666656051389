import { FC } from "react"
import { useParams } from "react-router-dom"

import { useGetInvestmentServiceSummary } from "Hooks/API/useInvestmentServices"
import Loading from "UI/Loading"
import PieBarGeoSquare from "./PieBarGeoSquare"
import KeyFigures from "./KeyFigures"
import InvestmentBalanceOverview from "./BalanceOverview"
import * as S from "./Home.styles"

const InvestmentsHome: FC = () => {
  const { accountID } = useParams() as { accountID: string }

  const investmentSummary = useGetInvestmentServiceSummary(accountID)

  if (!investmentSummary.data || investmentSummary.isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.InvestmentsHomeWrap>
      <InvestmentBalanceOverview
        data={investmentSummary.data}
        isError={investmentSummary.isError}
        accountID={accountID}
      />

      <S.InvestmentOverview>
        <S.InvestmentChartBox>
          <PieBarGeoSquare accountID={accountID} />
        </S.InvestmentChartBox>

        <S.InvestmentChartBox>
          <KeyFigures accountID={accountID} />
        </S.InvestmentChartBox>
      </S.InvestmentOverview>
    </S.InvestmentsHomeWrap>
  )
}

export default InvestmentsHome
