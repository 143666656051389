import { useState, useRef, useEffect } from "react"
import { SliderProps } from "./Slider.types"
import { StyledSlider } from "./Slider.styles"

const Slider = ({
  onChange,
  type,
  name,
  step,
  min,
  max,
  defaultValue,
  ...rest
}: SliderProps) => {
  const [value, setValue] = useState(defaultValue)
  const sliderRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
    if (onChange) {
      onChange(e)
    }
    updateBackground()
  }

  const updateBackground = () => {
    if (sliderRef.current) {
      const inputElement = sliderRef.current
      const value = Number(inputElement.value)
      const min = Number(inputElement.min)
      const max = Number(inputElement.max)
      const width = ((value - min) / (max - min)) * 100
      inputElement.style.backgroundSize = `${width}% 100%`
    }
  }

  useEffect(() => {
    updateBackground()
  }, [value])

  return (
    <StyledSlider
      {...rest}
      ref={sliderRef}
      value={value || rest.value}
      onChange={handleChange}
      type={type}
      name={name}
      step={step}
      id={name}
      min={min}
      max={max}
    />
  )
}

export default Slider
