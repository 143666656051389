import styled from "styled-components"

export const FilterWrap = styled.div`
  display: flex;
  max-width: 450px;
  width: 100%;
  margin: 1em 0em;
  gap: 1em;
`
export const SellingFiltersWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const PriorityLabel = styled.label`
  display: flex;
  align-items: center;
`

export const SellHoldingsWrap = styled.div`
  padding-top: 1em;
`

export const SellHoldingsDesc = styled.p`
  margin-bottom: 2em;
`
