import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const InvestmentPortfolioWrap = styled.div``

export const InvestmentPortfolioHeading = styled.h4``

export const InvestmentPortfolioUList = styled.ul`
  margin-bottom: 1em;
`
export const InvestmentPortfolioOList = styled.ol`
  li {
    font-weight: 600;
  }
  p {
    margin-top: 0.5em;
  }
`

export const InvestmentPortfolioItem = styled.li`
  list-style-position: inside;
`
export const InvestmentPortfolioMarkdown = styled.em`
  color: var(--dark-gray);
  font-size: ${font.size.sm};
`
export const InvestmentPortfolioParagraph = styled.p``
