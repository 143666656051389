import TinkCallback from "Components/TinkCallback"
import GenericError from "UI/GenericError/GenericError"
import { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import * as S from "../LoanApplication.styles"

type TinkPropTypes = {
  showBackButton?: boolean
  legalEntityNumber: string
  onSuccess: (eventData: Record<string, unknown>) => void
  closeTink: () => void
}

const Tink = ({
  showBackButton,
  legalEntityNumber,
  onSuccess,
  closeTink,
}: TinkPropTypes) => {
  const [isError, setIsError] = useState(false)
  const handleTinkEvent = useCallback(
    (type: string, eventData: string | Record<string, unknown>) => {
      if (
        type === "error" ||
        (typeof eventData === "object" && eventData?.element === "CLOSE_BUTTON")
      ) {
        setIsError(true)
      }

      if (typeof eventData === "object" && type === "reports") {
        onSuccess(eventData)
      }
    },
    []
  )

  const state = {
    redirect_uri: window.location.pathname,
  }

  if (isError) {
    return (
      <GenericError
        idMessages={{
          header: "app.mypages.loans.application.error.header",
          body: "app.mypages.loans.application.tink.error.body",
          button: "app.mypages.loans.application.tink.error.button",
        }}
        onClick={closeTink}
      />
    )
  }

  return (
    <S.TinkContainer>
      <S.Description>
        <FormattedMessage
          id="app.mypages.loans.application.tink.description"
          defaultMessage="Vi tar hjälp av vår partner Tink för att du snabbt och enkelt ska kunna koppla det konto du vill använda som grund för din kreditbedömning. Vi rekommenderar dig att använda ditt lönekonto."
        />
      </S.Description>
      <TinkCallback
        type="bundledFlow"
        legalEntityNumber={legalEntityNumber}
        handleTinkEvent={handleTinkEvent}
        state={state}
        showBackButton={showBackButton}
      />
    </S.TinkContainer>
  )
}

export default Tink
