import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const ExtendedInfoContainer = styled.div`
  background-color: var(--white);
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 1em;
  width: 100%;
`

export const InfoContainer = styled.div`
  padding: 1em;
  border-radius: 16px;
  border: 1px solid #ececec;

  h4 {
    margin: 0 0 1em;
  }

  @media screen and (min-width: ${mediaQueries.medium}) {
    padding: 35px 25px;
  }
`

export const InfoList = styled.ul`
  display: grid;
  row-gap: 1em;
`

export const InfoListItem = styled.li`
  display: flex;
  justify-content: space-between;

  > :last-of-type {
    text-align: end;
    font-weight: 500;
  }
`
