import { useMemo } from "react"
import * as d3 from "d3"
import { get } from "lodash"
import {
  DataType,
  FeatureType,
  UseChoroplethOptions,
  FeatureWithData,
  BoundFeaturesType,
} from "./CustomChoropleth.types"

export const useChoropleth = ({
  features,
  data,
  match,
  label,
  value,
  valueFormat,
  colors,
  unknownColor,
  domain,
}: UseChoroplethOptions) => {
  const findMatchingData = useMemo(
    () => (feature: FeatureType, matchingData: DataType) => {
      const featureKey = get(feature, match)
      const dataKey = get(matchingData, match)

      return featureKey && featureKey === dataKey
    },
    [match]
  )

  const valueFormatter = useMemo(() => {
    if (valueFormat === undefined) return (d: number) => d
    return d3.format(valueFormat)
  }, [valueFormat])

  const colorScale = useMemo(
    () =>
      d3
        .scaleQuantize()
        .range(colors as number[])
        .domain(domain),
    [colors, domain]
  )

  const getFillColor = useMemo(
    () => (feature: FeatureWithData | FeatureType) => {
      if ("value" in feature && feature.value)
        return colorScale(feature.value).toString()

      return unknownColor
    },
    [colorScale, unknownColor]
  )

  const boundFeatures = useMemo<BoundFeaturesType[] | FeatureType[]>(
    () =>
      features.map((feature) => {
        const filteredData = data.find((dataItem) =>
          findMatchingData(feature, dataItem)
        )
        const dataValue = get(filteredData, value) as number

        if (filteredData) {
          const featureWithData = {
            ...feature,
            data: filteredData,
            value: dataValue,
            formattedValue: valueFormatter(dataValue),
          }
          const featureWithFullData = {
            ...featureWithData,
            color: getFillColor(featureWithData),
            label: get(featureWithData, label),
          }

          return featureWithFullData
        }

        return feature
      }),
    [
      features,
      data,
      value,
      findMatchingData,
      valueFormatter,
      getFillColor,
      label,
    ]
  )

  const legendData = useMemo(() => {
    const colorsRange = colorScale.range()

    const items = colorsRange.map((colorRange, index) => {
      const [start, end] = colorScale.invertExtent(colorRange)

      return {
        id: colorRange,
        index,
        extent: [start, end],
        label: `${valueFormatter(start)}${" - "}${valueFormatter(end)}`,
        value: colorScale(start),
        color: colorRange,
      }
    })

    return items
  }, [colorScale, valueFormatter])

  return {
    getFillColor,
    boundFeatures,
    legendData,
  }
}
