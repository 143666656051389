import { useEffect, useRef, useState } from "react"

export const useResizeObserver = () => {
  const [content, setContent] = useState<DOMRectReadOnly>({} as DOMRectReadOnly)
  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    let observer: ResizeObserver
    if (typeof window !== "undefined") {
      observer = new ResizeObserver(([entry]) => setContent(entry.contentRect))

      if (parentRef.current && observer !== null) {
        observer.observe(parentRef.current)
      }
    }

    return () => {
      if (observer !== null) observer.disconnect()
    }
  }, [])

  return { parentRef, content }
}
