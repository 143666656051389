import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const InvestmentsWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  &:first-of-type {
    margin-top: 1em;
  }
  &:last-of-type {
    margin-bottom: 0em;
  }
  & > div {
    margin-right: 10px;
  }
`

export const ErrorMessage = styled.p`
  padding: 10px 20px;
  text-align: center;
  background: rgba(var(--light-yellow-rgb), 0.5);
  border-radius: 15px;
`

export const SubmitButtonContainer = styled.div`
  width: fit-content;
  margin: 1rem 0 2rem 0;
  button {
    padding: 15px 20px;
    font-size: ${font.size.base};
  }
`
