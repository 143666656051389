import { FC } from "react"
import { FormattedMessage } from "react-intl"
import InternetBank from "./InternetBank"
import Swish from "./Swish"
import SwishQRCode from "./SwishQRCode"
import Trustly from "./Trustly"
import { InternetBankProps, TrustlyProps } from "./DepositOptions.types"

type PaymentMethodType = {
  [key: number]: FC<InternetBankProps> | FC<TrustlyProps> | null
}

export const PaymentMethod: PaymentMethodType = {
  0: null,
  1: InternetBank,
  2: Swish,
  3: SwishQRCode,
  4: Trustly,
}

export const paymentMethodNames = [
  {
    id: 1,
    name: (
      <FormattedMessage
        id="app.depositAccordion.internetBanking.header"
        defaultMessage="Banköverföring"
      />
    ),
    description: (
      <FormattedMessage
        id="app.depositAccordion.internetBanking.description"
        defaultMessage="Gör en insättning med en bankgiro överföring."
      />
    ),
  },
  {
    id: 2,
    name: (
      <FormattedMessage
        id="app.depositAccordion.swish.title"
        defaultMessage="Swish"
      />
    ),
    description: (
      <FormattedMessage
        id="app.depositAccordion.swish.description"
        defaultMessage="Gör snabbt och enkelt insättningar med Swish."
      />
    ),
  },
  {
    id: 3,
    name: (
      <FormattedMessage
        id="app.depositAccordion.swish.qrCode.title"
        defaultMessage="Swish QR Kod"
      />
    ),
    description: (
      <FormattedMessage
        id="app.depositAccordion.swish.qrCode.description"
        defaultMessage="Skanna QR-koden och gör en insättning."
      />
    ),
  },
  // {
  //   id: 4,
  //   name: (
  //     <FormattedMessage
  //       id="app.depositAccordion.trustly.title"
  //       defaultMessage="Trustly"
  //     />
  //   ),
  //   description: (
  //     <FormattedMessage
  //       id="app.depositAccordion.trustly.description"
  //       defaultMessage="Sätt in pengar direkt via din internetbank med Trustly."
  //     />
  //   ),
  // },
]
