import { FC, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { AnimatePresence } from "framer-motion"
import { SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  PROFILE_CLASS,
  PROFILE_TERM,
  PROFILE_PREFERANCE,
} from "Constants/dataStrings"
import {
  useGetInvestmentServiceSettings,
  useUpdateReinvestmentPreferences,
  useUpdateInvestmentProfile,
  useInvestmentServiceByIDQuery,
} from "Hooks/API/useInvestmentServices"
import IconFactory from "UI/IconFactory"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import Loading from "UI/Loading"
import Modal from "UI/Modal"
import { SelectField } from "Components/form/SelectField"
import RiskProfilePortfolioModal from "./RiskProfilePortfolioModal"
import InvestmentPortfolioModal from "./InvestmentPortfolioModal"
import {
  useClassGroupOptions,
  termGroupOptions,
  useReinvestmentGroupOptions,
} from "./options"
import {
  InvestmentProfileChoicesPropTypes,
  InvestmetChoisesFormValues,
} from "./InvestmentProfileChoices.types"
import { investmentsChoisesSchema } from "./investmentChoisesValidation"
import * as S from "./InvestmentProfileChoices.styles"

const InvestmentProfileChoices: FC<InvestmentProfileChoicesPropTypes> = ({
  id,
  handleOnboarding,
}) => {
  const intl = useIntl()
  const [riskProfilePortfolioModal, setRiskProfilePortfolioModal] =
    useState(false)
  const [investmentPortfolioModal, setinvestmentPortfolioModal] =
    useState(false)
  const { errorModal, successModal } = useFeedbackModal()

  const reinvestmentGroupOptions = useReinvestmentGroupOptions()
  const classGroupOptions = useClassGroupOptions()

  const serviceSettings = useGetInvestmentServiceSettings(id)
  const investmentService = useInvestmentServiceByIDQuery(id)

  const updateReinvestmentPreferences =
    useUpdateReinvestmentPreferences(!!handleOnboarding)
  const updateInvestmentProfile = useUpdateInvestmentProfile(
    id,
    !!handleOnboarding
  )

  const isDisabled = useMemo(
    () =>
      updateReinvestmentPreferences.isLoading ||
      updateInvestmentProfile.isLoading,
    [updateInvestmentProfile.isLoading, updateReinvestmentPreferences.isLoading]
  )

  const buttonText = useMemo(
    () =>
      intl.formatMessage(
        handleOnboarding
          ? {
              id: "app.mypages.savings.onboarding.investmentProfile.button",
              defaultMessage: "Spara och gå vidare",
            }
          : {
              id: "app.mypages.investments.home.editButtonName.save",
              defaultMessage: "Spara",
            }
      ),
    [handleOnboarding, intl]
  )

  const form = useForm<InvestmetChoisesFormValues>({
    resolver: yupResolver(investmentsChoisesSchema),
    values: {
      investmentProfileClassGroup:
        investmentService?.data?.investment_profile?.class_group ||
        PROFILE_CLASS.PROGRESSIVE,
      investmentProfileTermGroup:
        investmentService?.data?.investment_profile?.term_group ||
        PROFILE_TERM.MEDIUM,
      reinvestmentPreferences:
        serviceSettings?.data?.reinvestment_preference ||
        PROFILE_PREFERANCE.REINVEST_ALL,
    },
  })

  const onSubmit: SubmitHandler<InvestmetChoisesFormValues> = (values) => {
    Promise.all([
      updateReinvestmentPreferences.mutateAsync({
        uid: id,
        payload: {
          reinvestment_preference: values.reinvestmentPreferences,
        },
      }),
      updateInvestmentProfile.mutateAsync({
        uid: id,
        payload: {
          class_group: values.investmentProfileClassGroup,
          term_group: values.investmentProfileTermGroup,
        },
      }),
    ])
      .then(() => {
        if (handleOnboarding) {
          handleOnboarding()
        }
        successModal(
          intl.formatMessage({
            id: "app.mypages.savings.onboarding.investmentProfile.successModal.header",
            defaultMessage: "Dina ändringar är sparade!",
          })
        )
      })
      .catch(() => {
        errorModal(
          intl.formatMessage({
            id: "app.mypages.error.investment.useUpdateReinvestmentPreferences",
            defaultMessage:
              "Det går inte att uppdatera inställningar för investeringstjänsten",
          })
        )
      })
  }

  const values = form.watch()

  const selectedReinvestmentDescription =
    reinvestmentGroupOptions.find(
      ({ value }) => value === values.reinvestmentPreferences
    )?.description || ""

  if (serviceSettings.isError || investmentService.isError) {
    return (
      <S.GrayInfoBox>
        {intl.formatMessage({
          id: "app.mypages.error.investment.getInvestmentServiceByID",
          defaultMessage:
            "Kan inte få den begärda investeringstjänsten. Försök igen",
        })}
      </S.GrayInfoBox>
    )
  }

  if (investmentService.isLoading || serviceSettings.isLoading) {
    return (
      <S.GrayInfoBox>
        <Loading isCentered />
      </S.GrayInfoBox>
    )
  }

  return (
    <>
      <AnimatePresence>
        {riskProfilePortfolioModal && (
          <Modal
            title={intl.formatMessage({
              id: "app.mypages.investments.investmentChoices.riskProfileModal.title",
              defaultMessage: "Riskprofil",
            })}
            onClick={() => setRiskProfilePortfolioModal(false)}
            body={<RiskProfilePortfolioModal />}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {investmentPortfolioModal && (
          <Modal
            title={intl.formatMessage({
              id: "app.mypages.investments.investmentChoices.investmentExplainationModal.title",
              defaultMessage: "Investerings horisont",
            })}
            onClick={() => setinvestmentPortfolioModal(false)}
            body={<InvestmentPortfolioModal />}
          />
        )}
      </AnimatePresence>

      <S.GrayBoxForm onSubmit={form.handleSubmit(onSubmit)}>
        <S.SectionContent>
          <S.BoxInfo>
            <S.SectionTitle id="profileClass">
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.classGroup.header",
                defaultMessage: "Riskprofil",
              })}
              {values.investmentProfileClassGroup ===
                PROFILE_CLASS.PROGRESSIVE && (
                <S.HappyIconWrap data-testid="happy-icon">
                  <IconFactory name="happyGreen" />
                </S.HappyIconWrap>
              )}
            </S.SectionTitle>
            <p>
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.classGroup.paragraph",
                defaultMessage:
                  "Här kan du välja vilken typ av riskprofil du vill ha för din investering.",
              })}
            </p>
            <S.ModalLink
              type="button"
              aria-label="About risk profile"
              onClick={() => setRiskProfilePortfolioModal(true)}
            >
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.link",
                defaultMessage: "Läs mer",
              })}
            </S.ModalLink>
          </S.BoxInfo>
          {investmentService.isSuccess && (
            <S.SelectWrap>
              <SelectField
                control={form.control}
                name="investmentProfileClassGroup"
                options={classGroupOptions}
                type="rounded"
                aria-labelledby="profileClass"
              />
            </S.SelectWrap>
          )}
        </S.SectionContent>
        <hr />
        <S.SectionContent>
          <S.BoxInfo>
            <S.SectionTitle id="investmentProfile">
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.termGroup.header",
                defaultMessage: "Investeringshorisont",
              })}

              {values.investmentProfileTermGroup === PROFILE_TERM.MEDIUM && (
                <S.HappyIconWrap data-testid="happy-icon">
                  <IconFactory name="happyGreen" />
                </S.HappyIconWrap>
              )}
            </S.SectionTitle>
            <p>
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.termGroup.paragraph",
                defaultMessage: "Välj investeringshorisont",
              })}
            </p>
            <S.ModalLink
              type="button"
              onClick={() => setinvestmentPortfolioModal(true)}
              aria-label="About investment horizon"
            >
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.link",
                defaultMessage: "Läs mer",
              })}
            </S.ModalLink>
          </S.BoxInfo>
          {investmentService.isSuccess && (
            <S.SelectWrap>
              <SelectField
                control={form.control}
                options={termGroupOptions}
                type="rounded"
                name="investmentProfileTermGroup"
                aria-labelledby="investmentProfile"
              />
            </S.SelectWrap>
          )}
        </S.SectionContent>
        <hr />
        <S.SectionContent>
          <S.BoxInfo>
            <S.SectionTitle id="reinvestment">
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.header",
                defaultMessage: "Återinvesteringspreferens",
              })}
              {values.reinvestmentPreferences ===
                (PROFILE_PREFERANCE.REINVEST_ALL ||
                  PROFILE_PREFERANCE.REINVEST_AMORTIZATIONS) && (
                <S.HappyIconWrap data-testid="happy-icon">
                  <IconFactory name="happyGreen" />
                </S.HappyIconWrap>
              )}
            </S.SectionTitle>
            <p>
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.paragraph",
                defaultMessage:
                  "Varje månad får du som långivare återbetalningar bestående av amortering och ränta från låntagarna. Du kan välja mellan tre olika alternativ för hur återbetalningar ska hanteras och du kan när som helst ändra dina inställningar. Vid ett val med månadsvis utbetalning är lägsta utbetalningsbelopp 100 kr.",
              })}
            </p>
            <p>
              {intl.formatMessage({
                id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.paragraph2",
                defaultMessage:
                  "Räntor och amorteringar återinvesteras löpande i nya lån",
              })}
            </p>
          </S.BoxInfo>
          {serviceSettings.isSuccess && (
            <S.SelectWrap>
              <SelectField
                control={form.control}
                options={reinvestmentGroupOptions}
                type="rounded"
                name="reinvestmentPreferences"
                aria-labelledby="reinvestment"
              />
              <span>{selectedReinvestmentDescription}</span>
            </S.SelectWrap>
          )}
        </S.SectionContent>
        <S.ButtonWrap>
          <S.ModalBody>
            <Button type="submit" disabled={isDisabled} isLoading={isDisabled}>
              {buttonText}
            </Button>
          </S.ModalBody>
        </S.ButtonWrap>
      </S.GrayBoxForm>
    </>
  )
}

export default InvestmentProfileChoices
