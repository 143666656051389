import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import {
  useGetInvestmentCurrentBalance,
  useGetInvestmentServiceSummary,
  usePostInvestmentWithdrawalAmount,
} from "Hooks/API/useInvestmentServices"
import { useGetCustomer } from "Hooks/API/useCustomer"
import Loading from "UI/Loading"
import { Urls } from "Constants/urls"
import WithdrawalsForm from "Components/WithdrawalsForm/WithdrawalsForm"
import { WithdrawalsFormValues } from "Components/WithdrawalsForm/WithdrawalsForm.types"
import * as S from "./WithdrawInvestment.styles"

const WithdrawInvestment = () => {
  const { accountID } = useParams() as { accountID: string }

  const postInvestmentWithdrawalMutation =
    usePostInvestmentWithdrawalAmount(accountID)

  const customer = useGetCustomer()
  const summary = useGetInvestmentServiceSummary(accountID)
  const currentBalance = useGetInvestmentCurrentBalance(accountID)

  const onSubmit = (values: WithdrawalsFormValues) => {
    return postInvestmentWithdrawalMutation.mutateAsync({
      amount: +values.amount,
      uid: accountID,
    })
  }

  if (customer.isLoading || summary.isLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (customer.isError || summary.isError) {
    return (
      <S.WithdrawContainer>
        <FormattedMessage
          id="app.mypages.error.investment.getInvestmentServiceByID"
          defaultMessage="Kan inte få den begärda investeringstjänsten. Försök igen"
        />
      </S.WithdrawContainer>
    )
  }

  return (
    <S.WithdrawContainer>
      {customer.data && currentBalance.data && (
        <WithdrawalsForm
          onSubmit={onSubmit}
          isLoading={postInvestmentWithdrawalMutation.isLoading}
          minAmount={50}
          type="investments"
          deposit={{
            currentBalance: currentBalance.data.current_balance,
            bankClearingNumber: customer.data.bank_clearing_number,
            bankAccountNumber: customer.data.bank_account_number,
            bankName: customer.data.bank_name,
          }}
          link={`${Urls.Account}/${Urls.AccountInfo}`}
        />
      )}
    </S.WithdrawContainer>
  )
}

export default WithdrawInvestment
