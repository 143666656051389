import styled, { css } from "styled-components"
import { flex } from "config/mixins"
import { StyledGradeProps } from "./LoanGrade.types"

export const Grade = styled.div<StyledGradeProps>`
  ${flex.center}
  border-radius: 50%;
  padding: 1em;
  text-align: center;
  font-weight: 700;
  color: var(--white);
  ${(p) => css`
    height: ${p.size};
    min-width: ${p.size};
    width: ${p.size};
    background: ${p.grade};
  `}
`
