import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const InvestmentChoicesWrap = styled.div`
  padding-top: 3em;
  p {
    margin: 0em 0 2em 0;
    max-width: 100%;
    line-height: 24px;
    font-size: ${font.size.base};
    @media screen and (max-width: ${mediaQueries.medium}) {
      line-height: 24px;
    }
  }
`
