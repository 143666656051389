import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import {
  useGetSecondaryAssets,
  useUnListAssets,
} from "Hooks/API/useInvestmentServices"
import Checkbox from "UI/Checkbox"
import { useFeedbackModal } from "Context/modal-context"
import { ISecondaryAsset } from "Interfaces/investmentServicesInterfaces"
import Button from "UI/Button"
import Loading from "UI/Loading"
import MobileRowForTable from "./MobileRowForTable"
import { determineStatus } from "./helpers"
import * as S from "../AssetsTable/AssetsTable.styles"

const AssetsForSaleTable = (): JSX.Element | null => {
  const [checkedToUnlistAssets, setCheckedToUnlistAssets] = useState<number[]>(
    []
  )
  const { accountID } = useParams() as { accountID: string }
  const intl = useIntl()
  const { successModal, errorModal } = useFeedbackModal()
  const data = useGetSecondaryAssets(accountID)
  const unlistAssets = useUnListAssets()
  const assets = data?.data

  const updateCheckedToUnlistAssets = (asset: ISecondaryAsset) => {
    const chekedAsset = checkedToUnlistAssets.find(
      (item) => item === asset.loan_number
    )
    if (chekedAsset) {
      const newArr = checkedToUnlistAssets.filter(
        (item) => item !== asset.loan_number
      )
      setCheckedToUnlistAssets(newArr)
    } else {
      setCheckedToUnlistAssets([...checkedToUnlistAssets, asset.loan_number])
    }
  }

  const toggleAllAssets = () => {
    if (assets && checkedToUnlistAssets.length < assets?.length) {
      const allLoanNumbers = assets?.map((asset) => asset.loan_number)
      setCheckedToUnlistAssets(allLoanNumbers)
    } else {
      setCheckedToUnlistAssets([])
    }
  }

  const unlistSelectedAssets = () => {
    const payload = {
      loan_numbers: checkedToUnlistAssets,
    }
    unlistAssets.mutate({ uid: accountID, payload })
  }

  useEffect(() => {
    if (unlistAssets.isSuccess) {
      setCheckedToUnlistAssets([])
      successModal(
        <FormattedMessage
          id="app.mypages.investments.forSaleAssets.unlistStatus.successMessage"
          defaultMessage="Dina innehav tas bort från försäljning. De kommer fortfarande att vara synliga under Utlagda till försäljning i cirka 3 minuter"
        />
      )
    }

    if (unlistAssets.isError) {
      errorModal(
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.sellStatus.failMessage"
          defaultMessage="Något gick fel. Prova igen"
        />
      )
    }
  }, [errorModal, successModal, unlistAssets.isError, unlistAssets.isSuccess])

  if (data.isLoading) {
    return (
      <S.AssetsTableContainer className="loading">
        <Loading isCentered withMessage />
      </S.AssetsTableContainer>
    )
  }
  if (data.isError) {
    return (
      <FormattedMessage
        id="app.mypages.error.investment.getListInvestmentAssets"
        defaultMessage="Kan inte få en förteckning över dina investeringstillgångar. Försök igen"
      />
    )
  }

  if (!data.isSuccess) return null

  if (!assets || !assets?.length) {
    return (
      <S.AssetsTableContainer isEmpty>
        <FormattedMessage
          id="app.mypages.investments.assets.assetsTable.noData"
          defaultMessage="Du har inga innehav att visa just nu."
        />
      </S.AssetsTableContainer>
    )
  }

  return (
    <>
      <S.AssetsTableContainer>
        <S.AssetsTableHeadWrap withCheckbox isSelling>
          <S.AssetsTableHead />
          <S.AssetsTableHead>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.loanNumber"
              defaultMessage="Lånenummer"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.status"
              defaultMessage="Status"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.riskClass"
              defaultMessage="Riskklass"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead hideMobile>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.term"
              defaultMessage="Löptid"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead hideMobile>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.loanInterestRate"
              defaultMessage="Utlåningsränta"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead hideMobile>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.amountOfLoanShares"
              defaultMessage="Antal låneandelar"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead hideMobile>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.bookValue"
              defaultMessage="Bokfört värde"
            />
          </S.AssetsTableHead>
          <S.AssetsTableHead hideMobile>
            <FormattedMessage
              id="app.mypages.investments.assets.assetsTable.tableHeaders.accruedInterest"
              defaultMessage="Upplupen ränta"
            />
          </S.AssetsTableHead>
        </S.AssetsTableHeadWrap>
        {assets?.map((asset) => (
          <MobileRowForTable
            key={asset.loan_number}
            asset={asset}
            checkedToUnlistAssets={checkedToUnlistAssets}
            updateCheckedToUnlistAssets={updateCheckedToUnlistAssets}
          />
        ))}
        {assets?.map((asset) => {
          const status = determineStatus(asset.loan_performance_status)
          const interestRate = intl.formatNumber(asset.loan_interest_rate, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: "percent",
          })
          const bookValue = intl.formatNumber(asset.nominal_value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          const accruedInterest = intl.formatNumber(asset.accrued_interest, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          const isChecked = !!checkedToUnlistAssets.find(
            (item) => item === asset.loan_number
          )
          return (
            <S.AssetsTableItemWrap
              key={`${asset.loan_number}-desktop`}
              withCheckbox
            >
              <S.AssetsTableItem>
                <label htmlFor={asset.loan_number.toString()}>
                  <Checkbox
                    size="large"
                    name={asset.loan_number.toString()}
                    value={asset.loan_number.toString()}
                    color="white"
                    checked={isChecked}
                    onChange={() => updateCheckedToUnlistAssets(asset)}
                    aria-label={`Unlist asset #${asset.loan_number}`}
                  />
                </label>
              </S.AssetsTableItem>
              <S.AssetsTableItem data-testid="loan-number">
                {asset.loan_number}
              </S.AssetsTableItem>
              <S.AssetsTableItem>{status}</S.AssetsTableItem>
              <S.AssetsTableItem>
                <S.Grade grade={asset.loan_grade}>{asset.loan_grade}</S.Grade>
              </S.AssetsTableItem>
              <S.AssetsTableItem>
                {asset.loan_remaining_term_in_days}
              </S.AssetsTableItem>
              <S.AssetsTableItem>{interestRate}</S.AssetsTableItem>
              <S.AssetsTableItem>
                {asset.number_of_loan_assets}
              </S.AssetsTableItem>
              <S.AssetsTableItem>{bookValue}</S.AssetsTableItem>
              <S.AssetsTableItem>{accruedInterest}</S.AssetsTableItem>
            </S.AssetsTableItemWrap>
          )
        })}
      </S.AssetsTableContainer>
      <S.ButtonWrap>
        <Button variant="inverse" onClick={toggleAllAssets}>
          {checkedToUnlistAssets.length < assets?.length ? (
            <FormattedMessage
              id="app.mypages.investments.forSaleAssets.markAllButton"
              defaultMessage="Markera alla innehav"
            />
          ) : (
            <FormattedMessage
              id="app.mypages.investments.forSaleAssets.unmarkAllButton"
              defaultMessage="Avmarkera alla innehav"
            />
          )}
        </Button>
        <Button
          disabled={checkedToUnlistAssets.length === 0}
          onClick={unlistSelectedAssets}
        >
          <FormattedMessage
            id="app.mypages.investments.forSaleAssets.unlistSelectedButton"
            defaultMessage="Avlista markerade innehav"
          />
        </Button>
      </S.ButtonWrap>
    </>
  )
}

export default AssetsForSaleTable
