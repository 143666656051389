import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

export const OptionsContainer = styled.div`
  ${flex.columnAlignCenter}
  button {
    margin-top: 20px;
  }
  img {
    margin-bottom: 1em;
    height: 300px;
    width: 300px;
  }
`

export const OptionsContent = styled.div`
  margin: 0;

  ol li {
    margin-bottom: 10px;
    line-height: 1.5em;
    list-style-position: inside;
  }
`

export const InputWrap = styled.div`
  width: 100%;
  span {
    font-size: ${font.size.sm};
    color: var(--dark-gray);
  }
`
export const LoadingContainer = styled.div`
  ${flex.justifyCenter}
  padding-top: 5em;
`

export const ModalLink = styled.button`
  text-decoration: underline;
  margin: 3em 0 1.5em 0;
  font-size: ${font.size.sm};
  padding: 0;
  align-self: flex-start;
`
export const DepositContent = styled.div`
  ${flex.column}
  width: 70%;
  gap: 1em;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    width: 100%;
  }
`
export const DepositMethod = styled.div`
  ${flex.alignCenterBetween}
  width: 100%;
  padding: 1.5em;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  cursor: pointer;
  gap: 1em;
  svg {
    path {
      stroke: var(--black);
    }
  }
  &:hover {
    box-shadow: 0 0 5px var(--light-gray);
  }
`
export const DepositMethodNameWrap = styled.div``

export const DepositMethodName = styled.h4`
  margin: 0 0 5px;
  font-weight: 500;
  font-size: ${font.size.lg};
`
export const DepositMethodDecription = styled.p`
  margin: 0;
  font-size: ${font.size.base};
  color: var(--dark-gray);
  line-height: 22px;
`
export const ErrorContainer = styled.div`
  ${flex.column}
`
export const DepositOptionHeading = styled.h4`
  margin-top: 0;
  font-size: ${font.size.lg};
`
export const DepositOptionDescription = styled.p``
