import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import InvestmentProfileChoices from "Components/InvestmentProfileChoices"
import * as S from "./InvestmentChoices.styles"

const InvestmentChoices = () => {
  const { accountID } = useParams() as { accountID: string }
  return (
    <S.InvestmentChoicesWrap>
      <p>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.introParagraph"
          defaultMessage="Investeringsprofilen styr vilka typer av lån du investerar i. Du kan välja
        att anpassa investeringsprofilen utifrån dina personliga önskemål."
        />
      </p>
      <InvestmentProfileChoices id={accountID} />
    </S.InvestmentChoicesWrap>
  )
}

export default InvestmentChoices
