import { FC, useCallback, useState, useMemo, memo } from "react"
import { calculateLegendItemLayout } from "../calculations"
import { LegendItemProps, StyleType } from "../CustomChoropleth.types"

const LegendItem: FC<LegendItemProps> = ({
  x,
  y,
  width,
  height,
  data,
  background = "transparent",
  opacity = 1,
  effects,
}) => {
  const [style, setStyle] = useState<StyleType>({})
  const symbolSize = 14
  const symbolSpacing = 8

  const { symbolX, symbolY, labelX, labelY, labelAnchor, labelAlignment } =
    useMemo(
      () =>
        calculateLegendItemLayout(
          style.symbolSize ?? symbolSize,
          symbolSpacing,
          width,
          height
        ),
      [height, style.symbolSize, width]
    )

  const handleMouseEnter = useCallback(() => {
    if (effects && effects.on === "hover") {
      setStyle(effects.style)
    }
  }, [effects])

  const handleMouseLeave = useCallback(() => {
    if (effects) {
      setStyle({})
    }
  }, [effects])

  return (
    <g
      transform={`translate(${x},${y})`}
      style={{
        opacity: style.itemOpacity ?? opacity,
      }}
    >
      <rect
        width={width}
        height={height}
        fill={style.itemBackground ?? background}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <rect
        width="18px"
        height="18px"
        id={data.id.toString()}
        x={symbolX}
        y={symbolY}
        fill={data.color.toString() ?? "black"}
        strokeWidth="0"
        stroke="transparent"
        style={{
          pointerEvents: "none",
          opacity: 0.6,
        }}
      />
      <text
        textAnchor={labelAnchor}
        style={{
          fill: style.itemTextColor ?? "#717171",
          dominantBaseline: labelAlignment,
          pointerEvents: "none",
          userSelect: "none",
          fontSize: "14px",
        }}
        x={labelX}
        y={labelY}
      >
        {data.label}
      </text>
    </g>
  )
}

export default memo(LegendItem)
