import styled from "styled-components"
import { flex } from "config/mixins"
import { mediaQueries } from "Styles/styleGlobal"

export const WithdrawContainer = styled.div`
  margin-top: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-top: 1em;
  }
`
export const LoadingContainer = styled.div`
  ${flex.center}
  height: 70vh;
`
