export const tooltipProps = {
  wrapperStyle: { outline: "none" },
  contentStyle: {
    border: "none",
    borderRadius: "6px",
    boxShadow: "0 0 3px #E6E6E6",
    fontSize: "14px",
  },
  itemStyle: {
    color: "#000",
  },
  labelStyle: {
    fontWeight: 600,
  },
  cursor: { fill: "transparent" },
}
