import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const DepositsContentHeader = styled.h3`
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: ${font.size.xl};
`

export const SavingsDepositDescription = styled.p`
  margin: 1em 0 2em;
`
