import { useIntl } from "react-intl"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "react-query"

import {
  getDepositServices,
  getDepositServiceByID,
  getDepositServiceTransactions,
  postDepositWithdrawalAmount,
  createBankPaymentRequest,
  updateDepositServiceName,
  postDirectDebitAmount,
  stopMonthlySaving,
  createDepositAgreementSignature,
  getDepositSignatureStatus,
  getDepositServiceProfiles,
  updateDepositServicePreferences,
  saveSelectedBankAccount,
  processBankPaymentRequest,
  getDepositReviewStatus,
  updateDepositInitialAmount,
  updateBankAccountManually,
  createDepositApplication,
  cancelDepositApplicationByID,
  closeDepositAccountByID,
} from "ApiServices/depositServices"
import {
  IDepositService,
  IDepositServices,
  PrepareAgreementSignature,
} from "Interfaces/depositInterfaces"
import { UpdateDepositNameValues } from "Pages/Authenticated/Savings/Home/UpdateDepositeName/UpdateDepositName.types"
import {
  DEPOSIT_SERVICES_DATA,
  DEPOSIT_SERVICES_PROFILES,
  DEPOSIT_SERVICES_SERVICE,
  DEPOSIT_SERVICES_SIGNATURE_STATUS,
  DEPOSIT_SERVICES_TRANSACTIONS,
  DEPOSIT_SERVICES_SERVICE_REVIEW_STATUS,
  DEPOSIT_SERVICES_CREARE_SIGNATURE,
} from "Constants/queryKeys"
import {
  DepositContractSignatureProgressStatus,
  DepositContractSignatureStatus,
} from "types/enums"
import { Urls } from "Constants/urls"

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/deposit-services/paths/~1deposit-services~1%7Buid%7D~1signatures~1%7Bsignature-uid%7D/get} */
export const useGetDepositSignatureStatus = ({
  enabled,
  signatureUid,
  uid,
}: {
  uid: string
  signatureUid: string
  enabled: boolean
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  return useQuery({
    queryKey: [DEPOSIT_SERVICES_SIGNATURE_STATUS, uid],
    queryFn: () => getDepositSignatureStatus(uid, signatureUid),
    refetchInterval: 2000,
    enabled,
    onSuccess: (data) => {
      if (data.status === DepositContractSignatureStatus.complete) {
        Promise.allSettled([
          queryClient.invalidateQueries([DEPOSIT_SERVICES_DATA]),
          queryClient
            .refetchQueries([DEPOSIT_SERVICES_SERVICE, uid])
            .catch(() => navigate(Urls.Home, { replace: true }))
            .then(() =>
              navigate(
                `${Urls.Savings}/${uid}/${Urls.SavingsAccountOverview}`,
                { replace: true }
              )
            ),
        ])
      }
    },
  })
}

/** api docs - {@link https://engine.test.brocc.se/docs/customer-api#tag/deposit-services/paths/~1deposit-services~1%7Buid%7D~1signatures/post} */
export const useCreateDepositAgreementSignature = () => {
  return useMutation<PrepareAgreementSignature, unknown, string>({
    mutationKey: [DEPOSIT_SERVICES_CREARE_SIGNATURE],
    mutationFn: createDepositAgreementSignature,
  })
}

export const useSignSavingsAgreement = (accountID: string) => {
  const intl = useIntl()

  const [
    isDepositSignatureStatusQueryEnabled,
    setIsDepositSignatureStatusQueryEnabled,
  ] = useState(false)
  const [isSignError, setIsSignError] = useState(false)

  const createAgreementSignatureMutation = useCreateDepositAgreementSignature()
  const depositSignatureStatusQuery = useGetDepositSignatureStatus({
    uid: accountID,
    signatureUid: createAgreementSignatureMutation.data?.uid ?? "",
    enabled:
      !!createAgreementSignatureMutation.data?.uid &&
      isDepositSignatureStatusQueryEnabled,
  })

  const startSigning = () => {
    setIsSignError(false)
    setIsDepositSignatureStatusQueryEnabled(true)
    createAgreementSignatureMutation.mutateAsync(accountID)
  }

  const cancelSigning = () => {
    setIsSignError(false)
    setIsDepositSignatureStatusQueryEnabled(false)
  }

  const reset = () => {
    setIsSignError(false)
    setIsDepositSignatureStatusQueryEnabled(false)
    depositSignatureStatusQuery.remove()
  }

  useEffect(() => {
    if (
      depositSignatureStatusQuery.data?.status ===
      DepositContractSignatureStatus.complete
    ) {
      setIsDepositSignatureStatusQueryEnabled(false)
      return
    }

    if (
      createAgreementSignatureMutation.data?.status ===
        DepositContractSignatureStatus.error ||
      createAgreementSignatureMutation.data?.progress_status ===
        DepositContractSignatureProgressStatus.alreadyInProgress ||
      depositSignatureStatusQuery.data?.status ===
        DepositContractSignatureStatus.error
    ) {
      setIsDepositSignatureStatusQueryEnabled(false)
      setIsSignError(true)
      depositSignatureStatusQuery.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    depositSignatureStatusQuery.data?.status,
    createAgreementSignatureMutation.data?.status,
    createAgreementSignatureMutation.data?.progress_status,
  ])

  const errorMessage = useMemo(() => {
    if (
      createAgreementSignatureMutation.data?.progress_status ===
        DepositContractSignatureProgressStatus.alreadyInProgress ||
      depositSignatureStatusQuery.data?.status ===
        DepositContractSignatureStatus.error
    ) {
      return intl.formatMessage({
        id: "app.mypages.savings.onBoarding.agreement.signedStatus.error",
        defaultMessage:
          "Något gick fel. Vänligen försök igen. Kontakta oss om problemet kvarstår.",
      })
    }

    return intl.formatMessage({
      id: "app.login.error.retry",
      defaultMessage: "Internt tekniskt fel. Försök igen.",
    })
  }, [
    createAgreementSignatureMutation.data?.progress_status,
    depositSignatureStatusQuery.data?.status,
    intl,
  ])

  return {
    reset,
    startSigning,
    cancelSigning,
    errorMessage,
    qrCode:
      depositSignatureStatusQuery.data?.qr_code ||
      createAgreementSignatureMutation.data?.qr_code ||
      "",
    autostartToken:
      createAgreementSignatureMutation.data?.auto_start_token || "",
    isLoading: createAgreementSignatureMutation.isLoading,
    isSigning:
      depositSignatureStatusQuery.data?.progress_status === "user_sign" ||
      depositSignatureStatusQuery.data?.progress_status === "complete",
    isError: isSignError,
  }
}

export const useGetDepositServices = (enabled = true) => {
  return useQuery({
    queryKey: [DEPOSIT_SERVICES_DATA],
    queryFn: getDepositServices,
    retry: 1,
    enabled,
    staleTime: 5 * 60 * 1000, // 5 mins
  })
}

export function useGetDepositServiceTransactions(uid: string) {
  return useInfiniteQuery({
    queryKey: [DEPOSIT_SERVICES_TRANSACTIONS, uid],
    queryFn: ({ pageParam = 0 }) =>
      getDepositServiceTransactions(uid, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.last_page === lastPage.meta.current_page) {
        return undefined
      }
      return lastPage.meta.current_page + 1 ?? undefined
    },
  })
}

export const useGetDepositServiceByID = (uid: string, enabled = true) =>
  useQuery({
    queryKey: [DEPOSIT_SERVICES_SERVICE, uid],
    queryFn: () => getDepositServiceByID(uid),
    enabled,
    retry: 1,
  })

export const usePostDepositWithdrawalAmount = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postDepositWithdrawalAmount,
    onSuccess: () => {
      queryClient.refetchQueries([DEPOSIT_SERVICES_SERVICE])
      queryClient.refetchQueries([DEPOSIT_SERVICES_TRANSACTIONS])
      queryClient.refetchQueries([DEPOSIT_SERVICES_DATA])
    },
  })
}

export const useCreateBankPaymentRequest = (uid: string) =>
  useMutation(({ amount }: { amount: number }) =>
    createBankPaymentRequest(uid, amount)
  )

export const useProcessBankPaymentRequest = (uid: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: processBankPaymentRequest,
    onSuccess: () => {
      queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, uid])
    },
  })
}

export const usePostDirectDebitAmount = (uid: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ amount }: { amount: number }) =>
      postDirectDebitAmount(uid, amount),
    onSuccess: () => {
      queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, uid])
    },
  })
}

export function useUpdateDepositServiceName(uid: string) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ depositName }: UpdateDepositNameValues) =>
      updateDepositServiceName(uid, depositName),
    onMutate: ({ depositName }: UpdateDepositNameValues) => {
      queryClient.cancelQueries([DEPOSIT_SERVICES_DATA])
      queryClient.cancelQueries([DEPOSIT_SERVICES_SERVICE, uid])
      const prevItemById = queryClient.getQueryData<IDepositService>([
        DEPOSIT_SERVICES_SERVICE,
        uid,
      ])
      queryClient.setQueryData([DEPOSIT_SERVICES_SERVICE, uid], {
        ...prevItemById,
        name: depositName,
      })

      const prevItemAll = queryClient.getQueryData<IDepositServices>(
        DEPOSIT_SERVICES_DATA
      )
      if (prevItemAll) {
        const deposits = prevItemAll.data.map((item) =>
          item.uid === uid ? { ...item, name: depositName } : item
        )
        queryClient.setQueryData(DEPOSIT_SERVICES_DATA, {
          data: deposits,
        })
      }

      return { prevItemById, prevItemAll }
    },
    onError: (_, __, context) => {
      if (context?.prevItemById) {
        queryClient.setQueryData<IDepositService>(
          [DEPOSIT_SERVICES_SERVICE, uid],
          context.prevItemById
        )
      }
      if (context?.prevItemAll) {
        queryClient.setQueryData<IDepositServices>(
          DEPOSIT_SERVICES_DATA,
          context.prevItemAll
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, uid])
      queryClient.invalidateQueries(DEPOSIT_SERVICES_DATA)
    },
  })
}

export const useStopMonthlySaving = () =>
  useMutation((uid: string) => stopMonthlySaving(uid))

export const useGetDepositServiceProfiles = (id: string) =>
  useQuery({
    queryKey: [DEPOSIT_SERVICES_PROFILES, id],
    queryFn: () => getDepositServiceProfiles(id),
  })

export const useUpdateDepositServicePreferences = (id: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateDepositServicePreferences,
    onSuccess: () =>
      queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, id]),
  })
}

export const useUpdateDepositInitialAmount = () =>
  useMutation(updateDepositInitialAmount)

export function useUpdateBankAccountManually(id: string) {
  return useMutation({
    mutationFn: (application: {
      account_number: string
      clearing_number: string
    }) => updateBankAccountManually(application, id),
  })
}

export const useSaveSelectedBankAccount = (id?: string) => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, { uid: string; data: string }>({
    mutationFn: ({ uid, data }) => saveSelectedBankAccount(uid, data),
    onSuccess: () => {
      if (id) {
        queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, id])
      }
    },
  })
}

export const useGetReviewStatus = (uid: string) =>
  useQuery({
    queryKey: [DEPOSIT_SERVICES_SERVICE_REVIEW_STATUS, uid],
    queryFn: () => getDepositReviewStatus(uid),
    retry: 1,
  })

export const useCreateDepositApplication = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createDepositApplication,
    onSuccess: () => queryClient.invalidateQueries([DEPOSIT_SERVICES_DATA]),
  })
}

export const useDepositCancelApplication = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: cancelDepositApplicationByID,
    onSuccess: () => queryClient.invalidateQueries([DEPOSIT_SERVICES_DATA]),
  })
}

export const useDepositCloseAccount = (
  uid: string,
  toDepositServiceUid?: string
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: closeDepositAccountByID,
    onSuccess: () => {
      queryClient.invalidateQueries([DEPOSIT_SERVICES_DATA])
      queryClient.invalidateQueries([DEPOSIT_SERVICES_SERVICE, uid])
      queryClient.invalidateQueries([
        DEPOSIT_SERVICES_SERVICE,
        toDepositServiceUid,
      ])
      queryClient.refetchQueries([DEPOSIT_SERVICES_TRANSACTIONS])
    },
  })
}
