import { useState, useEffect, useRef } from "react"
import { Loans } from "Pages/LoanCollection/LoansList/LoanItem/LoanItem.types"
import { isMobile } from "Utils"
import { MOCKED_CYPRESS_SESSION_UID } from "Constants/dataStrings"
import { WEB_SOCKET_URL } from "Pages/LoanCollection/Constants"
import { LoanCollectionError } from "Interfaces/iceingInterfaces"
import { useIceingScrapeBanks } from "./useIceing"

interface ScrapingWebSocket {
  isLoginIn: boolean
  bankName: string
  bankId: number
  firstName: string
  lastName: string
  ssn: string
  uuid: string
}
type CustomWindow = Window & {
  Cypress: unknown
  uuidv4: string
}

declare let window: CustomWindow

const useScrapingWebSocket = ({
  isLoginIn,
  bankName,
  bankId,
  firstName,
  lastName,
  ssn,
  uuid,
}: ScrapingWebSocket) => {
  const [autostarttoken, setAutostarttoken] = useState("")
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<LoanCollectionError>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loans, setLoans] = useState<Loans>({
    creditCards: [],
    installments: [],
    loans: [],
  })
  const ws = useRef<WebSocket>()
  const iceingScrapeBank = useIceingScrapeBanks()

  const getWsUrl = () => {
    // // only when testing in Cypress
    if (window.Cypress) {
      return `${WEB_SOCKET_URL}${MOCKED_CYPRESS_SESSION_UID}`
    }
    return `${WEB_SOCKET_URL}${uuid}`
  }

  const reset = () => {
    setIsLoggedIn(false)
    setAutostarttoken("")
    setIsError(false)
    setLoans({
      creditCards: [],
      installments: [],
      loans: [],
    })
  }

  const hasLoans = (fetchedLoans: Loans) => {
    return (
      fetchedLoans.creditCards.length > 0 ||
      fetchedLoans.installments.length > 0 ||
      fetchedLoans.loans.length > 0
    )
  }

  const hasError = (error: LoanCollectionError) => {
    return error.type !== null && error.message !== null
  }

  const closeWs = () => {
    ws.current?.close()
  }

  const getScrapedData = async () => {
    setIsLoading(true)
    const {
      ok,
      error,
      loans: fetchedLoans,
    } = await iceingScrapeBank.mutateAsync({
      bankData: {
        sessionToken: uuid,
        userBank: bankName,
        loginType: isMobile() ? "mobile" : "web",
        firstName,
        lastName,
        ssn,
      },
    })

    closeWs()

    if (!ok || hasError(error) || !hasLoans(fetchedLoans)) {
      setIsError(true)
      setError(error)
    }

    setLoans(fetchedLoans)

    setIsLoading(false)
  }

  useEffect(() => {
    if (bankId !== 0 && isLoginIn && bankName && !isError) {
      ws.current = new WebSocket(getWsUrl())
      ws.current.onopen = () => {
        getScrapedData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginIn, bankName, bankId])

  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = (evt) => {
        if (evt.data) {
          if (evt.data.includes("autostarttoken: ")) {
            setAutostarttoken(evt.data.replace("autostarttoken: ", ""))
            setIsLoading(false)
          } else if (evt.data.includes("qr code scanned")) {
            setIsLoading(true)
          } else if (evt.data.includes("has successfully logged in.")) {
            setIsLoggedIn(true)
            setIsLoading(true)
          } else if (evt.data.includes("Closing client ")) {
            closeWs()
          }
        }
      }
      ws.current.send("qrcode")
      ws.current.send("login")
    }
  }, [ws, isLoading])

  return {
    autostarttoken,
    loans,
    isError,
    error,
    isLoading,
    isLoggedIn,
    reset,
  }
}

export default useScrapingWebSocket
