import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 1,
        value: "savings",
        name: intl.formatMessage({
          id: "app.common.savings",
          defaultMessage: "Sparkonton",
        }),
      },
      {
        id: 2,
        value: "investments",
        name: intl.formatMessage({
          id: "app.common.investments",
          defaultMessage: "Investeringskonton",
        }),
      },
    ],
    [intl]
  )
}
