import { FC } from "react"
import { Link, NavLink } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useGetUser } from "Hooks/API/useUser"
import IconFactory from "UI/IconFactory"
import { Urls } from "Constants/urls"
import * as Styled from "./Menu.styles"

const Menu: FC = () => {
  const { data } = useGetUser()

  return (
    <Styled.StyledMenu>
      <Link to={Urls.Home} className="logo">
        <Styled.LogoWrap>
          <IconFactory name="logo" />
        </Styled.LogoWrap>
      </Link>
      <Styled.LinksWrap>
        <NavLink to={Urls.Home} end>
          <FormattedMessage
            id="app.mypages.mainNav.loan.link.overview"
            defaultMessage="Översikt"
          />
        </NavLink>
        <NavLink to={Urls.Loans}>
          <FormattedMessage
            id="app.mypages.mainNav.loan.title"
            defaultMessage="Låna"
          />
        </NavLink>
        <NavLink to={Urls.Investments}>
          <FormattedMessage
            id="app.mypages.mainNav.investments.title"
            defaultMessage="Investera"
          />
        </NavLink>
        <NavLink to={Urls.Savings}>
          <FormattedMessage
            id="app.mypages.mainNav.savings.title"
            defaultMessage="Savings"
          />
        </NavLink>
      </Styled.LinksWrap>
      <Styled.AccountLink>
        {data?.name && (
          <NavLink to={Urls.Account}>
            <Styled.AccountIcon>
              <IconFactory name="account" />
            </Styled.AccountIcon>

            <Styled.Name>
              <span>{data?.name}</span>
              <Styled.Subname>
                <FormattedMessage
                  id="app.common.accountSetting"
                  defaultMessage="Kontoinställningar"
                />
              </Styled.Subname>
            </Styled.Name>
          </NavLink>
        )}
      </Styled.AccountLink>
    </Styled.StyledMenu>
  )
}

export default Menu
