export const AUTH_PREFIX = "auth-"
export const AUTH_REFRESH_TOKEN = `${AUTH_PREFIX}refresh-token`
export const AUTH_STATUS = `${AUTH_PREFIX}status`

export const REPORTS_PREFIX = "reports-"
export const REPORTS_DOCUMENTS = `${REPORTS_PREFIX}documents`
export const REPORTS_INVESTMENT = `${REPORTS_PREFIX}investment`
export const REPORTS_DEPOSIT_SERVICE = `${REPORTS_PREFIX}deposit-service`

export const LOAN_APPLICATIONS_PREFIX = "loan-applications-"
export const LOAN_APPLICATIONS_DATA = `${LOAN_APPLICATIONS_PREFIX}data`
export const LOAN_APPLICATIONS_APPLICATION = `${LOAN_APPLICATIONS_PREFIX}application`
export const LOAN_APPLICATIONS_APPLICATION_FORM = `${LOAN_APPLICATIONS_APPLICATION}-form`
export const LOAN_APPLICATIONS_SIGN = `${LOAN_APPLICATIONS_APPLICATION}-sign`
export const LOAN_APPLICATIONS_SIGNATURE = `${LOAN_APPLICATIONS_APPLICATION}-signature`
export const LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE = `${LOAN_APPLICATIONS_APPLICATION}-insurance-agreement-signature`
export const LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE_CREATE = `${LOAN_APPLICATIONS_INSURANCE_AGREEMENT_SIGNATURE}-create`

export const LOAN_CONTRACTS_PREFIX = "loan-contracts-"
export const LOAN_CONTRACTS_DATA = `${LOAN_CONTRACTS_PREFIX}data`
export const LOAN_CONTRACTS_CONTRACT = `${LOAN_CONTRACTS_PREFIX}contract`
export const LOAN_CONTRACTS_SIGNATURE = `${LOAN_CONTRACTS_CONTRACT}-signature`

export const KYC_PREFIX = "kyc-"
export const KYC_DATA = `${KYC_PREFIX}data`
export const KYC_STATUS = `${KYC_PREFIX}status`

export const INVOICES_PREFIX = "invoices-"
export const INVOICES_DATA = `${INVOICES_PREFIX}data`

export const INVESTMENT_SERVICES_PREFIX = "investment-services-"
export const INVESTMENT_SERVICES_DATA = `${INVESTMENT_SERVICES_PREFIX}data`
export const INVESTMENT_SERVICES_SERVICE = `${INVESTMENT_SERVICES_PREFIX}service`
export const INVESTMENT_SERVICES_INVESTMENTS = `${INVESTMENT_SERVICES_SERVICE}-investments`
export const INVESTMENT_SERVICES_ACCOUNT_BALANCES_METRICS = `${INVESTMENT_SERVICES_SERVICE}-account-balances-metrics`
export const INVESTMENT_SERVICES_INVESTMENTS_AGGREGATE = `${INVESTMENT_SERVICES_INVESTMENTS}-aggregate`
export const INVESTMENT_SERVICES_UPCOMING_REPAYMENT_PLANS = `${INVESTMENT_SERVICES_SERVICE}-upcoming-repayment-plans`
export const INVESTMENT_SERVICES_SECONDARY_MARKET_LISTINGS = `${INVESTMENT_SERVICES_SERVICE}-secondary-market-listings`
export const INVESTMENT_SERVICES_SETTINGS = `${INVESTMENT_SERVICES_SERVICE}-settings`
export const INVESTMENT_SERVICES_SUMMARY = `${INVESTMENT_SERVICES_SERVICE}-summary`
export const INVESTMENT_SERVICES_PORTFILIOS = `${INVESTMENT_SERVICES_SERVICE}-portfolios`
export const INVESTMENT_SERVICES_DEMOGRAPHICS = `${INVESTMENT_SERVICES_SERVICE}-demographics`
export const INVESTMENT_SERVICES_TRANSACTIONS = `${INVESTMENT_SERVICES_SERVICE}-transactions`
export const INVESTMENT_SERVICES_MONTHLY_SAVING = `${INVESTMENT_SERVICES_SERVICE}-monthly-saving`
export const INVESTMENT_SERVICES_CURRENT_BALANCE = `${INVESTMENT_SERVICES_SERVICE}-current-balance`

export const DEPOSIT_SERVICES_PREFIX = "deposit-services-"
export const DEPOSIT_SERVICES_DATA = `${DEPOSIT_SERVICES_PREFIX}data`
export const DEPOSIT_SERVICES_SERVICE = `${DEPOSIT_SERVICES_PREFIX}service`
export const DEPOSIT_SERVICES_TRANSACTIONS = `${DEPOSIT_SERVICES_PREFIX}transactions`
export const DEPOSIT_SERVICES_PROFILES = `${DEPOSIT_SERVICES_PREFIX}profiles`
export const DEPOSIT_SERVICES_SIGNATURE_STATUS = `${DEPOSIT_SERVICES_PREFIX}signature-status`
export const DEPOSIT_SERVICES_CREARE_SIGNATURE = `${DEPOSIT_SERVICES_PREFIX}create-signature`
export const DEPOSIT_SERVICES_SERVICE_REVIEW_STATUS = `${DEPOSIT_SERVICES_PREFIX}review-status`

export const BASIC_LOAN_SERVICES_PREFIX = "basic-loan-services-"
export const BASIC_LOAN_SERVICES_DATA = `${BASIC_LOAN_SERVICES_PREFIX}data`
export const BASIC_LOAN_SERVICES_SERVICE = `${BASIC_LOAN_SERVICES_PREFIX}service`
export const BASIC_LOAN_SERVICES_CONTRACTS = `${BASIC_LOAN_SERVICES_PREFIX}contracts`
export const BASIC_LOAN_SERVICES_HAS_RECENT_REQUEST = `${BASIC_LOAN_SERVICES_PREFIX}has-recent-term-change-request`

export const USER = "user"

export const PAYMENT_FREE_MONTHS = "payment-free-months"

export const ELIGIBILITIES = "eligibilities"

export const CUSTOMER = "customer"

export const CASHBACK = "cashback"

export const BASIC_LOAN_SERVICE_TRANSACTIONS = "basic-loan-service-transactions"

export const INSURANCE_ELIGIBILITIES = "insurance-eligibilities"
