import yup from "config/yup"
import {
  PROFILE_CLASS,
  PROFILE_TERM,
  PROFILE_PREFERANCE,
} from "Constants/dataStrings"
import { InvestmetChoisesFormValues } from "./InvestmentProfileChoices.types"

export const investmentsChoisesSchema: yup.SchemaOf<InvestmetChoisesFormValues> =
  yup.object().shape({
    investmentProfileClassGroup: yup
      .string()
      .oneOf(Object.values(PROFILE_CLASS))
      .required(),
    investmentProfileTermGroup: yup
      .string()
      .oneOf(Object.values(PROFILE_TERM))
      .required(),
    reinvestmentPreferences: yup
      .string()
      .oneOf(Object.values(PROFILE_PREFERANCE))
      .required(),
  })
