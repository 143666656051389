import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"
import { ValidationMessage } from "UI/Select/Select.styles"
import { getBackgroundColorByGrade } from "Components/AssetsTable/helpers"
import { flex } from "config/mixins"

export const MyAssets = styled.div``

export const MyAssetsHeader = styled.span`
  color: var(--dark-gray);
  display: block;
  margin-bottom: 1em;
`

export const LinksWrap = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  > a {
    min-width: max-content;
    flex: 1;
    text-align: center;
  }
  @media screen and (min-width: ${mediaQueries.large}) {
    gap: 1em 2em;
  }
`

export const FilterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3em 1.5em 1em;
  gap: 1.5em;

  select {
    max-width: 280px;
    width: 100%;
  }

  ${ValidationMessage} {
    display: none;
  }

  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    margin: 2em 0 1em;
    gap: 1em;
  }

  @media screen and (max-width: ${mediaQueries.large}) {
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
    margin: 2em 0 1em;

    select {
      max-width: 100%;
    }
  }
`

export const HoldingsListBlock = styled.div``

export const ListBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0 0.5em;
`

export const FilterButton = styled.button`
  ${flex.center}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-gray);
  svg {
    width: 24px;
    height: 24px;
  }
`

export const HoldingsList = styled.ul`
  display: grid;
  row-gap: 1em;
`
export const HoldingsItem = styled.li`
  ${flex.justifyBetween}
  column-gap: 1em;
  padding: 0.5em 1em;
  border: 1px solid #ececec;
  border-radius: 3px;
  cursor: pointer;
`

export const ItemLeft = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "grade status"
    "grade percent";
  gap: 0.3em 0.5em;

  > :nth-child(1) {
    grid-area: grade;
    align-self: center;
  }

  > :nth-child(2) {
    grid-area: status;
  }

  > :nth-child(3) {
    grid-area: percent;
  }
`
export const ItemRight = styled.div`
  display: grid;
  row-gap: 0.3em;
  justify-items: flex-end;
`

export const NominalValue = styled.p`
  font-weight: 700;
  margin: 0;
  line-height: 1;
`

export const Grade = styled.div<{ grade?: string }>`
  ${flex.center}
  border-radius: 50%;
  padding: 1em;
  text-align: center;
  height: 26px;
  width: 26px;
  font-weight: bold;
  color: var(--white);
  background: ${(p) => getBackgroundColorByGrade(p.grade)};
`
