import styled, { css } from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

type GraphHeadersProps = {
  active?: boolean
  fullWidth?: boolean
}

export const InvestmentsHomeWrap = styled.div`
  margin-top: 1em;
  h1 {
    margin-top: 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      text-align: center;
    }
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-top: 1em;
  }
`

export const InvestmentOverview = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  width: calc(100% - 2em);
  gap: 0 2em;
  margin-top: 1em;

  @media screen and (max-width: ${mediaQueries.large}) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 100%;
    gap: 0;
    margin-bottom: 0;
  }
`

export const SectionHeader = styled.span`
  color: var(--dark-gray);
  display: block;
  margin: 3em 0 1em 0;
`

export const InvestmentChartBox = styled.div`
  ${flex.columnAlignCenter}
  background: ${(p) => p.theme.infoBox};
  border-radius: 16px;
  padding: 0;
  width: 100%;
  @media screen and (max-width: ${mediaQueries.large}) {
    width: 100%;
    margin-bottom: 2em;
    &:first-of-type {
      padding: 1.5em 0 0;
    }
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-bottom: 0;
  }
`

export const HeadersWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid var(--light-gray);
  width: 100%;
`

export const GraphHeaders = styled.div<GraphHeadersProps>`
  font-weight: bold;
  font-size: ${font.size.lg};
  padding-bottom: 0.5em;
  cursor: pointer;
  align-self: flex-start;
  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.base};
    line-height: 24px;
  }
  ${(p) =>
    p.active &&
    css`
      border-bottom: 3px solid var(--dark-yellow);
    `}

  ${(p) =>
    p.fullWidth &&
    css`
      margin-top: 0.25em;
      cursor: initial;
    `}
`

export const LoadingErrorContainer = styled.div`
  ${flex.center}
  height: 400px;
`

export const Divider = styled.div`
  border-bottom: 1px solid var(--light-gray);
  margin: 1em 0 0 0;
  width: 100%;
`

export const NewDepositWrap = styled.div`
  padding: 2em 2em 1em 2em;
`

export const LoadingContainer = styled.div`
  ${flex.center}
  flex: 1;
`

export const ShowMoreButton = styled.div`
  margin: 25px 23px 10px;
  color: ${(p) => p.theme.fontColor};
  text-decoration: underline;
  cursor: pointer;
`

export const LineChartWrap = styled.div`
  ${flex.columnAlignCenter}
  width: 100%;
  padding: 2em 0;
  ul {
    margin: 2em 0 0;
    @media screen and (max-width: ${mediaQueries.medium}) {
      justify-content: center;
    }
    @media screen and (max-width: ${mediaQueries.small}) {
      margin: 0.5em 0 0 0;
    }
  }
`
