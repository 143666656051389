import styled from "styled-components"
import { flex } from "config/mixins"

export const TrustlyWrap = styled.div`
  ${flex.columnCenter}
  height: 80vh;
  svg {
    margin-bottom: 2em;
  }
`
