import { FC } from "react"
import { UseQueryResult } from "react-query"
import { FormattedMessage } from "react-intl"

import { IInvestmentAggregate } from "Interfaces/investmentServicesInterfaces"
import { formatNumber } from "Utils/formatNumber"
import * as S from "./InvestmentAggregate.styles"

type InvestmentAggregateProps = {
  aggregateOverview: UseQueryResult<IInvestmentAggregate>
}

const InvestmentAggregate: FC<InvestmentAggregateProps> = ({
  aggregateOverview,
}) => (
  <S.OverviewWrap data-testid="investment-aggregate">
    {aggregateOverview.isError && (
      <FormattedMessage
        id="app.mypages.error.investment.getInvestmentAggregate"
        defaultMessage="Kan inte få aggregerade uppgifter denna investeringstjänst. Återuppta"
      />
    )}
    {aggregateOverview.data && (
      <>
        <div>
          <S.OverviewItemTitle>
            <FormattedMessage
              id="app.mypages.investments.assets.endedAssets.overview.numberOfLoans"
              defaultMessage="Antal lån"
            />
          </S.OverviewItemTitle>
          <S.OverviewItemData>
            {aggregateOverview.data?.number_of_investments}
          </S.OverviewItemData>
        </div>
        <div>
          <S.OverviewItemTitle>
            <FormattedMessage
              id="app.mypages.investments.assets.endedAssets.overview.averageTermTime"
              defaultMessage="Genomsnittlig löptid"
            />
          </S.OverviewItemTitle>
          <S.OverviewItemData>
            {formatNumber(Number(aggregateOverview.data?.avg_loan_term))}
          </S.OverviewItemData>
        </div>
        <div>
          <S.OverviewItemTitle>
            <FormattedMessage
              id="app.mypages.investments.assets.endedAssets.overview.averageLoanedInterest"
              defaultMessage="Genomsnittlig utlåningsränta"
            />
          </S.OverviewItemTitle>
          <S.OverviewItemData>
            {formatNumber(Number(aggregateOverview.data?.avg_interest_rate), {
              style: "percent",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </S.OverviewItemData>
        </div>
        <div>
          <S.OverviewItemTitle>
            <FormattedMessage
              id="app.mypages.investments.assets.endedAssets.overview.amountOfLoanShares"
              defaultMessage="Antal låneandelar"
            />
          </S.OverviewItemTitle>
          <S.OverviewItemData>
            {aggregateOverview.data?.total_number_of_loan_assets}
          </S.OverviewItemData>
        </div>
        <div>
          <S.OverviewItemTitle>
            <FormattedMessage
              id="app.mypages.investments.assets.endedAssets.overview.totalPortfolioWorth"
              defaultMessage="Totalt bokfört värde "
            />
          </S.OverviewItemTitle>
          <S.OverviewItemData>
            {formatNumber(Number(aggregateOverview.data?.total_nominal_value))}
          </S.OverviewItemData>
        </div>
      </>
    )}
  </S.OverviewWrap>
)

export default InvestmentAggregate
