import MenuLink from "Components/MenuLink"
import { linksData } from "./data"
import * as S from "./Transactions.styles"

const Transactions = () => (
  <S.TransactionsWrap>
    {linksData.map(({ text, link }) => (
      <MenuLink to={link} key={link}>
        {text}
      </MenuLink>
    ))}
  </S.TransactionsWrap>
)

export default Transactions
