import styled from "styled-components"

import { flex } from "config/mixins"

export const FilterWrap = styled.div`
  display: flex;
  max-width: 450px;
  width: 100%;
  margin: 3em 0 1em;
  gap: 1em;
`

export const LoadingContainer = styled.div`
  ${flex.center}
  flex: 1;
`
export const EndedAssetsWrap = styled.div`
  padding-top: 1em;
`
