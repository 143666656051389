import { useIntl } from "react-intl"

import { IInvestmentAsset } from "Interfaces/investmentServicesInterfaces"
import { formatNumber } from "Utils/formatNumber"
import {
  occupationStatuses,
  martialStatuses,
  housingTypes,
  loanPerformanceStatuses,
} from "./config"
import * as S from "./ExtendedInfo.styles"

type ExtendedInfoProps = {
  info: IInvestmentAsset
}

const ExtendedInfo = ({ info }: ExtendedInfoProps) => {
  const intl = useIntl()

  return (
    <S.ExtendedInfoContainer>
      <S.InfoContainer>
        <h4>
          {intl.formatMessage({
            id: "app.common.loan",
            defaultMessage: "Lån",
          })}
        </h4>
        <S.InfoList>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsTable.tableHeaders.loanInterestRate",
                defaultMessage: "Utlåningsränta",
              })}
            </div>
            <div>
              {intl.formatNumber(Number(info.loan_interest_rate), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "percent",
              })}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsTable.tableHeaders.bookValue",
                defaultMessage: "Bokört värde",
              })}
            </div>

            <div>
              {formatNumber(Number(info.nominal_value), {
                style: "currency",
              })}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsTable.tableHeaders.term",
                defaultMessage: "Löptid",
              })}
            </div>
            <div>{info.loan_term ?? "0"}</div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsTable.tableHeaders.status",
                defaultMessage: "Status",
              })}
            </div>
            <div>
              {intl.formatMessage(
                loanPerformanceStatuses[info.loan_performance_status] ?? {
                  defaultMessage: "-",
                }
              )}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsTable.tableHeaders.riskClass",
                defaultMessage: "Riskklass",
              })}
            </div>
            <div>{info.grade}</div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.onboarding.title.step5",
                defaultMessage: "Låneskydd",
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: info.has_payment_protection
                  ? "app.common.yes"
                  : "app.common.no",
                defaultMessage: info.has_payment_protection ? "Ja" : "Nej",
              })}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.sharesAmount",
                defaultMessage: "Antal andelar",
              })}
            </div>
            <div>{info.number_of_loan_assets}</div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.sharesToSellAmount",
                defaultMessage: "Andelar utlagda till försäljning",
              })}
            </div>
            <div>
              {info.secondary_market_listings[0].number_of_listed_assets}
            </div>
          </S.InfoListItem>
        </S.InfoList>
      </S.InfoContainer>

      <S.InfoContainer>
        <h4>
          {intl.formatMessage({
            id: "app.common.borrower",
            defaultMessage: "Låntagare",
          })}
        </h4>
        <S.InfoList>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.common.gender",
                defaultMessage: "Kön",
              })}
            </div>
            <div>
              {intl.formatMessage({
                id: `app.common.${info.customer_data.borrower.gender}`,
                defaultMessage: "-",
              })}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.livingIn",
                defaultMessage: "Bor i",
              })}
            </div>
            <div>{info.customer_data.borrower.state}</div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.occupation",
                defaultMessage: "Sysselsättning",
              })}
            </div>
            <div>
              {intl.formatMessage(
                occupationStatuses[info.customer_data.borrower.occupation] ?? {
                  defaultMessage: "-",
                }
              )}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.martialStatus",
                defaultMessage: "Civilstånd",
              })}
            </div>
            <div>
              {intl.formatMessage(
                martialStatuses[info.customer_data.borrower.marital_status] ?? {
                  defaultMessage: "-",
                }
              )}
            </div>
          </S.InfoListItem>
          <S.InfoListItem>
            <div>
              {intl.formatMessage({
                id: "app.mypages.investments.assets.assetsList.extendedInfo.formOfAccomodation",
                defaultMessage: "Boendeform",
              })}
            </div>
            <div>
              {intl.formatMessage(
                housingTypes[info.customer_data.borrower.housing_type] ?? {
                  defaultMessage: "-",
                }
              )}
            </div>
          </S.InfoListItem>
        </S.InfoList>
      </S.InfoContainer>
    </S.ExtendedInfoContainer>
  )
}

export default ExtendedInfo
