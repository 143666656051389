import { FC } from "react"
import { FormattedMessage } from "react-intl"

import DepositOptions from "Components/DepositOptions"
import * as S from "./Deposit.styles"

const Deposit: FC = () => (
  <>
    <S.DepositsContentHeader>
      <FormattedMessage
        id="app.mypages.savings.deposit.paragraph"
        defaultMessage="Hur vill du sätta in pengar?"
      />
    </S.DepositsContentHeader>
    <S.SavingsDepositDescription>
      <FormattedMessage
        id="app.mypages.savings.deposit.description"
        defaultMessage="Välj hur du vill sätta in pengar. Insättningar tar vanligtvis 1-2 vardagar."
      />
    </S.SavingsDepositDescription>
    <DepositOptions />
  </>
)

export default Deposit
