import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { useGetPortfolioMetrics } from "Hooks/API/useInvestmentServices"
import Loading from "UI/Loading"
import ResultsBox from "Components/ResultsBox"
import { AssetsSummaryPropTypes } from "./AssetsSummary.types"
import AssetsStatus from "./AssetsStatus"
import LoanTerm from "./LoanTerm"
import RiskClass from "./RiskClass"
import * as S from "./AssetsSummary.styles"

const AssetsSummary: FC<AssetsSummaryPropTypes> = ({ accountID }) => {
  const gradeMetrics = useGetPortfolioMetrics(accountID, "grade")
  const termMetrics = useGetPortfolioMetrics(accountID, "term_left")
  const statusMetrics = useGetPortfolioMetrics(accountID, "loan_status")

  if (
    gradeMetrics.isLoading ||
    termMetrics.isLoading ||
    statusMetrics.isLoading
  ) {
    return (
      <S.AssetsOverview data-testid="assets-summary">
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      </S.AssetsOverview>
    )
  }

  if (gradeMetrics.isError || termMetrics.isError || statusMetrics.isError) {
    return (
      <S.AssetsOverview data-testid="assets-summary">
        <S.LoadingErrorContainer>
          <FormattedMessage
            id="app.mypages.error.investment.getListInvestmentAssets"
            defaultMessage="Kan inte få en förteckning över dina investeringstillgångar. Försök igen"
          />
        </S.LoadingErrorContainer>
      </S.AssetsOverview>
    )
  }

  return (
    <S.AssetsOverview data-testid="assets-summary">
      {gradeMetrics.data && termMetrics.data && statusMetrics.data && (
        <>
          <ResultsBox>
            <ResultsBox.Header>
              <FormattedMessage
                id="app.mypages.investments.assets.riskOverview.titles.riskClass"
                defaultMessage="Riskklass"
              />
            </ResultsBox.Header>
            <ResultsBox.Content>
              <RiskClass data={gradeMetrics.data.data} />
            </ResultsBox.Content>
          </ResultsBox>
          <ResultsBox>
            <ResultsBox.Header>
              <FormattedMessage
                id="app.mypages.investments.assets.riskOverview.titles.term"
                defaultMessage="Löptid"
              />
            </ResultsBox.Header>
            <ResultsBox.Content>
              <LoanTerm data={termMetrics.data.data} />
            </ResultsBox.Content>
          </ResultsBox>

          <ResultsBox>
            <ResultsBox.Header>
              <FormattedMessage
                id="app.mypages.investments.assets.riskOverview.titles.loanStatus"
                defaultMessage="Lånestatus"
              />
            </ResultsBox.Header>
            <ResultsBox.Content>
              <AssetsStatus data={statusMetrics.data.data} />
            </ResultsBox.Content>
          </ResultsBox>
        </>
      )}
    </S.AssetsOverview>
  )
}

export default AssetsSummary
