import type { FC } from "react"
import { FormattedMessage } from "react-intl"

import type { SwishProps } from "../DepositOptions.types"
import * as S from "../DepositOptions.styles"

const Swish: FC<SwishProps> = ({ productNumber }) => (
  <S.OptionsContent>
    <S.DepositOptionHeading>
      <FormattedMessage
        id="app.depositAccordion.swish.body.heading"
        defaultMessage="Sätt in pengar med Swish"
      />
    </S.DepositOptionHeading>
    <S.DepositOptionDescription>
      <FormattedMessage
        id="app.depositAccordion.swish.body.description"
        defaultMessage="Gör en insättning i endast 1 steg. Insättningar tar vanligtvis 1 arbetsdag."
      />
    </S.DepositOptionDescription>
    <ol>
      <li>
        <FormattedMessage
          id="app.depositAccordion.swish.body.list.1"
          defaultMessage="Swisha till telefonnummer {tel} med meddelandet
    {productNumber} som används som referens för att identifiera din
    insättning"
          values={{
            productNumber: <strong>{productNumber}</strong>,
            tel: <strong>123 04 01 943</strong>,
          }}
        />
      </li>
    </ol>
  </S.OptionsContent>
)

export default Swish
