import { MemoExoticComponent } from "react"
import { TFeatureType } from "../../Components/Charts/GeographicChart/GeographicChart.types"

type Position = number[]

export type DataType = { id: string; value: unknown }

type MultiPolygon = {
  type: "MultiPolygon"
  coordinates: Position[][][]
}

type Polygon = {
  type: "Polygon"
  coordinates: Position[][]
}

export type FeatureType = {
  type: string
  properties: {
    name: string
    color: number
    l_id: number
  }
  id: string
  label?: string
  value?: number
  geometry: {
    type: string
    coordinates: number[][][][] | number[][][] | number[][]
  }
}

export type FeatureWithData = {
  data: DataType
  value: number
  formattedValue: number | string
} & FeatureType

export type BoundFeaturesType = FeatureType & {
  color: string
  data: DataType
  formattedValue: string
  label: string
  value: number
  geometries: MultiPolygon[] | Polygon[]
}

export type StyleType = {
  symbolSize?: number
  itemOpacity?: number
  itemBackground?: string
  itemTextColor?: string
}

export type BirectionType = "column" | "row"

export type CustomChoroplethProps = {
  features: FeatureType[]
  data: DataType[]
  margin: {
    top: number
    right: number
    bottom: number
    left: number
  }
  colors: string[]
  domain: [number, number]
  unknownColor: string
  label: string
  valueFormat: string
  projectionScale: number
  projectionTranslation: [number, number]
  projectionRotation: [number, number, number]
  isInteractive: boolean
  borderWidth?: number
  borderColor?: string
  tooltip?: MemoExoticComponent<
    ({ feature }: TFeatureType) => JSX.Element | null
  >
  layers?: string[]
  width: number
  height: number
  match?: string
  value?: string
  legends: {
    direction: BirectionType
    translateX: number
    translateY: number
    itemsSpacing: number
    itemWidth: number
    itemHeight: number
    itemOpacity: number
    symbolSize: number
    effects?: {
      on: "hover"
      style: StyleType
    }
  }[]
}

export type UseChoroplethOptions = {
  features: FeatureType[]
  data: DataType[]
  match: string
  label: string
  value: string
  valueFormat: string
  colors: string[] | number[]
  domain: [number, number]
  unknownColor: string
}

export type PaddingType = {
  top: number
  right: number
  bottom: number
  left: number
}

type LegendDataType = {
  id: number
  index: number
  extent: number[]
  label: string
  value: number
  color: number
}

export type LegendProps = {
  data: LegendDataType[]
  translateX?: number
  translateY?: number
  direction: BirectionType
  padding?: number | PaddingType
  itemsSpacing?: number
  itemWidth: number
  itemHeight: number
  itemOpacity: number
  effects?: { on: "hover"; style: StyleType }
}

export type LegendItemProps = {
  x: number
  y: number
  width: number
  height: number
  data: LegendDataType
  background?: string
  opacity?: number
  effects?: { on: "hover"; style: StyleType }
}

export enum LayersEnum {
  features = "features",
  legends = "legends",
}
