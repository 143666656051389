import { FC, useMemo } from "react"
import { Outlet, useLocation, useParams, Navigate } from "react-router-dom"

import TabLinks from "Components/TabLinks"
import { useGetDepositServiceByID } from "Hooks/API/useDepositServices"
import AccountName from "Components/AccountName/AccountName"
import { useGetBasicLoanServiceByID } from "Hooks/API/useBasicLoanServices"
import Loading from "UI/Loading"
import { Urls } from "Constants/urls"
import { useInvestmentServiceByIDQuery } from "Hooks/API/useInvestmentServices"
import {
  createInvestmentsLinks,
  createSavingsLinks,
  createloanLinksWithID,
  loanLinks,
  createInvestmentAccountProps,
  createSavingsAccountProps,
  createLoanAccountProps,
  serviceHeadings,
} from "./helpers"
import { IParams, ServiceLayoutPropTypes } from "./ServiceLayout.types"
import { LoadingContainer, Heading } from "../PageLayout/PageLayout.styles"

const ServiceLayout: FC<ServiceLayoutPropTypes> = ({ type }) => {
  const { accountID } = useParams() as IParams
  const { pathname } = useLocation()

  const investment = useInvestmentServiceByIDQuery(
    accountID,
    type === "investments"
  )

  const loan = useGetBasicLoanServiceByID(accountID, type === "loan")
  const deposit = useGetDepositServiceByID(accountID, type === "savings")

  const links = useMemo(
    () => ({
      loans: loanLinks,
      loan: createloanLinksWithID(accountID),
      savings: createSavingsLinks(accountID),
      investments: createInvestmentsLinks(accountID),
    }),
    [accountID]
  )

  const loanHeading = serviceHeadings[pathname]

  const accountData = {
    savings: createSavingsAccountProps(deposit.data),
    investments: createInvestmentAccountProps(investment.data),
    loan: createLoanAccountProps(loan.data),
  }

  const errorServices = useMemo(
    () => ({
      loan: loan.isError,
      investments: investment.isError,
      savings: deposit.isError,
    }),
    [deposit.isError, loan.isError, investment.isError]
  )

  const isLoading = investment.isLoading || deposit.isLoading || loan.isLoading

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    )
  }

  if (type !== "loans" && errorServices[type]) {
    return <Navigate to={Urls.Home} />
  }

  return (
    <>
      {type !== "loans" && !isLoading && accountData[type] ? (
        <>
          <AccountName {...accountData[type]} />
          {deposit.data?.status !== "closed" && (
            <TabLinks links={links[type]} />
          )}
        </>
      ) : (
        type === "loans" && (
          <>
            <Heading>{loanHeading}</Heading>
            <TabLinks links={links[type]} />
          </>
        )
      )}
      <Outlet />
    </>
  )
}

export default ServiceLayout
