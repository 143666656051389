import { FC } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import { useGetInvestmentAggregate } from "Hooks/API/useInvestmentServices"
import InvestmentAggregate from "Components/InvestmentAggregate"
import AssetsForSaleTable from "Components/AssetsForSaleTable"
import Loading from "UI/Loading"
import * as S from "./UnlistAssets.styles"

const UnlistAssets: FC = () => {
  const { accountID } = useParams() as { accountID: string }
  const aggregateOverview = useGetInvestmentAggregate(accountID)

  if (aggregateOverview.isLoading) {
    return (
      <S.LoadingContainer data-testid="loading">
        <Loading />
      </S.LoadingContainer>
    )
  }

  return (
    <S.UnlistAssetsWrap>
      <S.UnlistAssetsDesc aria-label="description">
        <FormattedMessage
          id="app.mypages.investments.forSaleAssets.paragraph"
          defaultMessage="Tiden för överlåtelsen är beroende av efterfrågan av överlåtna portföljer vid försäljningstillfället, vilket innebär att tiden för överlåtelsen kan variera mellan 1-3 månader. Observera även att detta gäller vid prioriterad försäljning. Om Brocc ej överlåtit din portfölj inom avsedda villkor för prioriterad försäljning kommer avgiften tas bort."
        />
      </S.UnlistAssetsDesc>
      <InvestmentAggregate aggregateOverview={aggregateOverview} />
      <AssetsForSaleTable />
    </S.UnlistAssetsWrap>
  )
}

export default UnlistAssets
