import { FC } from "react"
import { useIntl } from "react-intl"
import Checkbox from "UI/Checkbox"
import { IInvestmentAsset } from "Interfaces/investmentServicesInterfaces"

import MobileRow from "../MobileRow"
import AssetsInput from "../AssetsInput"
import { AssetsItem } from "../MobileRow/MobileRow.types"
import { SellableAssetType } from "../AssetsTable.types"
import * as Styled from "./Table.styles"
import { determineInvestmentStatus } from "../helpers"

type TablePropTypes = {
  isEnded?: boolean
  isSelling?: boolean
  assetsList: IInvestmentAsset[]
  setSellableAssets: React.Dispatch<React.SetStateAction<SellableAssetType[]>>
  sellableAssets: SellableAssetType[]
}

const Table: FC<TablePropTypes> = ({
  isEnded,
  isSelling,
  assetsList,
  setSellableAssets,
  sellableAssets,
}) => {
  const intl = useIntl()

  const updateSellableAssets = (asset: AssetsItem) => {
    const hasAsset = sellableAssets.find((item) => item.loan_uid === asset.uid)
    if (hasAsset) {
      const newSellableAssets = sellableAssets.map((item) => {
        if (item.loan_uid === asset.uid) {
          return { ...item, checked: !item.checked }
        }
        return item
      })
      setSellableAssets(newSellableAssets)
    } else {
      const newSellableAsset = {
        loan_uid: asset.uid,
        number_of_assets_to_list: 1,
        checked: true,
      }
      setSellableAssets([...sellableAssets, newSellableAsset])
    }
  }

  return (
    <Styled.AssetsTableContainer>
      {assetsList?.map((assets) => (
        <MobileRow
          key={`${assets.uid}-mobile`}
          assets={assets}
          isSelling={!!isSelling}
          sellableAssets={sellableAssets}
          updateSellableAssets={updateSellableAssets}
          setSellableAssets={setSellableAssets}
          isEnded={!!isEnded}
        />
      ))}

      {assetsList?.map((assets) => {
        const isChecked = !!sellableAssets.find(
          (item) => item.loan_uid === assets.uid && item.checked
        )
        const nominalValue = intl.formatNumber(Number(assets.nominal_value), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        const interestRate = intl.formatNumber(
          Number(assets.loan_interest_rate),
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: "percent",
          }
        )
        const repaid = intl.formatNumber(
          parseFloat(assets.interest_paid_to_date) +
            parseFloat(assets.principal_paid_to_date),
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )
        const numberOfListedAssets =
          assets.secondary_market_listings[0].number_of_listed_assets
        return (
          <Styled.AssetsTableItemWrap
            key={`${assets.uid}-desktop`}
            isEnded={isEnded}
          >
            <Styled.AssetsTableItem data-testid="loanNumber">
              {assets.loan_number}
            </Styled.AssetsTableItem>
            {!isSelling && (
              <Styled.AssetsTableItem>
                {determineInvestmentStatus(assets.investment_status)}
              </Styled.AssetsTableItem>
            )}
            <Styled.AssetsTableItem>
              <Styled.Grade grade={assets.grade}>{assets.grade}</Styled.Grade>
            </Styled.AssetsTableItem>
            <Styled.AssetsTableItem>{assets.loan_term}</Styled.AssetsTableItem>
            <Styled.AssetsTableItem>{interestRate}</Styled.AssetsTableItem>
            <Styled.AssetsTableItem>
              {assets.number_of_loan_assets}
            </Styled.AssetsTableItem>
            {!isEnded && (
              <Styled.AssetsTableItem>
                {numberOfListedAssets}
              </Styled.AssetsTableItem>
            )}
            <Styled.AssetsTableItem>{nominalValue}</Styled.AssetsTableItem>
            <Styled.AssetsTableItem>
              {isSelling ? (
                <Checkbox
                  size="large"
                  aria-label={`check loan #${assets.loan_number}`}
                  name={assets.uid}
                  value={assets.uid}
                  checked={isChecked}
                  onChange={() => updateSellableAssets(assets)}
                />
              ) : (
                repaid
              )}
            </Styled.AssetsTableItem>
            {isSelling && (
              <Styled.AssetsTableItem>
                <AssetsInput
                  numberOfLoanAssets={assets.number_of_loan_assets}
                  uid={assets.uid}
                  sellableAssets={sellableAssets}
                  setSellableAssets={setSellableAssets}
                />
              </Styled.AssetsTableItem>
            )}
          </Styled.AssetsTableItemWrap>
        )
      })}
    </Styled.AssetsTableContainer>
  )
}

export default Table
