import styled from "styled-components"
import { font } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import { DivergingChartWrapperPropTypes } from "./DivergingBarChart.types"

export const DivergingChartWrapper = styled.div<DivergingChartWrapperPropTypes>`
  ${flex.center}
  height: ${(props) => (props.isError ? "200px" : "400px")};
  width: auto;
  text-align: center;
  padding: 1em 0;
  line-height: 24px;
  color: var(--black);
  fill: ${(props) => props.theme.fontColor};
  margin-bottom: 2em;
  .recharts-legend-item-text {
    color: black !important;
    font-size: ${font.size.base};
  }
  .recharts-default-legend {
    ${flex.justifyCenter}
    flex-direction: row;
    padding-left: 10% !important;
    gap: 7em;
  }
`
