import styled from "styled-components"
import { font } from "Styles/styleGlobal"

export const RiskPortfolioModalWrap = styled.div`
  padding-bottom: 1em;
  p {
    margin: 1em 0 !important;
  }
`

export const RiskPortfolioModalHeading = styled.h3`
  margin: 2em 0 0.5em;
  &:first-of-type {
    margin-top: 1em;
  }
`

export const RiskPortfolioModalParagraph = styled.p``

export const RiskPortfolioModalRiskClass = styled.p`
  font-weight: 500;
`
export const RiskPortfolioModalMarkdown = styled.em`
  color: var(--dark-gray);
  font-size: ${font.size.sm};
`
