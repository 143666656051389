export class Urls {
  static readonly NotFound = "*"

  static readonly Home = "/"

  static readonly Login = "/login"

  static readonly FollowApplication = "/follow-application/:id"

  static readonly Account = "/account"
  static readonly AccountInfo = "information"
  static readonly AccountKyc = "kyc"
  static readonly AccountReports = "reports"
  static readonly AccountLanguage = "language"

  static readonly Loans = "/loans"
  static readonly LoansInvoices = "invoices"
  static readonly LoansCashback = "cashback"
  static readonly LoansProposedContract = "proposed-loan-contract/:id"
  static readonly LoansApplyForLoan = "apply-for-loan"
  static readonly LoansCollect = "collect-loans"
  static readonly LoansAccountID = ":accountID"
  static readonly LoansAccountOverview = "overview"
  static readonly LoansAccountDetails = "details"
  static readonly LoansAccountInvoices = `${Urls.LoansInvoices}`
  static readonly LoansAccountLowerInterest = ":accountID/lower-interest"

  static readonly LoansCollection = "/loan-collection"
  static readonly Ws = "ws?sessionId="

  static readonly Savings = "/savings"
  static readonly SavingsOnboarding = "onboarding/:accountID"
  static readonly SavingsAccount = ":accountID"
  static readonly SavingsAccountOverview = "overview"
  static readonly SavingsAccountDeposit = "deposit"
  static readonly SavingsAccountWithdrawal = "withdrawal"
  static readonly SavingsAccountDetails = "details"
  static readonly SavingsAccountSettings = "settings"

  static readonly Investments = "/investments"
  static readonly InvestmentsOnboarding = "onboarding/:accountID"
  static readonly InvestmentsAccount = ":accountID"
  static readonly InvestmentsAccountOverview = "overview"
  static readonly InvestmentsAccountHoldings = "holdings"
  static readonly InvestmentsAccountResults = "results"
  static readonly InvestmentsAccountInvestmentChoises = "investment-choices"
  static readonly InvestmentsAccountTransactions = "transactions"
  static readonly InvestmentsTrustlySuccess = `${Urls.Investments}/trustly-success/:accountID`
  static readonly InvestmentsTrustlyFail = `${Urls.Investments}/trustly-fail/:accountID`
  static readonly InvestmentsAccountHoldingsSellAssets = "sell-assets"
  static readonly InvestmentsAccountHoldingsEndedAssets = "ended-assets"
  static readonly InvestmentsAccountHoldingsPrepareForSale = "prepare-for-sale"
  static readonly InvestmentsAccountTransactionsDeposit = "deposit"
  static readonly InvestmentsAccountTransactionsWithdraw = "withdraw"
  static readonly InvestmentsAccountTransactionsRegisteredDeposits =
    "registered-deposits"
}
