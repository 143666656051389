import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const RegisterWrap = styled.div`
  margin-top: 1.5em;
  position: relative;
  > span {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    margin-top: 0;
  }
`

export const RegisterOveriew = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 550px;
  @media screen and (max-width: ${mediaQueries.small}) {
    flex-direction: column;
  }
`

export const RegisterOverviewTitle = styled.div`
  color: var(--dark-gray);
  font-size: ${font.size.base};
  text-align: center;
  margin-bottom: 1em;
  @media screen and (max-width: ${mediaQueries.small}) {
    margin: 1em 0 0.5em;
  }
`

export const RegisterOverviewItem = styled.div`
  font-size: ${font.size.xl2};
  text-align: center;
  @media screen and (max-width: ${mediaQueries.large}) {
    font-size: ${font.size.xl};
  }
  @media screen and (max-width: ${mediaQueries.medium}) {
    font-size: ${font.size.xl};
  }
  @media screen and (max-width: ${mediaQueries.small}) {
    font-size: ${font.size.xl2};
  }
`

export const LoadingContainer = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
