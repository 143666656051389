import { FC } from "react"
import { useParams } from "react-router-dom"

import Holdings from "./Holdings/Holdings"
import AssetsSummary from "./AssetsSummary/AssetsSummary"
import * as S from "./Assets.styles"

const Assets: FC = () => {
  const { accountID } = useParams() as { accountID: string }

  return (
    <S.AssetsWrap>
      <AssetsSummary accountID={accountID} />
      <Holdings />
    </S.AssetsWrap>
  )
}

export default Assets
