import styled from "styled-components"
import { flex } from "config/mixins"
import { font, mediaQueries } from "Styles/styleGlobal"

export const GrayBoxForm = styled.form`
  background-color: ${(p) => p.theme.infoBox};
  margin-bottom: 5em;
  padding-bottom: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    margin-bottom: 0;
  }
`
export const GrayInfoBox = styled.div`
  background-color: ${(p) => p.theme.infoBox};
  border-radius: 16px;
  padding: 3em;
`

export const SectionContent = styled.div`
  ${flex.justifyBetween}
  padding: 2em 0;
  gap: 1em;

  @media screen and (max-width: ${mediaQueries.medium}) {
    flex-direction: column;
    padding: 0;
  }
`

export const BoxInfo = styled.div`
  max-width: 400px;
  min-width: 200px;
  width: 100%;

  h4,
  p {
    margin: 1.5em auto;
  }
  h4 {
    margin: 0 auto 1.5em;
    @media screen and (max-width: ${mediaQueries.medium}) {
      margin: 0.5em 0;
    }
  }

  p {
    color: ${(p) => p.theme.fontColor};
  }
`

export const SelectWrap = styled.div`
  width: 400px;
  @media screen and (max-width: ${mediaQueries.medium}) {
    width: 100%;
    margin: 1em 0;
  }

  span {
    opacity: 0.7;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 0 0.5em;
  }
  select {
    margin-top: 0;
  }
`

export const ModalLink = styled.button`
  text-decoration: underline;
  font-size: ${font.size.base};
  padding: 0;
  display: block;
  color: ${(p) => p.theme.fontColor};
`

export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 2em;
  @media screen and (max-width: ${mediaQueries.medium}) {
    justify-content: center;
  }
  button {
    padding: 1em 3em;
  }
`

export const ModalBody = styled.div``

export const TickIconWrap = styled.div`
  svg {
    transform: scale(2);
    margin-top: 1em;
  }
`

export const HappyIconWrap = styled.span`
  margin-left: 0.75em;
  svg {
    vertical-align: bottom;
  }
`

export const SectionTitle = styled.h4`
  ${flex.justifyBetween}
  align-items: flex-start;
  font-size: ${font.size.xl2};
`
