import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"
import { flex } from "config/mixins"
import * as T from "../AssetsTable.types"

export const AssetsTableHead = styled.div<T.AssetsTableHeadProps>`
  ${flex.center}
  font-size: ${font.size.sm};
  font-weight: bold;
  hyphens: auto;
  text-align: center;

  ${(props) =>
    props.hideMobile &&
    css`
      @media screen and (max-width: ${mediaQueries.large}) {
        display: none;
      }

      display: flex;
    `}
  ${(props) =>
    props.showMobile &&
    css`
      @media screen and (max-width: ${mediaQueries.large}) {
        display: flex;
      }

      display: none;
    `};
`

export const AssetsTableHeadWrap = styled.div<T.AssetsTableHeadWrapProps>`
  display: grid;
  gap: 0 1.5em;
  padding: 1em;
  border-bottom: 1px solid var(--light-gray);

  ${(p) => css`
    grid-template-columns: ${p.isEnded ? "repeat(8, 1fr)" : "repeat(9, 1fr)"};
    @media screen and (max-width: ${mediaQueries.large}) {
      grid-template-columns: ${p.isSelling
        ? "repeat(5, 1fr)"
        : "repeat(4, 1fr)"};
    }
    grid-template-columns: ${p.withCheckbox && "50px repeat(8, 1fr);"};
  `}
`
