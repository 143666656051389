import { EnvKeysEnum, getEnv } from "Utils/environment"
import { Urls } from "Constants/urls"

export interface AccountType {
  BG: string
  PG: string
}

const webSocketUrl = () => {
  const webSocketProtocol =
    window.location.protocol === "https:" ? "wss://" : "ws://"
  const broccApi = getEnv(EnvKeysEnum.BroccApi)!
  const loanCollection = `${Urls.LoansCollection}/${Urls.Ws}`
  let baseUrl = ""

  try {
    const url = new URL(broccApi)
    baseUrl = `${url.host}${url.pathname}`
  } catch {
    baseUrl = `${window.location.host}${broccApi}`
  }

  return `${webSocketProtocol}${baseUrl}${loanCollection}`
}

export const ANNAN_AKTOR = "Annan kreditgivare"
export const WEB_SOCKET_URL = webSocketUrl()
export const BANKID_BASE_URL = "bankid:///?autostarttoken="
export const GENERIC_ERROR = "Something went wrong. Please try again later"
export const STRATEGIES = {
  SSN: "SSN",
  BANK_ID: "BANK_ID",
}
export const ACCOUNT_TYPE_OPTIONS = [
  { id: "1", name: "Bankgiro", value: "BG" },
  { id: "2", name: "Plusgiro", value: "PG" },
]
export const ACCOUNT_TYPE: AccountType = {
  BG: "9900",
  PG: "9500",
}
export const LOAN_TYPE_OPTIONS = [
  { id: "1", name: "Lån", value: "Lån" },
  { id: "2", name: "Kredit", value: "Kredit" },
  { id: "3", name: "Avbetalning", value: "Avbetalning" },
]

export enum BanksName {
  klarna = "Klarna",
  santander = "Santander",
  marginalen = "Marginalen",
}
