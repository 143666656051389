import { FC, useCallback } from "react"
import { PieChart as RePieChart, Pie, Cell, Legend } from "recharts"
import { FormattedMessage, useIntl } from "react-intl"
import { useResizeObserver } from "Hooks/UI/useResizeObserber"
import { PieChartPropTypes } from "./PieChart.types"
import { PieChartWrapper } from "./PieChart.styles"
import { mapToPieChartData } from "./helpers"

const PieChart: FC<PieChartPropTypes> = ({ data, metricType }) => {
  const intl = useIntl()
  const { parentRef, content } = useResizeObserver()

  const chartData = mapToPieChartData(data, metricType, intl)

  const renderCustomizedLabel = useCallback(
    ({ percent }: { percent: number }) => `${(percent * 100).toFixed(0)}%`,
    []
  )

  if (data.length === 0) {
    return (
      <PieChartWrapper isError>
        <FormattedMessage
          id="app.mypages.error.investment.charts"
          defaultMessage="Det finns för närvarande ingenting att visa."
        />
      </PieChartWrapper>
    )
  }

  return (
    <PieChartWrapper data-testid="pie-chart" ref={parentRef}>
      <RePieChart
        width={content.width}
        height={content.height}
        margin={{ top: 15, right: 0, bottom: 0, left: 0 }}
      >
        <Pie
          data={chartData}
          nameKey="label"
          dataKey="value"
          outerRadius="75%"
          cy="55%"
          legendType="circle"
          label={renderCustomizedLabel}
        >
          {chartData.map((entry, i) => (
            <Cell key={`cell-${i}`} fill={entry.color} />
          ))}
        </Pie>
        <Legend verticalAlign="top" />
      </RePieChart>
    </PieChartWrapper>
  )
}

export default PieChart
