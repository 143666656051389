import { FC } from "react"
import { FormattedMessage } from "react-intl"

import { formatNumber } from "Utils/formatNumber"
import * as S from "./LoanTerm.styles"
import { LoanTermMetricLabel, LoanTermPropTypes } from "./LoanTerm.types"

const loanTermMetrics: LoanTermMetricLabel[] = [
  "0-24",
  "24-48",
  "48-60",
  "60-84",
  "84-120",
  "120+",
]

const loanTermLabels = ["0-23", "24-47", "48-59", "60-83", "84-119", "120+"]

const LoanTerm: FC<LoanTermPropTypes> = ({ data }) => {
  const metricsData = data.reduce(
    (res, metric) => {
      res[metric.label as LoanTermMetricLabel] = {
        count: metric.count,
        amount: metric.amount,
      }

      res.totalAmount += metric.amount
      res.totalCount += metric.count
      return res
    },
    {
      "0-24": {
        count: 0,
        amount: 0,
      },
      "24-48": {
        count: 0,
        amount: 0,
      },
      "48-60": {
        count: 0,
        amount: 0,
      },
      "60-84": {
        count: 0,
        amount: 0,
      },
      "84-120": {
        count: 0,
        amount: 0,
      },
      "120+": {
        count: 0,
        amount: 0,
      },
      totalCount: 0,
      totalAmount: 0,
    }
  )

  if (data.length === 0) {
    return (
      <S.LoanTermContainer empty>
        <FormattedMessage
          id="app.mypages.investments.assets.riskOverview.noAssets"
          defaultMessage="Inga innehav att visa"
        />
      </S.LoanTermContainer>
    )
  }
  return (
    <S.LoanTermContainer data-testid="loan-term">
      <S.LoanTermColumn>
        <S.LoanTermItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.months"
            defaultMessage="Månader"
          />
        </S.LoanTermItem>
        {loanTermLabels.map((label, i) => (
          <S.LoanTermItem key={i}>{label}</S.LoanTermItem>
        ))}
        <S.LoanTermItem isTitle>
          <FormattedMessage id="app.common.total" defaultMessage="Total" />
        </S.LoanTermItem>
      </S.LoanTermColumn>
      <S.LoanTermColumn>
        <S.LoanTermItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.localCurrency"
            defaultMessage="Belopp"
          />
        </S.LoanTermItem>
        {loanTermMetrics.map((metric, i) => (
          <S.LoanTermItem key={i}>
            {formatNumber(metricsData[metric].amount, {
              style: "currency",
            })}
          </S.LoanTermItem>
        ))}
        <S.LoanTermItem isTitle>
          {formatNumber(metricsData.totalAmount, {
            style: "currency",
          })}
        </S.LoanTermItem>
      </S.LoanTermColumn>
      <S.LoanTermColumn>
        <S.LoanTermItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.quantity"
            defaultMessage="Antal"
          />
        </S.LoanTermItem>
        {loanTermMetrics.map((metric, i) => (
          <S.LoanTermItem key={i}>{metricsData[metric].count}</S.LoanTermItem>
        ))}
        <S.LoanTermItem isTitle>{metricsData.totalCount}</S.LoanTermItem>
      </S.LoanTermColumn>
    </S.LoanTermContainer>
  )
}

export default LoanTerm
