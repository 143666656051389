import { InvestmentGradeType } from "Interfaces/investmentServicesInterfaces"
import * as S from "./LoanGrade.styles"
import { LoanGradeProps, SizeType } from "./LoanGrade.types"

const grades: Record<InvestmentGradeType, string> = {
  A: "var(--grade-a-bg)",
  B: "var(--grade-b-bg)",
  C: "var(--grade-c-bg)",
  D: "var(--grade-d-bg)",
  E: "var(--grade-e-bg)",
  F: "var(--grade-f-bg)",
}

const sizes: Record<SizeType, string> = {
  small: "26px",
  medium: "40px",
}

export const LoanGrade = ({ grade, size = "medium" }: LoanGradeProps) => {
  return (
    <S.Grade
      size={sizes[size]}
      grade={grade && grades[grade] ? grades[grade] : "var(--grade-default-bg)"}
    >
      {grade}
    </S.Grade>
  )
}
