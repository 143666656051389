import { useState, FC } from "react"
import { FormattedMessage } from "react-intl"

import Input from "UI/Input"
import { SWISH_QR_CODE } from "Constants/externalLinks"
import type { SwishQRCodeProps } from "../DepositOptions.types"
import * as S from "../DepositOptions.styles"

const SwishQRCode: FC<SwishQRCodeProps> = ({ productNumber }) => {
  const [swishQRInputAmount, setSwishQRInputAmount] = useState(0)

  return (
    <S.OptionsContent>
      <S.DepositOptionHeading>
        <FormattedMessage
          id="app.depositAccordion.swish.qrCode.body.heading"
          defaultMessage="Sätt in pengar med Swish QR Kod"
        />
      </S.DepositOptionHeading>
      <S.DepositOptionDescription>
        <FormattedMessage
          id="app.depositAccordion.swish.qrCode.body.description"
          defaultMessage="Ange det belopp du vill sätta in och skanna QR-koden. Insättningar tar vanligtvis 1 arbetsdag."
        />
      </S.DepositOptionDescription>
      <S.OptionsContainer>
        <img
          src={SWISH_QR_CODE.replace(
            ":amount",
            swishQRInputAmount === 0 ? "" : swishQRInputAmount.toString()
          ).replace(":productNumber", productNumber.toString())}
          alt="Swish QR Code"
        />
        <S.InputWrap>
          <span>
            <FormattedMessage
              id="app.depositAccordion.swish.qrCodeExplaination"
              defaultMessage="Scanna QR-koden med din Swish-app i mobilen"
            />
          </span>
          <Input
            placeholder={
              <FormattedMessage
                id="app.depositAccordion.inputs.placeholder"
                defaultMessage="Ange belopp i kr"
              />
            }
            name="amountQRCode"
            type="number"
            onChange={(e) => setSwishQRInputAmount(+e.target.value)}
          />
        </S.InputWrap>
      </S.OptionsContainer>
    </S.OptionsContent>
  )
}

export default SwishQRCode
