import { useState, useMemo, FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import IconFactory from "UI/IconFactory"
import Checkbox from "UI/Checkbox"
import AssetsInput from "../AssetsInput"
import { MobileRowPropsType } from "./MobileRow.types"
import { determineInvestmentStatus } from "../helpers"
import * as S from "../AssetsTable.styles"

const MobileRowComponent: FC<MobileRowPropsType> = ({
  assets,
  isSelling,
  sellableAssets,
  updateSellableAssets,
  setSellableAssets,
  isEnded,
}) => {
  const [showMobileRow, setShowMobileRow] = useState(false)
  const intl = useIntl()
  const isChecked = useMemo(
    () =>
      !!sellableAssets.find(
        (item) => item.loan_uid === assets.uid && item.checked
      ),
    [assets.uid, sellableAssets]
  )

  return (
    <S.MobileAssetsTableWrap isSelling={isSelling}>
      <S.AssetsTableItem>{assets.loan_number}</S.AssetsTableItem>
      {!isSelling && (
        <S.AssetsTableItem>
          {determineInvestmentStatus(assets.investment_status)}
        </S.AssetsTableItem>
      )}
      <S.AssetsTableItem>
        <S.Grade grade={assets.grade}>{assets.grade}</S.Grade>
      </S.AssetsTableItem>
      {isSelling && (
        <S.AssetsTableItem>
          <label>
            <Checkbox
              size="large"
              name={assets.uid}
              value={assets.uid}
              color="white"
              checked={isChecked}
              onChange={() => updateSellableAssets(assets)}
            />
          </label>
        </S.AssetsTableItem>
      )}
      {isSelling && (
        <S.AssetsTableItem>
          {isSelling && (
            <AssetsInput
              numberOfLoanAssets={assets.number_of_loan_assets}
              uid={assets.uid}
              sellableAssets={sellableAssets}
              setSellableAssets={setSellableAssets}
            />
          )}
        </S.AssetsTableItem>
      )}
      <S.AssetsTableItem chevron>
        <S.ExpandButton
          onClick={() => setShowMobileRow(!showMobileRow)}
          show={showMobileRow}
        >
          <IconFactory name="chevronRight" />
        </S.ExpandButton>
      </S.AssetsTableItem>
      <S.MobileRowWrap show={showMobileRow} mobile>
        <S.MobileRow>
          <div>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.term"
                  defaultMessage="Löptid"
                />
                :
              </strong>{" "}
              {assets.loan_term}
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.loanInterestRate"
                  defaultMessage="Utlåningsränta"
                />
                :
              </strong>{" "}
              {intl.formatNumber(+assets.loan_interest_rate, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: "percent",
              })}
            </S.MobileRowItem>
            <S.MobileRowItem>
              <strong>
                <FormattedMessage
                  id="app.mypages.investments.assets.assetsTable.tableHeaders.amountOfLoanShares"
                  defaultMessage="Antal låneandelar"
                />
                :
              </strong>{" "}
              {assets.number_of_loan_assets}
            </S.MobileRowItem>
            {!isEnded && (
              <S.MobileRowItem>
                <strong>
                  <FormattedMessage
                    id="app.mypages.investments.assets.assetsTable.tableHeaders.amountToSell"
                    defaultMessage="Antal utlagt till försäljning"
                  />
                  :
                </strong>{" "}
                {assets.secondary_market_listings[0].number_of_listed_assets}
              </S.MobileRowItem>
            )}
            <S.MobileRowItem>
              <strong>
                {isSelling ? (
                  <FormattedMessage
                    id="app.mypages.investments.assets.assetsTable.tableHeaders.amontLoanedOut"
                    defaultMessage="Utlånat"
                  />
                ) : (
                  <FormattedMessage
                    id="app.mypages.investments.assets.assetsTable.tableHeaders.bookValue"
                    defaultMessage="Bokfört värde"
                  />
                )}
                :
              </strong>{" "}
              {intl.formatNumber(+assets.nominal_value, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </S.MobileRowItem>
            {!isSelling && (
              <S.MobileRowItem>
                <strong>
                  <FormattedMessage
                    id="app.mypages.investments.assets.assetsTable.tableHeaders.repayed"
                    defaultMessage="Återbetalat"
                  />
                  :
                </strong>{" "}
                {intl.formatNumber(
                  parseFloat(assets.interest_paid_to_date) +
                    parseFloat(assets.principal_paid_to_date),
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </S.MobileRowItem>
            )}
          </div>
        </S.MobileRow>
      </S.MobileRowWrap>
    </S.MobileAssetsTableWrap>
  )
}

export default MobileRowComponent
