export const BROCC_LANDING = "https://brocc.se"
export const BROCC_LANDING_LOGIN = `${BROCC_LANDING}/login`
export const BROCC_LANDING_SAVINGS = `${BROCC_LANDING}/spara`
export const BROCC_LANDING_INVESTMENTS = `${BROCC_LANDING}/investera`
export const BROCC_LANDING_ABOUT = `${BROCC_LANDING}/om-brocc`
export const BROCC_LANDING_MY_PAGES = `${BROCC_LANDING}/mina-sidor`
export const BROCC_LANDING_LOAN = `${BROCC_LANDING}/lana#loan`
export const BROCC_LANDING_SECURITY_INSURANCE = `${BROCC_LANDING}/lana/trygghetsforsakring`
export const BROCC_LANDING_TERMS_AND_INFO = `${BROCC_LANDING}/villkor-och-information#dokument-och-produktinformation`
export const BROCC_LANDING_INTEGRITY = `${BROCC_LANDING}/brocc-docs/integrity-policy`

export const BROCC_TEST_ONBOARDING = "https://app.test.brocc.se/onboarding/:uid"
export const BROCC_ONBOARDING = "https://app.brocc.se/onboarding/:uid"

export const SWISH_QR_CODE =
  "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=C1230401943%3B:amount%3B:productNumber%3B20&choe=UTF-8"

export const BROCC_SUPPORT = "https://brocc.se/kontakta-oss"
export const BANKID_SUPPORT = "https://support.bankid.com/sv"

export const BROCC_LOAN_REJECTED =
  "https://www.ordnabolan.se/privatlan/?utm_source=brocc&utm_medium=referral&utm_campaign=brocc"

export const TINK_LINK = "https://link.tink.com"
export const TINK_LINK_ACCOUNT_CHECK = `${TINK_LINK}/1.0/:tinkType/?:tinkParams`
