import { Dispatch, SetStateAction, useState } from "react"
import RiskClassFilter from "Components/AssetsFilters/RiskClassFilter"
import StatusFilter from "Components/AssetsFilters/StatusFilter"
import TermFilter from "Components/AssetsFilters/TermFilter"
import Modal from "UI/Modal"
import { useIntl } from "react-intl"
import Button from "UI/Button"
import { initialFilterValues } from "../config"
import * as S from "./FilterModal.styles"
import { HoldingsFilters } from "../Holdings.types"

type FilterModalProps = {
  closeModal: () => void
  filters: HoldingsFilters
  setFilters: Dispatch<SetStateAction<HoldingsFilters>>
}

const FiltersModal = ({
  closeModal,
  filters,
  setFilters,
}: FilterModalProps) => {
  const intl = useIntl()
  const [riskClass, setRiskClass] = useState(filters.riskClass)
  const [termDuration, setTermDuration] = useState(filters.termDuration)
  const [status, setStatus] = useState(filters.status)

  const applyFilters = () => {
    setFilters({
      riskClass,
      termDuration,
      status,
    })
    closeModal()
  }

  const resetFilters = () => {
    setRiskClass(initialFilterValues.riskClass)
    setTermDuration(initialFilterValues.termDuration)
    setStatus(initialFilterValues.status)
    setFilters(initialFilterValues)
    closeModal()
  }

  const isClearButtonDisabled =
    initialFilterValues.riskClass === riskClass &&
    initialFilterValues.termDuration === termDuration &&
    initialFilterValues.status === status

  const isApplyButtonDisabled =
    filters.riskClass === riskClass &&
    filters.termDuration === termDuration &&
    filters.status === status

  return (
    <Modal
      maxWidth="500px"
      onClick={closeModal}
      title={intl.formatMessage({
        id: "app.common.filters",
        defaultMessage: "Filtrera",
      })}
      body={
        <>
          <S.FiltersWrap>
            <RiskClassFilter
              riskClass={riskClass}
              setRiskClass={setRiskClass}
            />
            <TermFilter
              termDuration={termDuration}
              setTermDuration={setTermDuration}
            />
            <StatusFilter status={status} setStatus={setStatus} />
          </S.FiltersWrap>
          <S.ButtonsWrap>
            <Button
              disabled={isClearButtonDisabled}
              onClick={resetFilters}
              variant="inverse"
            >
              {intl.formatMessage({
                id: "app.common.clear",
                defaultMessage: "Rensa",
              })}
            </Button>

            <Button disabled={isApplyButtonDisabled} onClick={applyFilters}>
              {intl.formatMessage({
                id: "app.common.apply",
                defaultMessage: "Tillämpa",
              })}
            </Button>
          </S.ButtonsWrap>
        </>
      }
    />
  )
}

export default FiltersModal
