import KYCStep from "./KYCStep"
import Intro from "./Intro"
import Deposit from "./Deposit"
import InvestmentProfile from "./InvestmentProfile"
import {
  InvestmentsStepsType,
  StepsTitlesType,
} from "./InvestmentOnboarding.types"

export const STEPS: InvestmentsStepsType = {
  0: Intro,
  1: KYCStep,
  2: InvestmentProfile,
  3: Deposit,
}

export const totalSteps = Object.keys(STEPS).length - 1

export const STEP_TITLES: StepsTitlesType = {
  1: {
    id: "app.mypages.investments.onboarding.title.step2",
    defaultMessage: "Kundkännedom",
  },
  2: {
    id: "app.mypages.investments.onboarding.title.step3",
    defaultMessage: "Välj investeringsprofil",
  },
  3: {
    id: "app.mypages.investments.onboarding.title.step4",
    defaultMessage: "Gör en insättning",
  },
}
