import { FC, memo } from "react"
import { FormattedMessage } from "react-intl"

import { LoanGrade } from "Components/LoanGrade/LoanGrade"
import { InvestmentGradeType } from "Interfaces/investmentServicesInterfaces"
import { formatNumber } from "Utils/formatNumber"
import { RiskClassMetrics, RiskClassProps } from "./RiskClass.types"
import * as S from "./RiskClass.styles"

const loanGrades: InvestmentGradeType[] = ["A", "B", "C", "D", "E", "F"]

const RiskClass: FC<RiskClassProps> = ({ data }) => {
  const metricsData = data.reduce(
    (res, item) => {
      res[item.label as InvestmentGradeType] = {
        count: item.count,
        amount: item.amount,
      }
      res.totalCount += item.count
      res.totalAmount += item.amount
      return res
    },
    {
      A: { amount: 0, count: 0 },
      B: { amount: 0, count: 0 },
      C: { amount: 0, count: 0 },
      D: { amount: 0, count: 0 },
      E: { amount: 0, count: 0 },
      F: { amount: 0, count: 0 },
      totalCount: 0,
      totalAmount: 0,
    } as RiskClassMetrics
  )

  if (data.length === 0) {
    return (
      <S.RiskClassContainer empty>
        <FormattedMessage
          id="app.mypages.investments.assets.riskOverview.noAssets"
          defaultMessage="Inga innehav att visa"
        />
      </S.RiskClassContainer>
    )
  }

  return (
    <S.RiskClassContainer data-testid="risk-class">
      <S.RiskClassColumn>
        <S.RiskClassItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.riskClass.class"
            defaultMessage="Klass"
          />
        </S.RiskClassItem>
        {loanGrades.map((grade) => (
          <S.RiskClassItem key={grade}>
            <LoanGrade grade={grade} size="small" />
          </S.RiskClassItem>
        ))}
        <S.RiskClassItem isTitle>
          <FormattedMessage id="app.common.total" defaultMessage="Total" />
        </S.RiskClassItem>
      </S.RiskClassColumn>
      <S.RiskClassColumn>
        <S.RiskClassItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.localCurrency"
            defaultMessage="Belopp"
          />
        </S.RiskClassItem>
        {loanGrades.map((grade) => (
          <S.RiskClassItem key={grade}>
            {formatNumber(metricsData[grade].amount, { style: "currency" })}
          </S.RiskClassItem>
        ))}
        <S.RiskClassItem isTitle>
          {formatNumber(metricsData.totalAmount, { style: "currency" })}
        </S.RiskClassItem>
      </S.RiskClassColumn>
      <S.RiskClassColumn>
        <S.RiskClassItem isTitle>
          <FormattedMessage
            id="app.mypages.investments.assets.riskOverview.riskClass.quantity"
            defaultMessage="Antal"
          />
        </S.RiskClassItem>
        {loanGrades.map((grade) => (
          <S.RiskClassItem key={grade}>
            <S.RiskClassItem>{metricsData[grade].count}</S.RiskClassItem>
          </S.RiskClassItem>
        ))}
        <S.RiskClassItem isTitle>{metricsData.totalCount}</S.RiskClassItem>
      </S.RiskClassColumn>
    </S.RiskClassContainer>
  )
}

export default memo(RiskClass)
