import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

export const FiltersWrap = styled.div`
  margin: 1em 0;
`

export const ButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1em;
  margin-bottom: 1em;

  > button {
    width: 100%;
  }

  @media screen and (min-width: ${mediaQueries.small}) {
    grid-template-columns: auto auto;

    > button {
      width: auto;
    }
  }
`
