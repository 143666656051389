import styled from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const HeadersWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--light-gray);
`

export const GraphHeaders = styled.div`
  font-weight: bold;
  font-size: ${font.size.lg};
  padding-bottom: 0.65em;
  cursor: pointer;
  @media screen and (max-width: ${mediaQueries.medium}) {
    line-height: 24px;
    font-size: ${font.size.base};
  }
`

export const LoadingErrorContainer = styled.div`
  height: 180px;
  justify-content: center;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${mediaQueries.medium}) {
    padding: 4em 0;
  }
`

export const SelectWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  select {
    margin: 0;
    width: 70%;
    padding-right: 25px;
    @media screen and (max-width: ${mediaQueries.medium}) {
      width: 100%;
    }
  }
`
