import { FC, useMemo } from "react"
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts"
import { FormattedMessage, useIntl } from "react-intl"
import { useResizeObserver } from "Hooks/UI/useResizeObserber"
import { tooltipProps } from "../defaultProps"
import {
  DivergingBarChartPropTypes,
  GraphDataType,
} from "./DivergingBarChart.types"
import { DivergingChartWrapper } from "./DivergingBarChart.styles"

const DivergingBarChart: FC<DivergingBarChartPropTypes> = ({ data }) => {
  const intl = useIntl()
  const { parentRef, content } = useResizeObserver()

  const maleEntries = Object.entries(data.male)
  const femaleValues = Object.values(data.female)

  const graphData: GraphDataType[] = useMemo(
    () =>
      maleEntries
        .map((item, i) => {
          const add = {
            age: item[0],
            male: item[1],
            female: femaleValues[i] > 0 ? -femaleValues[i] : 0,
          }

          return add
        })
        .reverse(),
    [femaleValues, maleEntries]
  )

  const hasData = useMemo(
    () => graphData.filter((item) => item.male > 0 && item.female < 0),
    [graphData]
  )

  const legendNames = {
    female: intl.formatMessage({
      id: "app.common.female",
      defaultMessage: "Kvinnor",
    }),
    male: intl.formatMessage({
      id: "app.common.male",
      defaultMessage: "Män",
    }),
  }

  if (hasData.length === 0) {
    return (
      <DivergingChartWrapper isError>
        <FormattedMessage
          id="app.mypages.error.investment.charts"
          defaultMessage="Det finns för närvarande ingenting att visa."
        />
      </DivergingChartWrapper>
    )
  }

  return (
    <DivergingChartWrapper data-testid="diverging-chart" ref={parentRef}>
      <BarChart
        height={content.height}
        width={content.width}
        data={graphData}
        layout="vertical"
        barGap="-98%"
        barCategoryGap={0}
        style={{
          fontSize: "12px",
          paddingTop: "10px",
        }}
        margin={{
          top: 0,
          right: 10,
          left: -10,
          bottom: 0,
        }}
      >
        <XAxis
          type="number"
          domain={[-100, 100]}
          axisLine={false}
          tickLine={false}
          tickFormatter={(v) => `${v < 0 ? -v : v}%`}
        />
        <YAxis
          type="category"
          dataKey="age"
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          formatter={(value, name: "male" | "female") => [
            `${value < 0 ? -value : +value}%`,
            legendNames[name],
          ]}
          labelFormatter={(label) =>
            `${intl.formatMessage({
              id: "app.common.age",
              defaultMessage: "Ålder",
            })} ${label}`
          }
          {...tooltipProps}
        />
        <Legend
          iconSize={0}
          verticalAlign="top"
          wrapperStyle={{ maxWidth: "100%", width: "100%" }}
          formatter={(name: "female" | "male") => legendNames[name]}
        />
        <Bar dataKey="female" fill="#F9D682" layout="horizontal" />
        <Bar dataKey="male" fill="#8FBBAF" layout="horizontal" />
      </BarChart>
    </DivergingChartWrapper>
  )
}

export default DivergingBarChart
