import { useMemo } from "react"
import { useIntl } from "react-intl"
import {
  PROFILE_CLASS,
  PROFILE_TERM,
  PROFILE_PREFERANCE,
} from "Constants/dataStrings"

export const termGroupOptions = [
  {
    id: 1,
    name: PROFILE_TERM.SHORT,
    value: PROFILE_TERM.SHORT,
  },
  {
    id: 2,
    name: PROFILE_TERM.MEDIUM,
    value: PROFILE_TERM.MEDIUM,
  },
]

export const useReinvestmentGroupOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 1,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.reinvestAllProceeds",
          defaultMessage: "Återinvesterande",
        }),
        description: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.reinvestAllProceeds.description",
          defaultMessage:
            "Räntor och amorteringar återinvesteras löpande i nya lån",
        }),
        value: PROFILE_PREFERANCE.REINVEST_ALL,
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.reinvestOnlyAmortizations",
          defaultMessage: "Ränteutbetalande",
        }),
        description: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.reinvestOnlyAmortizations.description",
          defaultMessage:
            "Räntor utbetalas månadsvis samtidigt som amorteringar återinvesterar löpande i nya lån",
        }),
        value: PROFILE_PREFERANCE.REINVEST_AMORTIZATIONS,
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.payoutAllProceeds",
          defaultMessage: "Utbetalande",
        }),
        description: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.reinvestmentPreferences.select.option.payoutAllProceeds.description",
          defaultMessage: "Räntor och amorteringar utbetalas månadsvis",
        }),
        value: PROFILE_PREFERANCE.PAYOUT_ALL,
      },
    ],
    [intl]
  )
}

export const useClassGroupOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 1,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.classGroupOptions.conservative",
          defaultMessage: "Konservativ",
        }),
        value: PROFILE_CLASS.CONSERVATIVE,
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.classGroupOptions.balanced",
          defaultMessage: "Balanserad",
        }),
        value: PROFILE_CLASS.BALANCED,
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "app.mypages.savings.onboarding.investmentProfile.classGroupOptions.progressive",
          defaultMessage: "Progressiv",
        }),
        value: PROFILE_CLASS.PROGRESSIVE,
      },
    ],
    [intl]
  )
}
