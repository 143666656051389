import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query"
import {
  getInvestmentServices,
  getInvestmentServiceByID,
  createTrustlyDeposit,
  getInvestmentServiceSettings,
  updateReinvestmentPreferences,
  updateInvestmentProfile,
  getInvestmentServiceSummary,
  getPortfolioMetrics,
  getDemographicMetrics,
  getUpcomingRepaymentPlans,
  getInvestmentAssets,
  getSecondaryAssets,
  getAccountBalancesMetrics,
  getInvestmentAggregate,
  getTransactions,
  listAssets,
  unlistAssets,
  postInvestmentWithdrawalAmount,
  TermDurationType,
  AccountBalancesMetricsType,
  AccountBalanceTimeSpanType,
  InvestmentProfilePayloadType,
  MetricsGroupByType,
  TrustlyDepositPayloadType,
  getInvestmentCurrentBalance,
} from "ApiServices/investmentServices"
import {
  IInvestmentAsset,
  IInvestmentAssets,
  IInvestmentProfile,
  IInvestmentServiceSettings,
  InvestmentAssestsParams,
  InvestmentGradeType,
  LoanPerfomanceStatusType,
  ReinvestmentPreferenceType,
} from "Interfaces/investmentServicesInterfaces"
import { AxiosError } from "axios"
import {
  INVESTMENT_SERVICES_ACCOUNT_BALANCES_METRICS,
  INVESTMENT_SERVICES_CURRENT_BALANCE,
  INVESTMENT_SERVICES_DATA,
  INVESTMENT_SERVICES_DEMOGRAPHICS,
  INVESTMENT_SERVICES_INVESTMENTS,
  INVESTMENT_SERVICES_INVESTMENTS_AGGREGATE,
  INVESTMENT_SERVICES_PORTFILIOS,
  INVESTMENT_SERVICES_SECONDARY_MARKET_LISTINGS,
  INVESTMENT_SERVICES_SERVICE,
  INVESTMENT_SERVICES_SETTINGS,
  INVESTMENT_SERVICES_SUMMARY,
  INVESTMENT_SERVICES_TRANSACTIONS,
  INVESTMENT_SERVICES_UPCOMING_REPAYMENT_PLANS,
} from "Constants/queryKeys"

type TrustlyDepositErrorType = {
  errors: {
    amount: string
  }
}

type TrustlyDepositResponseData = {
  order_id: number
  url: string
}

const determineTermDuration = (duration?: number): TermDurationType => {
  switch (duration) {
    case 24:
      return { loan_term_gte: 0, loan_term_lte: 24 }
    case 48:
      return { loan_term_gte: 24, loan_term_lte: 48 }
    case 72:
      return { loan_term_gte: 48, loan_term_lte: 72 }
    case 96:
      return { loan_term_gte: 72, loan_term_lte: 96 }
    case 120:
      return { loan_term_gte: 96, loan_term_lte: 120 }
    case 130:
      return { loan_term_gte: 120 }
    default:
      return {}
  }
}

export function useInvestmentServices(enabled = true) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_DATA],
    queryFn: getInvestmentServices,
    retry: 0,
    enabled,
  })
}

export const useInvestmentServiceByIDQuery = (
  accountID: string,
  enabled = true
) => {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_SERVICE, accountID],
    queryFn: () => getInvestmentServiceByID(accountID),
    enabled,
    retry: 0,
  })
}

export const useCreateTrustlyDeposit = () =>
  useMutation<
    TrustlyDepositResponseData,
    AxiosError<TrustlyDepositErrorType>,
    {
      uid: string
      payload: TrustlyDepositPayloadType
    }
  >(createTrustlyDeposit)

export function useGetInvestmentServiceSettings(uid: string) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_SETTINGS, uid],
    queryFn: () => getInvestmentServiceSettings(uid),
  })
}

export const useUpdateReinvestmentPreferences = (isOnboarding = false) => {
  const queryClient = useQueryClient()
  return useMutation<
    IInvestmentServiceSettings,
    unknown,
    {
      uid: string
      payload: { reinvestment_preference: ReinvestmentPreferenceType }
    }
  >({
    mutationFn: ({ uid, payload }) =>
      updateReinvestmentPreferences(uid, payload),
    onSuccess: () => {
      if (!isOnboarding) {
        queryClient.invalidateQueries([INVESTMENT_SERVICES_SETTINGS])
      }
    },
  })
}

export const useUpdateInvestmentProfile = (
  id: string,
  isOnboarding = false
) => {
  const queryClient = useQueryClient()
  return useMutation<
    IInvestmentProfile,
    unknown,
    {
      uid: string
      payload: InvestmentProfilePayloadType
    }
  >({
    mutationFn: ({ uid, payload }) => updateInvestmentProfile(uid, payload),
    onSuccess: () => {
      if (!isOnboarding) {
        queryClient.invalidateQueries([INVESTMENT_SERVICES_SERVICE, id])
      }
    },
  })
}

export function useGetInvestmentServiceSummary(uid: string) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_SUMMARY, uid],
    queryFn: () => getInvestmentServiceSummary(uid),
  })
}

export function useGetPortfolioMetrics(
  uid: string,
  params: MetricsGroupByType
) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_PORTFILIOS, uid, params],
    queryFn: () => getPortfolioMetrics(uid, params),
  })
}

export function useGetDemographicMetrics(uid: string) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_DEMOGRAPHICS, uid],
    queryFn: () => getDemographicMetrics(uid),
  })
}

export function useGetInvestmentAggregate(
  uid: string,
  activeInvestment = true
) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_INVESTMENTS_AGGREGATE, uid],
    queryFn: () => {
      const params = {
        active: Number(activeInvestment),
      }
      return getInvestmentAggregate(uid, params)
    },
    retry: 1,
  })
}

export function useGetUpcomingRepaymentPlans(uid: string) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_UPCOMING_REPAYMENT_PLANS, uid],
    queryFn: () => getUpcomingRepaymentPlans(uid),
    retry: 1,
  })
}

export function useGetInvestmentAssets(
  uid: string,
  riskClass?: string,
  termDuration?: number,
  status?: string
) {
  return useInfiniteQuery<IInvestmentAssets, unknown, IInvestmentAsset>({
    queryKey: [
      INVESTMENT_SERVICES_INVESTMENTS,
      uid,
      riskClass,
      termDuration,
      status,
    ],
    queryFn: ({ pageParam = 1 }) => {
      const loanPerformanceStatuses =
        status && status !== "-" ? status.split(" ") : undefined
      const duration = determineTermDuration(termDuration)
      const params: InvestmentAssestsParams = {
        loan_performance_statuses:
          loanPerformanceStatuses as LoanPerfomanceStatusType[],
        grade:
          riskClass === "-" ? undefined : (riskClass as InvestmentGradeType),
        page: pageParam,
        ...duration,
      }
      return getInvestmentAssets(uid, params)
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.last_page === lastPage.meta.current_page) {
        return undefined
      }
      return lastPage.meta.current_page + 1 ?? undefined
    },
    select: (data) => {
      return {
        ...data,
        pages: data.pages.flatMap((page) => page.data),
      }
    },
  })
}

export function useGetAccountBalancesMetrics(
  uid: string,
  type = "account_balance" as AccountBalancesMetricsType,
  timeSpan = "1_month" as AccountBalanceTimeSpanType
) {
  return useQuery({
    queryKey: [
      INVESTMENT_SERVICES_ACCOUNT_BALANCES_METRICS,
      uid,
      type,
      timeSpan,
    ],
    queryFn: () => getAccountBalancesMetrics(uid, type, timeSpan),
  })
}

export function useGetTransactions(uid: string) {
  return useInfiniteQuery({
    queryKey: [INVESTMENT_SERVICES_TRANSACTIONS, uid],
    queryFn: ({ pageParam = 0 }) => getTransactions(uid, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.last_page === lastPage.meta.current_page) {
        return undefined
      }
      return lastPage.meta.current_page + 1 ?? undefined
    },
  })
}

export const useListAssets = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: listAssets,
    onSettled: () =>
      queryClient.invalidateQueries([
        INVESTMENT_SERVICES_SECONDARY_MARKET_LISTINGS,
      ]),
  })
}

export const useUnListAssets = () => useMutation(unlistAssets)

export function useGetSecondaryAssets(uid: string) {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_SECONDARY_MARKET_LISTINGS],
    queryFn: () => getSecondaryAssets(uid),
    retry: 1,
    refetchInterval: 2 * 60 * 1000,
    refetchIntervalInBackground: true,
  })
}

export const usePostInvestmentWithdrawalAmount = (uid: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postInvestmentWithdrawalAmount,
    onSuccess: () =>
      queryClient.invalidateQueries([INVESTMENT_SERVICES_CURRENT_BALANCE, uid]),
  })
}

export const useGetInvestmentCurrentBalance = (uid: string) => {
  return useQuery({
    queryKey: [INVESTMENT_SERVICES_CURRENT_BALANCE, uid],
    queryFn: () => getInvestmentCurrentBalance(uid),
  })
}

export const getInvestmentServiceByIDQueryData = (accountID: string) => ({
  queryKey: [INVESTMENT_SERVICES_SERVICE, accountID],
  queryFn: () => getInvestmentServiceByID(accountID),
})
