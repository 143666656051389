import type {
  IDepositService,
  IDepositServiceProfiles,
  IDepositServices,
  IDepositSignatureStatus,
  IDepositTransactions,
  IBankPaymentRequest,
  PrepareAgreementSignature,
  IDepositReviewStatus,
  ICreateDepositApplication,
} from "Interfaces/depositInterfaces"
import axios from "ApiServices/axios"
import { Api } from "Constants/api"
import type { AxiosError, AxiosResponse } from "axios"

export const getDepositServices = async () => {
  try {
    const { data } = await axios.get<IDepositServices>(Api.DepositServices)
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositServiceByID = async (uid: string) => {
  try {
    const { data } = await axios.get<IDepositService>(
      Api.DepositService.replace(":id", uid)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositServiceTransactions = async (
  uid: string,
  page: number
) => {
  try {
    const { data } = await axios.get<IDepositTransactions>(
      Api.DepositServiceTransactionds.replace(":id", uid),
      {
        params: {
          page,
        },
      }
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export interface PostWithdrawalAmountOptions {
  uid: string
  amount: number
  toDepositServiceId: string | null
}

export const postDepositWithdrawalAmount = async ({
  uid,
  amount,
  toDepositServiceId,
}: PostWithdrawalAmountOptions) => {
  try {
    const { data } = await axios.post<{ reserved_amount: number }>(
      Api.DepositServiceWithdrawals.replace(":id", uid),
      {
        amount,
        to_deposit_service_uid: toDepositServiceId,
      }
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createBankPaymentRequest = async (uid: string, amount: number) => {
  try {
    const { data } = await axios.post<
      IBankPaymentRequest,
      AxiosResponse<IBankPaymentRequest>,
      { amount: number }
    >(Api.DepositServiceBankPayments.replace(":id", uid), {
      amount,
    })
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const processBankPaymentRequest = async ({
  uid,
  requestID,
}: {
  uid: string
  requestID: string
}) => {
  try {
    const { data } = await axios.put(
      Api.DepositServiceBankPayment.replace(":id", uid).replace(
        ":requestId",
        requestID
      )
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateDepositServiceName = async (
  uid: string,
  depositName: string
) => {
  try {
    const { data } = await axios.patch<
      IDepositService,
      AxiosResponse<IDepositService>,
      { name: string }
    >(Api.DepositService.replace(":id", uid), { name: depositName })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const postDirectDebitAmount = (uid: string, amount: number) => {
  try {
    return axios.post(
      Api.DepositServiceDirectDebitPayment.replace(":id", uid),
      {
        amount,
      }
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const stopMonthlySaving = (uid: string) => {
  try {
    return axios.delete(Api.DepositServiceMonthlySaving.replace(":id", uid))
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createDepositAgreementSignature = async (uid: string) => {
  try {
    const { data } = await axios.post<
      PrepareAgreementSignature,
      AxiosResponse,
      { use_new_version: boolean }
    >(Api.DepositServiceSignatures.replace(":id", uid), {
      use_new_version: true,
    })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositSignatureStatus = async (
  uid: string,
  signatureUid: string
) => {
  try {
    const { data } = await axios.get<IDepositSignatureStatus>(
      Api.DepositServiceSignatureStatus.replace(":id", uid).replace(
        ":signatureId",
        signatureUid
      )
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositServiceProfiles = async (
  id: string
): Promise<IDepositServiceProfiles> => {
  try {
    const { data } = await axios.get<IDepositServiceProfiles>(
      Api.DepositServiceProfiles.replace(":id", id)
    )
    const flexProfiles = data?.data.filter((item) => item.type === "flex")
    const fixedProfile = data?.data.filter((item) => item.type === "fixed")

    const sortedArray = fixedProfile.sort((a, b) => {
      if (a.term_period && b.term_period) {
        return a.term_period - b.term_period
      }
      return 1
    })

    const sortedProfiles = [...flexProfiles, ...sortedArray]

    return {
      data: sortedProfiles,
    }
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateDepositServicePreferences = ({
  uid,
  payload,
}: {
  uid: string
  payload: { profile_id: number }
}) => {
  try {
    return axios.put(Api.DepositServicePreferences.replace(":id", uid), payload)
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const saveSelectedBankAccount = async (
  uid: string,
  reportId: string
) => {
  try {
    const { data } = await axios.post(
      Api.DepositServiceBankAccounts.replace(":id", uid),
      { account_verification_report_id: reportId }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositAgreementPdf = async (id: string) => {
  try {
    const { data } = await axios.get<Blob>(
      Api.DepositServiceAgreementPdf.replace(":id", id),
      { responseType: "blob" }
    )

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const getDepositReviewStatus = async (id: string) => {
  try {
    const { data } = await axios.get<IDepositReviewStatus>(
      Api.DepositServiceReviewStatus.replace(":id", id)
    )
    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateDepositInitialAmount = async ({
  uid,
  amount,
}: {
  uid: string
  amount: number
}) => {
  try {
    return await axios.patch(
      Api.DepositServiceInitialAmount.replace(":id", uid),
      { amount }
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const updateBankAccountManually = async (
  application: {
    account_number: string
    clearing_number: string
  },
  uid: string
) => {
  try {
    return await axios.post(
      Api.DepositServiceUpdateManualBank.replace(":id", uid),
      application
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const createDepositApplication = async ({
  accept_agreement,
  subscribe_newsletter,
  legal_entity_number,
  email,
  tel,
}: ICreateDepositApplication) => {
  try {
    const { data } = await axios.post(Api.DepositApplications, {
      accept_agreement,
      subscribe_newsletter,
      legal_entity_number,
      email,
      tel,
    })

    return data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const cancelDepositApplicationByID = async (uid: string) => {
  try {
    return await axios.put(
      Api.DepositServiceCancelApplication.replace(":id", uid)
    )
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export const closeDepositAccountByID = async ({
  uid,
  toDepositServiceUid,
}: {
  uid: string
  toDepositServiceUid: string | null
}) => {
  try {
    return await axios.put(Api.DepositServiceCloseAccount.replace(":id", uid), {
      to_deposit_service_uid: toDepositServiceUid,
    })
  } catch (error) {
    throw error as AxiosError
  }
}
