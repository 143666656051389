import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import { useGetInvestmentServiceSummary } from "Hooks/API/useInvestmentServices"
import Loading from "UI/Loading"
import ResultsRealized from "./ResultsRealized"
import ResultsBalance from "./ResultsBalance"
import * as S from "./Results.styles"

const Results = () => {
  const { accountID } = useParams() as { accountID: string }
  const summary = useGetInvestmentServiceSummary(accountID)

  return (
    <S.ResultsWrap>
      {summary.isLoading && (
        <S.LoadingContainer data-testid="loader">
          <Loading />
        </S.LoadingContainer>
      )}
      {summary.isSuccess && summary.data && (
        <S.OverviewBoxes>
          <ResultsRealized accountID={accountID} />
          <ResultsBalance accountID={accountID} />
        </S.OverviewBoxes>
      )}

      {summary.isError && (
        <FormattedMessage
          id="app.mypages.error.investment.getInvestmentSummary"
          defaultMessage="Det går inte att hämta sammanfattande mätvärden. Försök igen"
        />
      )}
    </S.ResultsWrap>
  )
}

export default Results
