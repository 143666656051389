export enum EnvKeysEnum {
  AppVersion = "REACT_APP_VERSION",
  AppName = "REACT_APP_NAME",
  AppGtmId = "REACT_APP_GTM_ID",
  BroccAuthApi = "REACT_APP_BROCC_AUTH_API",
  BroccApi = "REACT_APP_BROCC_API",
  BroccAuthClientId = "REACT_APP_BROCC_AUTH_CLIENT_ID",
  CypressRecordKey = "CYPRESS_RECORD_KEY",
  DomainName = "REACT_APP_DOMAIN_NAME",
  InstantorProductKey = "REACT_APP_INSTANTOR_PRODUCT_KEY",
  InstantorEnv = "REACT_APP_INSTANTOR_ENV",
  MixpanelProjectToken = "REACT_APP_MIXPANEL_PROJECT_TOKEN",
  SentryApi = "REACT_APP_SENTRY_API",
  SentryAuthToken = "SENTRY_AUTH_TOKEN",
  SentryDsn = "REACT_APP_SENTRY_DSN",
  TinkClientId = "REACT_APP_TINK_CLIENT_ID",
  TinkPaymentClientId = "REACT_APP_TINK_PAYMENT_CLIENT_ID",
  TinkReportingClientId = "REACT_APP_TINK_REPORTING_CLIENT_ID",
  TinkEnvironment = "REACT_APP_TINK_ENVIRONMENT",
  LaunchDarklyClientId = "REACT_APP_LAUNCH_DARKLY_CLIENT_ID",
  Env = "REACT_APP_ENV",
}

export const getEnv = (key: EnvKeysEnum): string => {
  const value = process.env[key]

  if (value === undefined) {
    throw new Error(`Missing: process.env['${key}'].`)
  }

  return value
}
