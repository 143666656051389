import { QueryClient } from "react-query"

type Params = {
  accountID?: string
}

type QueryFunction = (accountID: string) => Query

type Query = {
  queryKey: string[]
}

export const useLoader =
  (queryClient: QueryClient, cb: QueryFunction) =>
  async ({ params }: { params: Params }) => {
    const query = cb(params.accountID ? params.accountID : "")
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    )
  }
