import { FC, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import InvestmentProfileChoices from "Components/InvestmentProfileChoices"
import { INVESTMENT_ONBOARDING } from "Constants/storage"
import {
  getParsedStorageItemOrDefault,
  setStorageItem,
} from "Utils/localStorage"
import { InvestmentOnboardingData } from "../InvestmentOnboarding.types"
import * as S from "./InvestmentProfile.styles"

type InvestmentProfilePropsType = {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const InvestmentProfile: FC<InvestmentProfilePropsType> = ({ setStep }) => {
  const { accountID: id } = useParams() as { accountID: string }

  const investmentOnboardingData =
    getParsedStorageItemOrDefault<InvestmentOnboardingData>(
      INVESTMENT_ONBOARDING,
      {}
    )

  const handleOnboarding = useCallback(() => {
    const updatedStatus: InvestmentOnboardingData = {
      ...investmentOnboardingData,
      [id]: {
        ...investmentOnboardingData[id],
        brocc_has_investment_choices: true,
      },
    }
    setStorageItem(INVESTMENT_ONBOARDING, updatedStatus)
    setStep((prev) => prev + 1)
  }, [id, investmentOnboardingData, setStep])

  return (
    <S.InvestmentProfileContainer>
      <h1>
        <FormattedMessage
          id="app.mypages.savings.onboarding.investmentProfile.header"
          defaultMessage="Välj investeringsprofil"
        />
      </h1>
      <p>
        <FormattedMessage
          id="app.mypages.savings.onboarding.investmentProfile.introParagraph"
          defaultMessage="Investeringsprofilen styr vilka typ av lån du investerar i. Du kan välja
          att anpassa investeringsprofilen utifrån dina personliga önskemål. Om du
          väljer att gå vidare utan att göra något val används de förvalda
          alternativen."
        />
      </p>
      <InvestmentProfileChoices id={id} handleOnboarding={handleOnboarding} />
    </S.InvestmentProfileContainer>
  )
}

export default InvestmentProfile
