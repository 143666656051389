import styled from "styled-components"
import { mediaQueries } from "Styles/styleGlobal"

type BalanceOverviewContainerPropTypes = {
  isMonthlySaving?: boolean
}

export const BalanceOverviewContainerTest = styled.div`
  padding: 0 4em;
  height: 143px;
  @media screen and (max-width: ${mediaQueries.extraLarge}) {
    height: auto;
  }
`

export const BalanceOverviewContainer = styled.div<BalanceOverviewContainerPropTypes>`
  background: ${(props) => props.theme.infoBox};
  border-radius: 20px;
  padding: 3em 0;
  display: flex;
  justify-content: center;
  gap: 0 2em;
  padding: 1em 0;
  width: 100%;
  @media screen and (max-width: ${mediaQueries.large}) {
    align-items: center;
    gap: 0 1em;
    margin-bottom: 2em;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 2em;
  width: 100%;
  padding: 1em 0;
  a {
    min-width: 115px;
    max-height: 52px;
  }
  @media screen and (max-width: ${mediaQueries.large}) {
    align-items: center;
    gap: 0em 1em;
    margin-bottom: 2em;
  }
`
