import { ellipsisText, flex } from "config/mixins"
import styled, { css } from "styled-components"
import { font, mediaQueries } from "Styles/styleGlobal"

export const HoldingsListBlock = styled.div``

export const ListBlockHeader = styled.div`
  ${flex.alignCenterBetween}
  margin: 1em 0 0.5em;
`

export const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--light-gray);
`

export const HoldingsList = styled.ul`
  display: grid;
  row-gap: 1em;
`
export const HoldingsItem = styled.li`
  ${flex.justifyBetween}
  gap: 1em;
  padding: 1em;
  border: 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;
`

export const ItemLeft = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "grade status"
    "grade percent";
  gap: 0.3em 1em;

  > :nth-child(1) {
    grid-area: grade;
    align-self: center;
  }

  > :nth-child(2) {
    font-weight: 500;
    grid-area: status;
    width: 100%;
    ${ellipsisText}
  }

  > :nth-child(3) {
    grid-area: percent;
  }
`
export const ItemRight = styled.div`
  display: grid;
  row-gap: 0.3em;
  justify-items: flex-end;
`

export const NominalValue = styled.p`
  font-weight: 700;
  margin: 0;
  line-height: 1;
`

export const HoldingsListContainer = styled.div<{ isEmpty?: boolean }>`
  border-radius: 20px;
  margin: 0;
  text-align: center;
  &.loading {
    ${flex.columnAlignCenter}
  }

  h3 {
    @media screen and (max-width: ${mediaQueries.medium}) {
      font-size: ${font.size.base};
    }
  }

  ${(p) => css`
    background: ${p.theme.infoBox};
    ${p.isEmpty &&
    css`
      ${flex.center}
      min-height: auto;
      padding: 2em 0;
      font-weight: 500;
    `}
  `}
`
