import { FC } from "react"
import { FormattedMessage } from "react-intl"

import DepositOptions from "Components/DepositOptions"
import * as S from "./Deposit.styles"

const Deposit: FC = () => (
  <S.DepositWrap>
    <h1>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.header"
        defaultMessage="Gör en insättning"
      />
    </h1>
    <p>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.paragraphOne"
        defaultMessage="Toppen, nu är vi nästan klara! Sista steget är att du gör din första
        insättning."
      />
    </p>
    <p>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.paragraphTwo"
        defaultMessage="När du har gjort din insättning och pengarna blivit dragna från ditt
        konto kommer vi att aktivera ditt konto, detta brukar vanligtvis ta en
        arbetsdag."
      />
    </p>
    <h4>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.accordionHeader"
        defaultMessage="Välj insättningsmetod"
      />
    </h4>
    <DepositOptions />
    <h4>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.activation.header"
        defaultMessage="Aktivering av ditt konto"
      />
    </h4>

    <p>
      <FormattedMessage
        id="app.mypages.investments.onboarding.deposit.activation.paragraph"
        defaultMessage="När ditt konto blivit aktiverat kommer din insättning investeras bland
        våra kreditvärdiga låntagare enligt din investeringsprofil."
      />
    </p>
  </S.DepositWrap>
)

export default Deposit
