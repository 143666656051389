import { FC, memo, useCallback, useState, useRef, useMemo } from "react"
import {
  BoundFeaturesType,
  CustomChoroplethProps,
  FeatureType,
  LayersEnum,
} from "./CustomChoropleth.types"
import { calculatePath } from "./calculations"
import { useChoropleth } from "./hooks"
import Legend from "./Legend"
import * as S from "./CustomChoropleth.styles"

const CustomChoropleth: FC<CustomChoroplethProps> = ({
  features,
  data,
  margin,
  colors,
  domain,
  unknownColor,
  label,
  valueFormat,
  projectionScale,
  projectionTranslation,
  projectionRotation,
  borderWidth = 0,
  borderColor = "#000000",
  isInteractive,
  legends,
  tooltip: Tooltip,
  layers = [LayersEnum.features, LayersEnum.legends],
  width,
  height,
  match = "id",
  value = "value",
}) => {
  const [currentFeature, setCurrentFeature] = useState<FeatureType | null>(null)
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  const path = useMemo(
    () =>
      calculatePath(
        width,
        height,
        projectionScale,
        projectionTranslation,
        projectionRotation
      ),
    [height, projectionRotation, projectionScale, projectionTranslation, width]
  )
  const { getFillColor, boundFeatures, legendData } = useChoropleth({
    features,
    data,
    match,
    label,
    value,
    valueFormat,
    colors,
    unknownColor,
    domain,
  })

  const handleMove = useCallback(
    (
      feature: FeatureType,
      event: React.MouseEvent<SVGPathElement, MouseEvent>
    ) => {
      if (isInteractive && Tooltip) {
        setCurrentFeature(feature)
        const x = event.clientX - ref.current.offsetWidth / 2
        const y = event.clientY - 60
        ref.current.style.transform = `translate(${x}px, ${y}px)`
        ref.current.style.opacity = "1"
      }
    },
    [isInteractive, Tooltip]
  )
  const handleMouseLeave = useCallback(
    () => isInteractive && setCurrentFeature(null),
    [isInteractive]
  )

  const featuresJsx: JSX.Element[] = useMemo(
    () =>
      boundFeatures.map((feature) => (
        <path
          key={feature.id}
          fill={getFillColor(feature)}
          strokeWidth={borderWidth}
          stroke={borderColor}
          strokeLinejoin="bevel"
          d={path(feature as BoundFeaturesType) || undefined}
          onMouseEnter={(event) => handleMove(feature, event)}
          onMouseMove={(event) => handleMove(feature, event)}
          onMouseLeave={handleMouseLeave}
        />
      )),
    [
      borderColor,
      borderWidth,
      boundFeatures,
      getFillColor,
      handleMouseLeave,
      handleMove,
      path,
    ]
  )

  const legendsJsx: JSX.Element[] = useMemo(
    () =>
      legends.map((legend, i) => (
        <Legend key={i} data={legendData} {...legend} />
      )),
    [legendData, legends]
  )

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        role="img"
      >
        <rect width={width} height={height} fill="transparent" />
        <g transform={`translate(${margin.left},${margin.top})`}>
          {layers.map((layer) => {
            if (layer === LayersEnum.features) {
              return featuresJsx
            }
            if (layer === LayersEnum.legends) {
              return legendsJsx
            }
            return null
          })}
        </g>
      </svg>
      <S.TooltipContainer ref={ref}>
        {!!Tooltip && <Tooltip feature={currentFeature} />}
      </S.TooltipContainer>
    </>
  )
}

export default memo(CustomChoropleth)
