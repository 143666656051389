import { FC } from "react"
import { FormattedMessage } from "react-intl"

import * as S from "./RiskProfilePortfolioModal.styles"

const RiskProfilePortfolioModal: FC = () => (
  <S.RiskPortfolioModalWrap>
    <S.RiskPortfolioModalHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.subtitleConservative"
        defaultMessage="Konservativ"
      />
    </S.RiskPortfolioModalHeading>
    <S.RiskPortfolioModalParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textConservative"
        defaultMessage="Placeringar begränsas till de mest kreditvärdig låntagarna"
      />
    </S.RiskPortfolioModalParagraph>
    <S.RiskPortfolioModalMarkdown>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textConservative.markdown"
        defaultMessage="* Detta medför att längre utlåningstider kan förekomma"
      />
    </S.RiskPortfolioModalMarkdown>
    <S.RiskPortfolioModalRiskClass>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textConservativeSecondPart"
        defaultMessage="Riskklass: A-C"
      />
    </S.RiskPortfolioModalRiskClass>

    <S.RiskPortfolioModalHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.subtitleBalance"
        defaultMessage="Balanserd"
      />
    </S.RiskPortfolioModalHeading>
    <S.RiskPortfolioModalParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textBalance"
        defaultMessage="Bäst riskspridning och snabbast utlåning med Broccs Bredprofil"
      />
    </S.RiskPortfolioModalParagraph>
    <S.RiskPortfolioModalRiskClass>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textBalance.secondPart"
        defaultMessage="Riskklasser: Alla"
      />
    </S.RiskPortfolioModalRiskClass>
    <S.RiskPortfolioModalRiskClass>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textBalance.thirdPart"
        defaultMessage="Lägsta riskspridning: 1:40"
      />
    </S.RiskPortfolioModalRiskClass>

    <S.RiskPortfolioModalHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.subtitleProgressive"
        defaultMessage="Progresiv"
      />
    </S.RiskPortfolioModalHeading>
    <S.RiskPortfolioModalParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textProgressive"
        defaultMessage="Den offensiva profilen samlar låntagare med högre kreditrisk men som betalar högre räntor. Den offensiva portföljen har en lägre riskspridning än Bas."
      />
    </S.RiskPortfolioModalParagraph>
    <S.RiskPortfolioModalMarkdown>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textProgressive.markdown"
        defaultMessage="* Detta medför att längre utlåningstider kan förekomma"
      />
    </S.RiskPortfolioModalMarkdown>
    <S.RiskPortfolioModalRiskClass>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textProgressiveSecondPart"
        defaultMessage="Riskklasser: C-F"
      />
    </S.RiskPortfolioModalRiskClass>
    <S.RiskPortfolioModalRiskClass>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.riskProfileModal.textProgressiveThirdPart"
        defaultMessage="Lägsta riskspridning: 1:40"
      />
    </S.RiskPortfolioModalRiskClass>
  </S.RiskPortfolioModalWrap>
)

export default RiskProfilePortfolioModal
