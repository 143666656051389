import styled from "styled-components"

export const TransactionsWrap = styled.div`
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  a {
    &:first-of-type {
      padding-top: 0;
    }
  }
`
