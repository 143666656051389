import { FC, useMemo } from "react"
import { calculatePadding } from "../calculations"
import { LegendProps } from "../CustomChoropleth.types"
import LegendItem from "./LegendItem"

const Legend: FC<LegendProps> = ({
  data,
  translateX = 0,
  translateY = 0,
  direction,
  padding: customPadding = 0,
  itemsSpacing = 0,
  itemWidth,
  itemHeight,
  itemOpacity,
  effects,
}) => {
  const padding = useMemo(
    () => calculatePadding(customPadding),
    [customPadding]
  )

  const xStep = useMemo(
    () => (direction === "row" ? itemWidth + itemsSpacing : 0),
    [direction, itemWidth, itemsSpacing]
  )
  const yStep = useMemo(
    () => (direction === "column" ? itemHeight + itemsSpacing : 0),
    [direction, itemHeight, itemsSpacing]
  )

  return (
    <g transform={`translate(${translateX},${translateY})`}>
      {data.map((legendData, i) => (
        <LegendItem
          key={i}
          data={legendData}
          x={i * xStep + padding.left}
          y={i * yStep + padding.top}
          width={itemWidth}
          height={itemHeight}
          effects={effects}
          background="transparent"
          opacity={itemOpacity}
        />
      ))}
    </g>
  )
}

export default Legend
