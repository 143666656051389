import { FC, useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { useQueryClient } from "react-query"

import ResultsBox from "Components/ResultsBox"
import { useGetInvestmentServiceSummary } from "Hooks/API/useInvestmentServices"
import { IInvestmentService } from "Interfaces/investmentServicesInterfaces"
import Loading from "UI/Loading"
import { ResultListItem } from "Components/ResultsBox/ResultBox.types"
import { INVESTMENT_SERVICES_SERVICE } from "Constants/queryKeys"
import { formatNumber } from "Utils/formatNumber"

type KeyFiguresProps = {
  accountID: string
}

const KeyFigures: FC<KeyFiguresProps> = ({ accountID }) => {
  const queryClient = useQueryClient()

  const investmentSummary = useGetInvestmentServiceSummary(accountID)
  const investmentServiceByID = queryClient.getQueryData<IInvestmentService>([
    INVESTMENT_SERVICES_SERVICE,
    accountID,
  ])

  const netLandingRate = useMemo(() => {
    if (investmentServiceByID && investmentSummary.isSuccess) {
      if (investmentSummary.data.weighted_interest_rate > 0) {
        return (
          investmentSummary.data.weighted_interest_rate -
          investmentServiceByID.investment_service_fee
        )
      }
    }
    return 0
  }, [investmentServiceByID, investmentSummary])

  const expectedTerm = useMemo(() => {
    if (investmentSummary.isSuccess) {
      return investmentSummary.data.weighted_loan_term * 0.55
    }
    return 0
  }, [investmentSummary])

  const data: ResultListItem[] = [
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.netDeposits"
          defaultMessage="Nettoutlåningsränta"
        />
      ),
      value: formatNumber(netLandingRate, {
        style: "percent",
      }),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.netDeposits.info"
            defaultMessage="Utlåningsräntan i portföljen efter avgifter före eventuella kreditförluster."
          />
        ),
        placement: "top",
      },
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.amountOfLoans"
          defaultMessage="Antal lån"
        />
      ),
      value: investmentSummary.data
        ? investmentSummary.data.number_of_active_loans
        : 0,
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.loanTerm"
          defaultMessage="Avtalad löptid"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.loanTerm.info"
            defaultMessage="Avtalad löptid är den löptid låntagaren åtagit sig att betala tillbaka lånet på enligt avtal med Brocc. Löptiden varierar mellan 6 månader och 10 år."
          />
        ),
        placement: "top",
      },
      value: (
        <>
          {formatNumber(
            investmentSummary.data
              ? +investmentSummary.data.weighted_loan_term
              : 0,
            {
              style: "currency",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }
          )}{" "}
          <FormattedMessage
            id="app.common.monthAbbreviated"
            defaultMessage="mån"
          />
        </>
      ),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.cumulativeTerms"
          defaultMessage="Förväntad löptid"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.cumulativeTerms.info"
            defaultMessage="Merparten av låntagarna betalar tillbaka lånet snabbare än den löptid som har avtalats. Detta har alla låntagare rätt till enligt konsumentkreditlagen. Därför visas förväntad löptid, den löptid då lånen i genomsnitt förväntas att vara återbetalda på."
          />
        ),
        placement: "top",
      },
      value: (
        <>
          {formatNumber(expectedTerm, {
            style: "currency",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}{" "}
          <FormattedMessage
            id="app.common.monthAbbreviated"
            defaultMessage="mån"
          />
        </>
      ),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.balance"
          defaultMessage="Nettoinsättningar"
        />
      ),
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.balance.info"
            defaultMessage="Sammanställning av kontots insättningar och uttag som specificeras under menyvalet “Transaktioner”."
          />
        ),
        placement: "top",
      },
      value: formatNumber(
        investmentSummary.data
          ? investmentSummary.data.account_summary.simple.cash_flows
              .net_deposits
          : 0,
        { style: "currency" }
      ),
    },
  ]

  return (
    <>
      {investmentSummary.isLoading && <Loading />}
      {investmentSummary.isError && (
        <FormattedMessage
          id="app.mypages.error.investment.getAccountBalancesMetrics"
          defaultMessage="Det går inte att få fram parametrar för saldon för investeringstjänstkonton. Försök igen"
        />
      )}
      {investmentSummary.isSuccess && (
        <ResultsBox>
          <ResultsBox.Header>
            <FormattedMessage
              id="app.mypages.investments.home.yourInvestmentsBox.keyFigures.title"
              defaultMessage="Nyckeltal"
            />
          </ResultsBox.Header>
          <ResultsBox.Content data={data} />
        </ResultsBox>
      )}
    </>
  )
}

export default KeyFigures
