import { useMemo } from "react"
import { useIntl } from "react-intl"

export const useOptions = () => {
  const intl = useIntl()
  return useMemo(
    () => [
      {
        id: 0,
        value: "grade",
        name: intl.formatMessage({
          id: "app.charts.loanPortfolio.select.option.type",
          defaultMessage: "Riskklass",
        }),
      },
      {
        id: 1,
        value: "loan_status",
        name: intl.formatMessage({
          id: "app.charts.loanPortfolio.select.option.loanStatus",
          defaultMessage: "Lånestatus",
        }),
      },
      {
        id: 2,
        value: "term_left",
        name: intl.formatMessage({
          id: "app.charts.loanPortfolio.select.option.termLeft",
          defaultMessage: "Löptid",
        }),
      },
      {
        id: 3,
        value: "age",
        name: intl.formatMessage({
          id: "app.mypages.investment.select.firstOption",
          defaultMessage: "Demografisk spridning ålder/kön",
        }),
      },
      {
        id: 4,
        value: "geography",
        name: intl.formatMessage({
          id: "app.mypages.investment.select.secondOption",
          defaultMessage: "Geografisk spridning",
        }),
      },
    ],
    [intl]
  )
}
