import { FC, memo } from "react"
import { useQueryClient } from "react-query"
import { FormattedMessage } from "react-intl"

import {
  IInvestmentService,
  IInvestmentServiceSummary,
} from "Interfaces/investmentServicesInterfaces"
import ResultsBox from "Components/ResultsBox"
import InfoBadge from "UI/InfoBadge"
import { ResultListItem } from "Components/ResultsBox/ResultBox.types"
import {
  INVESTMENT_SERVICES_SERVICE,
  INVESTMENT_SERVICES_SUMMARY,
} from "Constants/queryKeys"
import { formatNumber } from "Utils/formatNumber"

type ResultsRealizedPropsType = {
  accountID: string
}

const ResultsRealized: FC<ResultsRealizedPropsType> = ({ accountID }) => {
  const queryClient = useQueryClient()
  const summary = queryClient.getQueryData([
    INVESTMENT_SERVICES_SUMMARY,
    accountID,
  ]) as IInvestmentServiceSummary
  const investmentService = queryClient.getQueryData([
    INVESTMENT_SERVICES_SERVICE,
    accountID,
  ]) as IInvestmentService

  const data: ResultListItem[] = [
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.interests"
          defaultMessage="Räntor"
        />
      ),
      value: formatNumber(summary.account_summary.results.interests, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.fees"
          defaultMessage="Avgifter"
        />
      ),
      isHidden: !summary.account_summary.results.fees,
      infoBadge: {
        title: (
          <FormattedMessage
            id="app.mypages.investments.results.overviewBox.realizedResults.fees.info"
            defaultMessage="I avgifter inkluderas bl.a. servicekostnaden samt eventuell överlåtelseavgift"
          />
        ),
        placement: "right",
      },
      value: formatNumber(summary.account_summary.results.fees, {
        style: "currency",
        currencyDisplay: "narrowSymbol",
      }),
    },
    {
      isHidden: summary.account_summary.results.realized_value_changes <= 0.35,
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.realizedValueChanges"
          defaultMessage="Realiserade värdeförändringar"
        />
      ),
      value: formatNumber(
        summary.account_summary.results.realized_value_changes,
        {
          style: "currency",
          currencyDisplay: "narrowSymbol",
        }
      ),
    },
    {
      name: (
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.netResults"
          defaultMessage="Realiserat resultat"
        />
      ),
      value: formatNumber(
        summary.account_summary.simple.realized_results.net_results,
        {
          style: "currency",
          currencyDisplay: "narrowSymbol",
        }
      ),
      isBold: true,
    },
  ]

  return (
    <ResultsBox>
      <ResultsBox.Header>
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.title"
          defaultMessage="Realiserat resultat"
        />
      </ResultsBox.Header>
      <ResultsBox.Content data={data} />
      <ResultsBox.Footer>
        <FormattedMessage
          id="app.mypages.investments.results.overviewBox.realizedResults.serviceFees"
          defaultMessage="Servicekostnad"
        />
        :{" "}
        {formatNumber(investmentService.investment_service_fee, {
          style: "percent",
        })}
        <InfoBadge
          placement="top"
          title={
            <FormattedMessage
              id="app.mypages.investments.results.overviewBox.realizedResults.serviceFees.info"
              defaultMessage="{serviceCost}: Servicekostnaden baseras på aktivt utlånat kapital som på månadsbasis dras från intjänad ränta"
              values={{
                serviceCost: formatNumber(
                  investmentService.investment_service_fee,
                  {
                    style: "percent",
                  }
                ),
              }}
            />
          }
        />
      </ResultsBox.Footer>
    </ResultsBox>
  )
}

export default memo(ResultsRealized)
