import { FC, memo } from "react"
import { useIntl } from "react-intl"
import Select from "UI/Select"
import { useStatusFilterOptions } from "./data"

type StatusFilterPropsType = {
  status: string
  setStatus: React.Dispatch<React.SetStateAction<string>>
}

const StatusFilter: FC<StatusFilterPropsType> = ({ status, setStatus }) => {
  const intl = useIntl()
  const options = useStatusFilterOptions()
  return (
    <Select
      type="rounded"
      aria-label={intl.formatMessage({
        id: "app.common.status",
        defaultMessage: "Status",
      })}
      options={options}
      onChange={(e) => setStatus(e.currentTarget.value)}
      selected={status}
    />
  )
}

export default memo(StatusFilter)
