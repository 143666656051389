import { FC } from "react"
import { FormattedMessage } from "react-intl"

import * as S from "./InvestmentPortfolioModal.styles"

const InvestmentPortfolioModal: FC = () => (
  <S.InvestmentPortfolioWrap>
    <S.InvestmentPortfolioHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleBroccs"
        defaultMessage="Broccs val"
      />
    </S.InvestmentPortfolioHeading>
    <S.InvestmentPortfolioUList>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.highestInterestList"
          defaultMessage="Högst ränta"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.bestRiskList"
          defaultMessage="Bäst riskspridning"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.fastestLendingList"
          defaultMessage="Snabbast utlåning"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.allMaturitiesList"
          defaultMessage="Alla löptider"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.average5YearsList"
          defaultMessage="Genomsnitt 5 år*"
        />
      </S.InvestmentPortfolioItem>
    </S.InvestmentPortfolioUList>
    <S.InvestmentPortfolioHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleCard"
        defaultMessage="Kort val"
      />
    </S.InvestmentPortfolioHeading>
    <S.InvestmentPortfolioUList>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.lowerInterestList"
          defaultMessage="Lägre räntor"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.fasterRepaymentList"
          defaultMessage="Snabbare återbetalning"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.shorterMaturitiesList"
          defaultMessage="Kortare löptider"
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.averageList"
          defaultMessage="Genomsnitt 3,5 år*"
        />
      </S.InvestmentPortfolioItem>
    </S.InvestmentPortfolioUList>
    <S.InvestmentPortfolioMarkdown>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.cardCondition"
        defaultMessage="* Förväntad löptid. Löptiderna för enskilda lån kan med Broccs val uppgå till 10 år och med Kort val till 7 år."
      />
    </S.InvestmentPortfolioMarkdown>
    <S.InvestmentPortfolioHeading>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationSection"
        defaultMessage="Information om löptider"
      />
    </S.InvestmentPortfolioHeading>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph1"
        defaultMessage="Merparten av låntagarna betalar tillbaka lånen snabbare än den löptid som avtalats. Detta har alla låntagare rätt till enligt konsumentkreditlagen. Historiken visar att du som investerare kan förvänta dig att ett lån återbetalas när lite mer än hälften av den ordinarie löptiden har gått."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph2"
        defaultMessage="Dina pengar investeras i lån som har olika löptider. Ett låns löptid är den tid lånet ska återbetalas på enligt låneavtalet. Brocc erbjuder löptider på lån från 3 till 10 år. De flesta låntagarna väljer dock en kortare avbetalningstid än 10 år."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph3"
        defaultMessage="För dig som investerare innebär lån med längre löptider i regel högre räntor."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph4"
        defaultMessage="Merparten av låntagarna betalar dock tillbaka lånet snabbare än den löptid som avtalats. Detta har alla låntagare rätt till enligt konsumentkreditlagen. Anledningen till att lånen ofta återbetalas i förtid är tex att kvarvarande sklid inte är så stor, att man löser lånet i samband med omläggning av ett annat lån (ofta bolånet) eller att man till exempel sålt fordonet man lånat till att köpa ifrån början."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph5"
        defaultMessage="När du investerar hos Brocc kan du därför förvänta dig att ha fått tillbaka pengarna efter att ungefär lite mer än hälften av lånets löptid har gått."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph6"
        defaultMessage="I tabellen ser du den maximala löptid som Brocc lånar ut på,
        en genomsnittlig löptid som visar hur lång återbetalningstid låntagarna faktiskt väljer för sina
        lån och en förväntad löptid som berättar hur snabbt de faktiskt betalat tillbaka i slutänden."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioUList>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationMaximumTermList"
          defaultMessage="Den maximala löptiden på lån hos Brocc är 10 år."
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationAverageMaturityList"
          defaultMessage="Den genomsnittliga löptiden hos Brocc är 7 år."
        />
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationExpectedMaturityList"
          defaultMessage="Den förväntade löptiden hos Brocc är 4,4 år."
        />
      </S.InvestmentPortfolioItem>
    </S.InvestmentPortfolioUList>
    <S.InvestmentPortfolioParagraph>
      <FormattedMessage
        id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationParagraph7"
        defaultMessage="Det du som investerare behöver ta ställning till är hur länge du vill att dina pengar ska vara
        utlånade. Du kan göra 2 val för vad som ska gälla i din portfölj."
      />
    </S.InvestmentPortfolioParagraph>
    <S.InvestmentPortfolioOList>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationOrderedListBroccsChoice"
          defaultMessage="Broccs val"
        />

        <S.InvestmentPortfolioParagraph>
          <FormattedMessage
            id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationOrderedListBroccsChoiceParagraph"
            defaultMessage="Brocc väljer bland lån på samtliga löptider åt dig för att ge dig så bra ränta och
            riskspridning som möjligt. Du får återbetalningar varje månad och förväntad genomsnittlig
            löptid på lånen i din portfölj är 4–6 år. Du väljer själv om du vill återinvestera för att
            få ränta på ränta eller få pengarna utbetalda."
          />
        </S.InvestmentPortfolioParagraph>
      </S.InvestmentPortfolioItem>
      <S.InvestmentPortfolioItem>
        <FormattedMessage
          id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationOrderedListCardChoice"
          defaultMessage="Kort val"
        />

        <S.InvestmentPortfolioParagraph>
          <FormattedMessage
            id="app.mypages.investments.investmentChoices.investmentExplainationModal.subtitleInformationOrderedListCardChoiceParagraph"
            defaultMessage="Snabbare återbetalningstid.
            Eftersom du inte kan ta del i alla lån på plattformen blir din riskspridning något lägre.
            Den förväntade genomsnittliga löptiden på lånen i din portfölj kommer att vara ca 3,5 år.
            Lån med kortare löptider har även lägre räntor än lån med lite lägre löptid.
            Även i detta val kan du välja att återinvestera eller ta ut dina
            återbetalningar. Den maximala löptiden med Kort val uppgår fn till 7 år."
          />
        </S.InvestmentPortfolioParagraph>
      </S.InvestmentPortfolioItem>
    </S.InvestmentPortfolioOList>
  </S.InvestmentPortfolioWrap>
)

export default InvestmentPortfolioModal
