import { useState, useEffect, useCallback, FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import { useGetKYC } from "Hooks/API/useKYC"
import IconFactory from "UI/IconFactory"
import Loading from "UI/Loading"
import ProgressBar from "UI/ProgressBar"
import { INVESTMENT_ONBOARDING } from "Constants/storage"
import { getParsedStorageItemOrDefault } from "Utils/localStorage"
import { useInvestmentServiceByIDQuery } from "Hooks/API/useInvestmentServices"
import { STEP_TITLES, STEPS, totalSteps } from "./constants"
import {
  InvestmentOnboardingData,
  InvestmentsOnboardingStepsEnum,
} from "./InvestmentOnboarding.types"
import * as S from "./InvestmentsOnboarding.styles"

const InvestmentsOnboarding: FC = () => {
  const intl = useIntl()
  const [step, setStep] = useState<number>(InvestmentsOnboardingStepsEnum.Intro)
  const { accountID: id } = useParams() as { accountID: string }

  const [investmentOnboardingData] = useState(() => {
    const item = getParsedStorageItemOrDefault<InvestmentOnboardingData>(
      INVESTMENT_ONBOARDING,
      {}
    )
    return item[id]
  })

  const { data, isLoading, isError, isSuccess } =
    useInvestmentServiceByIDQuery(id)

  const {
    isError: isErrorKYC,
    isSuccess: isSuccessKYC,
    isLoading: isLoadingKYC,
  } = useGetKYC()

  const progress = (100 / totalSteps) * step

  const CurrentStep = STEPS[step]

  const determineStep = useCallback(() => {
    if (!investmentOnboardingData) {
      setStep(InvestmentsOnboardingStepsEnum.Intro)
      return
    }

    if (
      investmentOnboardingData.brocc_has_started_investment_onboarding &&
      isErrorKYC
    ) {
      setStep(InvestmentsOnboardingStepsEnum.KYC)
      return
    }

    if (
      isSuccessKYC &&
      !investmentOnboardingData.brocc_has_investment_choices
    ) {
      setStep(InvestmentsOnboardingStepsEnum.InvestmentProfile)
      return
    }

    if (investmentOnboardingData.brocc_has_investment_choices) {
      setStep(InvestmentsOnboardingStepsEnum.Deposit)
    }
  }, [investmentOnboardingData, isErrorKYC, isSuccessKYC])

  const handleBackBtn = () =>
    step > InvestmentsOnboardingStepsEnum.KYC && setStep(step - 1)

  useEffect(() => {
    if (data) {
      determineStep()
    }
  }, [data, determineStep])

  if (isLoading || isLoadingKYC) {
    return (
      <S.LoadingWrapper>
        <Loading isCentered />
      </S.LoadingWrapper>
    )
  }

  if (isError) {
    return (
      <FormattedMessage
        id="app.mypages.error.investment.getInvestmentServiceByID"
        defaultMessage="Kan inte få den begärda investeringstjänsten. Försök igen"
      />
    )
  }

  if (!isSuccess) return null

  return (
    <S.OnboardingStepsContainer>
      <S.CounterContainer>
        {step !== InvestmentsOnboardingStepsEnum.Intro && (
          <S.BackButtonWrap>
            <button
              onClick={handleBackBtn}
              type="button"
              aria-label="Go Back a Step"
            >
              <IconFactory name="chevronRight" />
            </button>
            <span>
              <FormattedMessage id="app.common.step" defaultMessage="Steg" />
              &nbsp;{step}/3
            </span>
            <strong>{intl.formatMessage(STEP_TITLES[step])}</strong>
          </S.BackButtonWrap>
        )}
      </S.CounterContainer>
      <ProgressBar width={progress} />
      <S.StepContainer>
        {CurrentStep ? (
          <CurrentStep
            isCompany={data.customer.is_corporate}
            setStep={setStep}
          />
        ) : (
          <FormattedMessage
            id="app.common.error.header"
            defaultMessage="Något gick fel"
          />
        )}
      </S.StepContainer>
    </S.OnboardingStepsContainer>
  )
}

export default InvestmentsOnboarding
