import { FC, useMemo, useState, useCallback } from "react"
import { xor } from "lodash"
import { useQueryClient } from "react-query"
import { useCreateInvestmentReports } from "Hooks/API/useReports"
import { FormattedMessage } from "react-intl"
import type { IInvestmentServices } from "Interfaces/investmentServicesInterfaces"
import { useFeedbackModal } from "Context/modal-context"
import Button from "UI/Button"
import Checkbox from "UI/Checkbox"
import { INVESTMENT_SERVICES_DATA } from "Constants/queryKeys"
import type { AccountsComponentProps } from "../Reports.types"
import {
  InvestmentsWrap,
  ErrorMessage,
  SubmitButtonContainer,
  CheckboxLabel,
} from "./InvestmentsAccounts.styles"

const InvestmentsAccounts: FC<AccountsComponentProps> = ({ children }) => {
  const queryClient = useQueryClient()

  const [investmentsId, setInvestmentsId] = useState<string[]>([])
  const [rangeDate, setRangeDate] = useState({
    start_date: "",
    end_date: "",
  })

  const createInvestmentReports = useCreateInvestmentReports()

  const initialData = queryClient.getQueryData<IInvestmentServices>(
    INVESTMENT_SERVICES_DATA
  )
  const { successModal, errorModal } = useFeedbackModal()
  const handleRangeDate = useCallback(
    (value: { start_date: string; end_date: string }) => setRangeDate(value),
    [setRangeDate]
  )

  const filteredInvestments = useMemo(
    () => initialData?.data.filter((item) => item.status === "active"),
    [initialData]
  )

  const onAccountChange = (uid: string) => {
    const uids = xor(investmentsId, [uid])
    setInvestmentsId(uids)
  }

  const handleCreateReport = useCallback(() => {
    if (investmentsId.length) {
      createInvestmentReports
        .mutateAsync({
          ...rangeDate,
          investment_service_uids: investmentsId,
        })
        .then(() => {
          successModal(
            <FormattedMessage
              id="app.mypages.reports.creation.successMessage"
              defaultMessage="Din rapport håller på att skapas! Var god vänta. Den kommer att visas i listan om några sekunder."
            />
          )
        })
        .catch(() =>
          errorModal(
            <FormattedMessage
              id="app.mypages.error.document.createInvestmentReports"
              defaultMessage="Det går inte att skapa en investera rapport. Försök igen"
            />
          )
        )
    }
  }, [
    investmentsId,
    createInvestmentReports,
    rangeDate,
    successModal,
    errorModal,
  ])

  if (!filteredInvestments?.length) {
    return (
      <ErrorMessage>
        <FormattedMessage
          id="app.mypages.reports.investments.noAccounts"
          defaultMessage="Du har inget aktivt investeringskonto"
        />
      </ErrorMessage>
    )
  }

  return (
    <InvestmentsWrap>
      {filteredInvestments?.map((investment) => (
        <CheckboxLabel key={investment.uid}>
          <Checkbox
            key={investment.uid}
            color="white"
            size="large"
            name="investments"
            value={investment.uid}
            checked={investmentsId.includes(investment.uid)}
            onChange={() => onAccountChange(investment.uid)}
          />
          {investment.product_number},{" "}
          <FormattedMessage
            id="app.common.account.investmentAccount"
            defaultMessage="Investeringskonto"
          />
        </CheckboxLabel>
      ))}
      {filteredInvestments.length > 0 &&
        investmentsId?.length > 0 &&
        children("", handleRangeDate, "investments")}

      <SubmitButtonContainer>
        <Button disabled={!rangeDate.start_date} onClick={handleCreateReport}>
          <FormattedMessage id="app.mypages.reports.creation.create" />
        </Button>
      </SubmitButtonContainer>
    </InvestmentsWrap>
  )
}

export default InvestmentsAccounts
